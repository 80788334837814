
// src/screens/LeaveReport.js
import React, { useEffect, useState } from 'react';


const LeaveReport = () => {
  const [leaveData, setLeaveData] = useState([]);


  useEffect(() => {
    // Fetch the token from localStorage
    const token = localStorage.getItem('authToken');


    // Fetch data from the API
    fetch('https://api.myntask.com/report/leave', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setLeaveData(data);
      })
      .catch(error => {
        console.error('Error fetching the leave report:', error);
      });
  }, []);


  return (
    <div>
      <h1>Leave Report</h1>
      <p>Welcome to the Leave Report section!</p>


      <table border="1" style={{ width: '100%', textAlign: 'left', marginTop: '20px' }}>
        <thead>
          <tr>
            <th>User Image</th>
            <th>User Name</th>
            <th>Designation</th>
            <th>Approved Leaves</th>
            <th>Pending Leaves</th>
          </tr>
        </thead>
        <tbody>
          {leaveData.map((user, index) => (
            <tr key={user.user_id}>
              <td>
                {user.user_image ? (
                  <img
                    src={user.user_image}
                    alt={user.user_name}
                    style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                  />
                ) : (
                  <span>No Image</span>
                )}
              </td>
              <td>{user.user_name}</td>
              <td>{user.designaton}</td>
              <td>{user.approved_leaves !== null ? user.approved_leaves : 'N/A'}</td>
              <td>{user.pending_leaves !== null ? user.pending_leaves : 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};


export default LeaveReport;