import React, { useState, useEffect } from 'react';
import { Modal } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';

const MarkAttendance = ({ open, handleClose }) => {
  const [locations, setLocations] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState('');
  const [workingFrom, setWorkingFrom] = useState('');
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [attendanceId, setAttendanceId] = useState(null); // Capture attendance ID
  const [isClockedIn, setIsClockedIn] = useState(false); // State for clock-in status

  useEffect(() => {
    if (open) {
      console.log('Modal Opened: Fetching locations and getting user location.');
      fetchLocations();
      getUserLocation();
    }
  }, [open]);

  // Fetch locations available for attendance
  const fetchLocations = async () => {
    try {
      const token = localStorage.getItem("authToken");
      console.log('Fetching locations with token:', token);
      const response = await fetch('https://api.myntask.com/attendance/locations', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Locations fetched:', data);
        setLocations(data);
      } else {
        console.error('Failed to fetch locations:', response.status);
        toast.error('Failed to fetch locations');
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
      toast.error('Error fetching locations');
    }
  };

  // Get the user's current location
  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log('User location fetched:', position.coords);
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error('Error getting user location:', error);
          toast.error('Error getting user location');
        }
      );
    } else {
      toast.error('Geolocation is not supported by this browser.');
    }
  };

  // Save the attendance when user clicks "Yes, Mark Attendance"
  const handleSaveAttendance = async () => {
    const url = 'https://api.myntask.com/attendance/save';
    const data = {
      location_id: selectedLocationId,
      working_from: workingFrom,
      latitude: latitude,
      longitude: longitude
    };

    console.log('Marking attendance with data:', data);

    try {
      const token = localStorage.getItem("authToken");
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      const responseText = await response.text();

      try {
        const result = JSON.parse(responseText); // Try to parse JSON
        if (response.ok) {
          console.log('Attendance marked successfully:', result);
          setAttendanceId(result.attendance_id); // Store attendance_id if available
          setIsClockedIn(true); // Update clock-in status
          toast.success('Attendance marked successfully');
          handleClose();
        } else {
          console.error('Failed to save attendance:', result.message || result.error);
          toast.error(result.message || 'Failed to save attendance');
        }
      } catch (error) {
        console.warn('Non-JSON response:', responseText); // Log non-JSON response
        if (response.ok) {
          toast.success(responseText); // Show success message if it's a success
        } else {
          toast.error(responseText); // Show error message from the response
        }
      }
    } catch (error) {
      console.error('Error saving attendance:', error);
      toast.error('Error saving attendance');
    }
  };

  // Clock Out Function
  const handleClockOut = async () => {
    const clockOutTimestamp = dayjs().format('YYYY-MM-DD HH:mm:ss'); // Get the current time in the required format
    const url = 'https://api.myntask.com/attendance/save'; // Reusing the same save endpoint for clock-out
    const data = {
      location_id: selectedLocationId,
      working_from: workingFrom,
      latitude: latitude,
      longitude: longitude,
      clock_out: clockOutTimestamp, // Send the clock-out time
      attendance_id: attendanceId, // Send the captured attendance_id from clock-in
    };

    console.log('Clocking out with data:', data);

    try {
      const token = localStorage.getItem("authToken");
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      const responseText = await response.text();
      console.log('Clock-out response:', responseText);

      try {
        const result = JSON.parse(responseText); // Try to parse JSON
        if (response.ok) {
          toast.success('Clocked out successfully');
          setIsClockedIn(false); // Update clock-in status
          handleClose();
        } else {
          toast.error(result.message || 'Failed to clock out');
        }
      } catch (error) {
        console.warn('Non-JSON response:', responseText); // Log non-JSON response
        if (response.ok) {
          toast.success(responseText); // Show success message if it's a success
        } else {
          toast.error(responseText); // Show error message from the response
        }
      }
    } catch (error) {
      console.error('Error clocking out:', error);
      toast.error('Error clocking out');
    }
  };

  const handleLocationChange = (event) => {
    console.log('Location changed:', event.target.value);
    setSelectedLocationId(event.target.value);
  };

  const handleWorkingFromChange = (event) => {
    console.log('Working from changed:', event.target.value);
    setWorkingFrom(event.target.value);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <div
          style={{
            position: 'absolute',
            top: '20%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '350px',
            backgroundColor: 'white',
            border: '2px solid #000',
            boxShadow: '24px 24px 48px rgba(0, 0, 0, 0.5)',
            padding: '16px',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <h2>Mark Attendance</h2>

            <div style={{ marginTop: '16px', marginBottom: '16px' }}>
              <div style={{ marginBottom: '16px' }}>
                <label>Select your location:</label>
                <select
                  value={selectedLocationId}
                  onChange={handleLocationChange}
                  style={{
                    width: '100%',
                    marginTop: '8px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <option value="" disabled>
                    Select a location
                  </option>
                  {locations.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.location}
                    </option>
                  ))}
                </select>
              </div>

              <div style={{ marginBottom: '16px' }}>
                <label>Select working from:</label>
                <select
                  value={workingFrom}
                  onChange={handleWorkingFromChange}
                  style={{
                    width: '100%',
                    marginTop: '8px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <option value="" disabled>
                    Select working from
                  </option>
                  <option value="office">Office</option>
                  <option value="home">Home</option>
                </select>
              </div>
            </div>
          </div>
          <div style={{ alignSelf: 'flex-end' }}>
            <button
              style={{
                marginTop: '16px',
                padding: '8px 16px',
                backgroundColor: '#1976d2',
                color: 'white',
                border: 'none',
                cursor: 'pointer',
                borderRadius: '4px',
              }}
              onClick={handleSaveAttendance}
              disabled={!selectedLocationId || !workingFrom}
            >
              Yes, Mark Attendance
            </button>
            <button
              style={{
                marginTop: '16px',
                marginLeft: '8px',
                padding: '8px 16px',
                backgroundColor: '#1976d2',
                color: 'white',
                border: 'none',
                cursor: 'pointer',
                borderRadius: '4px',
              }}
              onClick={handleClockOut}
              disabled={!selectedLocationId || !workingFrom}
            >
              Clock Out
            </button>
            <button
              style={{
                marginTop: '16px',
                marginLeft: '8px',
                padding: '8px 16px',
                backgroundColor: 'white',
                color: '#1976d2',
                border: '1px solid #1976d2',
                cursor: 'pointer',
                borderRadius: '4px',
              }}
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default MarkAttendance;
