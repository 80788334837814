

import React, { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import { useNavigate } from 'react-router-dom';
import TaskDetail from '../Components/TaskDetail';
import { GridMoreVertIcon } from '@mui/x-data-grid';
import './../Components/Main.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import './../Components/Main.css';




import { Avatar,Tooltip  } from '@mui/material';
import defaultAvatar from '../assets/vector.jpeg';
import Swal from 'sweetalert2';








const PAGE_SIZE = 15; // Number of tasks per page








const Task = () => {
const [tasks, setTasks] = useState([]);
const [filteredTasks, setFilteredTasks] = useState([]);
const [statusOptions, setStatusOptions] = useState([]);
const [error, setError] = useState('');
const [searchQuery, setSearchQuery] = useState('');
const [selectedTasks, setSelectedTasks] = useState([]);
const [action, setAction] = useState('');
const [currentPage, setCurrentPage] = useState(1);
const [totalPages, setTotalPages] = useState(1);
const [isModalOpen, setIsModalOpen] = useState(false);
const [selectedTaskId, setSelectedTaskId] = useState(null);
const [actionMenuVisible, setActionMenuVisible] = useState({});
const navigate = useNavigate();


const [loading, setLoading] = useState(false);
const [fetchData, setFetchData] = useState(false); // State to trigger useEffect
const [isOpen, setIsOpen] = useState(false);
const [projects, setProjects] = useState([]);
const [selectedProject, setSelectedProject] = useState('');
const [userProfile, setUserProfile] = useState({});
const [selectedFile, setSelectedFile] = useState(null);
const [fetchMyTasks, setFetchMyTasks] = useState(false); // New state to track My Task button
const [startDate, setStartDate] = useState('');
const [dueDate, setDueDate] = useState('');
const [selectedStatus, setSelectedStatus] = useState('');
















useEffect(() => {
  const storedProfile = JSON.parse(localStorage.getItem('userProfile')) || {};
  setUserProfile(storedProfile);
}, []);


const userId= userProfile.user_id;




const handleDownloadPdf = () => {
  const doc = new jsPDF();
  const tableColumn = ['Code', 'Task', 'Start Date', 'Due Date', 'Status', 'Assigned To'];
  const tableRows = [];


  tasks.forEach(task => {
    const assignedUsers = task.assigned_users.map(user => user.name).join(', ');


    const taskData = [
      task.code || 'N/A',
      truncateText(task.task, 6) || 'N/A',  // Use truncateText here
      task.start_date || 'N/A',
      task.due_date || 'N/A',
      task.status || 'N/A',
      assignedUsers || 'N/A',
    ];


    tableRows.push(taskData);
  });


  doc.autoTable({
    head: [tableColumn],
    body: tableRows,
  });


  doc.save('tasks_table.pdf');
};




// Fetch project list from the API
useEffect(() => {
  const fetchProject = async () => {
    setLoading(true);
    setError(null);








    try {
      // Retrieve the token from local storage
      const token = localStorage.getItem('authToken');
     
      // Check if the token is available
      if (!token) {
        throw new Error('Authorization token is missing');
      }








      const response = await fetch('https://api.myntask.com/task/project_list', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });








      // Handle the response
      if (!response.ok) {
        throw new Error(`Failed to fetch project list: ${response.statusText}`);
      }








      const data = await response.json();
      setProjects(data);
      console.log('Project List Data:', data);
    } catch (error) {
      setError(error.message);
      console.error('Error fetching project list:', error);
    } finally {
      setLoading(false);
    }
  };








  fetchProject();
}, []); // Run only once on component mount








const handleFileChange = (event) => {
  setSelectedFile(event.target.files[0]);
};








const handleSubmit = async () => {
  if (!selectedFile || !selectedProject) return;


  const token = localStorage.getItem('authToken'); // Replace with your token key
  const formData = new FormData();
  formData.append('file', selectedFile);
  formData.append('project_id', selectedProject);


  // Log the data being sent
  console.log('Data being sent:', {
    file: selectedFile.name, // Log the file name for clarity
    project_id: selectedProject,
  });


  try {
    const response = await fetch('https://api.myntask.com/project/task-import', { // Replace with your upload API endpoint
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });


    // Log the response
    const responseData = await response.json(); // Assuming the response is in JSON format
    console.log('Response from server:', responseData);


    // Check if the response was successful
    if (!response.ok) {
      throw new Error(`Error: ${responseData.message || 'Upload failed'}`);
    }


    close(); // Close the modal after submission
  } catch (error) {
    console.error('Error during file upload:', error);
  }
};










const open = () => {
  setIsOpen(true);
};








const close = () => {
  setIsOpen(false);
  setSelectedFile(null); // Reset file selection
  setSelectedProject(''); // Reset project selection
};








useEffect(() => {
  const fetchTaskTemplate = async () => {
      if (fetchData) {
          setLoading(true);
          setError(null);








          try {
              const response = await fetch('https://api.myntask.com/project/task-template', {
                  method: 'GET',
                  headers: {
                      // Add any required headers here if necessary
                  }
              });
             
              if (!response.ok) {
                  throw new Error('Failed to download file');
              }








              // Create a blob from the response
              const blob = await response.blob();
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;








              // Set the download attribute with the correct file extension for Excel
              link.setAttribute('download', 'task-template.xlsx'); // Use .xls if it's an older Excel file
              document.body.appendChild(link);
              link.click();
              link.remove();
              window.URL.revokeObjectURL(url); // Clean up the URL object








          } catch (err) {
              setError(err.message);
          } finally {
              setLoading(false);
          }
      }
  };








  fetchTaskTemplate();
}, [fetchData]); // Effect runs when fetchData changes






const handleDownloadClick = () => {
  setFetchData(true); // Set the flag to trigger the useEffect
};








const truncateText = (text, wordLimit) => {
  const words = text.split(' ');
  if (words.length <= wordLimit) {
    return text;
  }
  return words.slice(0, wordLimit).join(' ') + '...';
};








// Fetch tasks with filters
useEffect(() => {
  const fetchTasks = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No token found');
      return;
    }
 
    console.log('status id is ', selectedStatus);
 
    let apiUrl = `https://api.myntask.com/task/list?start_date=${startDate}&due_date=${dueDate}&status_id=${selectedStatus}`;




     // Add userId parameter if fetching "My Tasks"
  if (fetchMyTasks) {
    apiUrl = `https://api.myntask.com/task/list/${userId}?start_date=${startDate}&due_date=${dueDate}&status_id=${selectedStatus}`; // Ensure userId is appended when fetching My Tasks
  }


 
    // Log the API URL to check if the parameters are correct
    console.log("Fetching tasks from:", apiUrl);
 
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
 
      if (!response.ok) {
        throw new Error('Failed to fetch tasks');
      }
 
      const data = await response.json();
 
      // Log the response to ensure you are getting the data you expect
      console.log("Fetched data:", data);
 
      setTasks(data);
      setFilteredTasks(data); // Ensure `filteredTasks` is updated here
      setTotalPages(Math.ceil(data.length / PAGE_SIZE));
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setError(error.message);
    }
  };
 


  fetchTasks();
}, [startDate, dueDate, selectedStatus, fetchMyTasks, userId]); // Refetch when filters change






// Toggle "My Task" state
const handleMyTaskClick = () => {
setFetchMyTasks(!fetchMyTasks); // Toggle between all tasks and my tasks
};








useEffect(() => {
const fetchStatusOptions = async () => {
  const token = localStorage.getItem('authToken');
  if (!token) {
    setError('No token found');
    return;
  }




  try {
    const response = await fetch('https://api.myntask.com/task/task_status', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });




    if (!response.ok) {
      throw new Error('Failed to fetch status options');
    }




    const data = await response.json();
    setStatusOptions(data);
  } catch (error) {
    console.error('Error fetching status options:', error);
    setError(error.message);
  }
};




fetchStatusOptions();
}, []);








const handleStatusChange = async (taskId, newStatus) => {
  const token = localStorage.getItem('authToken');
  if (!token) {
    setError('No token found');
    return;
  }




  const statusOption = statusOptions.find(option => option.status === newStatus);
  if (!statusOption) {
    setError('Invalid status selected');
    return;
  }




  try {
    const response = await fetch('https://api.myntask.com/task/task_status_update', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        task_id: taskId,
        status_id: statusOption.id
      }),
    });




    if (!response.ok) {
      throw new Error('Failed to update status');
    }




    const updatedTasks = tasks.map(task =>
      task.task_id === taskId ? { ...task, status: newStatus } : task
    );
    setTasks(updatedTasks);
    setFilteredTasks(updatedTasks);
  } catch (error) {
    console.error('Error updating status:', error);
    setError(error.message);
  }
};








const handleAddTaskClick = () => {
  console.log("Add Task button clicked");
  navigate('/new-task'); // Navigate to New Task screen
};




const handleEditTaskClick = () => {
  if (selectedTasks.length === 1) {
    const taskId = selectedTasks[0];
    navigate(`/edit-task/${taskId}`);
  } else {
    setError('Please select exactly one task to edit');
  }
};








// Updated delete function to handle both single and multiple task IDs
const handleDeleteTask = async (taskIdOrIds) => {
  const token = localStorage.getItem('authToken');
  if (!token) {
    setError('No token found');
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: 'No token found',
    });
    return;
  }


  // Determine if input is a single ID or an array of IDs
  const taskIds = Array.isArray(taskIdOrIds) ? taskIdOrIds : [taskIdOrIds];


  // SweetAlert confirmation dialog
  const confirmDelete = await Swal.fire({
    title: "Are you sure?",
    text: `You won't be able to revert this!`,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!"
  });


  if (!confirmDelete.isConfirmed) return;


  try {
    for (const taskId of taskIds) {
      const response = await fetch('https://api.myntask.com/task/delete', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ task_id: taskId }),
      });


      if (!response.ok) {
        throw new Error(`Failed to delete task with ID ${taskId}`);
      }
    }


    // Remove the deleted tasks from the list
    const updatedTasks = tasks.filter((task) => !taskIds.includes(task.task_id));
    setTasks(updatedTasks);
    setFilteredTasks(updatedTasks);
    setSelectedTasks([]);
    setTotalPages(Math.ceil(updatedTasks.length / PAGE_SIZE));


    // SweetAlert success notification
    Swal.fire({
      title: "Deleted!",
      text: "Your task have been deleted.",
      icon: "success",
    });
  } catch (error) {
    console.error('Error deleting tasks:', error);
    setError(error.message);
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: 'Failed to delete task.',
    });
  }
};






const handleSearch = (event) => {
const query = event.target.value.toLowerCase();
setSearchQuery(query);




const filtered = tasks.filter(task =>
  (task.code?.toLowerCase() || '').includes(query) ||
  (task.task?.toLowerCase() || '').includes(query) ||
  (task.start_date?.toLowerCase() || '').includes(query) ||
  (task.due_date?.toLowerCase() || '').includes(query) ||
  (task.status?.toLowerCase() || '').includes(query) ||
  (task.task_points?.toString().toLowerCase() || '').includes(query) ||
  task.assigned_users?.some(user =>
    (user.name?.toLowerCase() || '').includes(query)
  )
);




setFilteredTasks(filtered);
setCurrentPage(1);
setTotalPages(Math.ceil(filtered.length / PAGE_SIZE));
};




const handlePageChange = (event, value) => {
setCurrentPage(value);
};








const handleActionChange = (event) => {
  setAction(event.target.value);
};


// Handle bulk action
const applyAction = async () => {
if (action === 'edit') {
  handleEditTaskClick();
} else if (action === 'delete') {
  if (selectedTasks.length > 0) {
    await handleDeleteTask(selectedTasks); // Pass array of taskIds to delete function
  } else {
    setError('Please select at least one task to delete');
  }
}
};


// Handle checkbox selection
const handleCheckboxChange = (taskId) => {
  setSelectedTasks((prevState) =>
    prevState.includes(taskId)
      ? prevState.filter((id) => id !== taskId)
      : [...prevState, taskId]
  );
};










const paginatedTasks = filteredTasks.slice(
  (currentPage - 1) * PAGE_SIZE,
  currentPage * PAGE_SIZE
);




// Ensure the total pages are calculated after filtering
useEffect(() => {
  setTotalPages(Math.ceil(filteredTasks.length / PAGE_SIZE));
}, [filteredTasks]);




// Handle the case where the currentPage becomes greater than the total pages
useEffect(() => {
  if (currentPage > totalPages && totalPages > 0) {
    setCurrentPage(totalPages);
  }
}, [totalPages, currentPage]);








console.log('Paginated tasks:', paginatedTasks);




const closeModal = () => {
  setIsModalOpen(false);
  setSelectedTaskId(null);
};




const handleFilterChange = () => {
  const filtered = tasks.filter(task => {
    const isWithinDateRange =
      (startDate ? new Date(task.start_date) >= new Date(startDate) : true) &&
      (dueDate ? new Date(task.due_date) <= new Date(dueDate) : true);
    const matchesStatus = selectedStatus ? task.status === selectedStatus : true;
 
    return isWithinDateRange && matchesStatus;
  });
 
  setFilteredTasks(filtered);
  setTotalPages(Math.ceil(filtered.length / PAGE_SIZE));
};








// Navigate to the task detail page with the task ID
const handleTaskNameClick = (taskId) => {
navigate(`/task-detail/${taskId}`);
};








// Toggle the action menu visibility for a specific task
const toggleActionMenu = (taskId) => {
setActionMenuVisible((prevState) => ({
  ...prevState,
  [taskId]: !prevState[taskId],
}));
};








// Handle action menu clicks for Edit and Delete actions
const handleActionMenuClick = (actionType, taskId) => {
if (actionType === 'edit') {
  navigate(`/edit-task/${taskId}`);
} else if (actionType === 'delete') {
  handleDeleteTask(taskId); // Pass single taskId to delete function
}








setActionMenuVisible((prevState) => ({
  ...prevState,
  [taskId]: false,
}));
};








const statusColors = {
"Incomplete": "red",
"To Do": "blue",
"Doing": "orange",
"Completed": "green"
};








return (
  <div className='main-container'>








<div className="top-controls">
<h1 className='title'>Task List
<div className="underline"></div>
</h1>


<div className="buttons-row">
{selectedTasks.length > 0 && (
<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
  <select onChange={handleActionChange} value={action} className="color-button">
    <option value="">Select Action</option>
    <option value="edit">Edit</option>
    <option value="delete">Delete</option>
  </select>
  <button onClick={applyAction} className="color-button">
    Apply
  </button>
</div>
)}








<button className="color-button" onClick={handleMyTaskClick}>
      {fetchMyTasks ? "Show All Tasks" : "My Task"}
    </button>








     <button className="color-button"  onClick={handleAddTaskClick} >
        Add Task
      </button>




      <button className="color-button" onClick={handleDownloadClick}>
       Sample File
    </button>
    <button className="color-button" onClick={open}>
      Import
    </button>
      <button className="color-button" onClick={handleDownloadPdf} >
        Download Table
      </button>
</div>
  </div>








    {/* First Row: Filters and Search Bar */}
  <div className="top-controls">
    <div className="filters-and-search">








      <button className="border-button" >
        Clear Filters
      </button>
     
        <input
          type="date"
          className="border-button"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
     
        <input
          type="date"
          className="border-button"
          value={dueDate}
          onChange={(e) => setDueDate(e.target.value)}
        />
     
       
        <select
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
          className="border-button"
        >
          <option value="">All Statuses</option>
          {statusOptions.map((status) => (
            <option key={status.id} value={status.id}>
              {status.status}
            </option>
          ))}
        </select>
   








      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearch}
        className="searchbar"
      />
     
    </div>
  </div>
   
    <table >
      <thead>
        <tr>
          <th></th>
          <th>Code</th>
          <th>Task</th>
          <th>Start Date</th>
          <th>Due Date</th>
          <th>Status</th>
          <th>Points</th>
          <th>Assigned Users</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {paginatedTasks.map(task => (
          <tr key={task.task_id}>
           <td>
            <input
            type="checkbox"
            checked={selectedTasks.includes(task.task_id)}
            onChange={() => handleCheckboxChange(task.task_id)}
            />
          </td>
            <td>{task.code}</td>
            <td
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={() => handleTaskNameClick(task.task_id)}
            >
              {truncateText(task.task, 6)} <br/>
              <span style={{ color: '#555'}}>{task.project_name}</span>
            </td>
            <td>{task.start_date}</td>
            <td>{task.due_date}</td>
            <td style={{ width: '120px' }}>
    <div className="custom-select-container" style={{ position: 'relative', width: '100%' }}>
      <div
        className="selected-item"
        style={{
          padding: '4px',
          border: '1px solid #ccc',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <span
          style={{
            display: 'inline-block',
            width: '10px',
            height: '10px',
            backgroundColor: statusColors[task.status],
            borderRadius: '50%',
            marginRight: '8px'
          }}
        />
        {task.status}
      </div>
      <select
        value={task.status}
        onChange={(e) => handleStatusChange(task.task_id, e.target.value)}
        style={{
          width: '100%',
          padding: '5px',
          border: 'none',
          cursor: 'pointer',
          backgroundColor: 'transparent',
          appearance: 'none',  // Hide native dropdown arrow
          position: 'absolute',
          top: '0',
          left: '0',
          height: '100%',
          opacity: 0,  // Invisible select
        }}
      >
        {statusOptions.map(option => (
          <option key={option.id} value={option.status}>
            {option.status}
          </option>
        ))}
      </select>
    </div>
  </td>
            <td>{task.task_points}</td>
            <td>
{task.assigned_users && task.assigned_users.length > 0 ? (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {task.assigned_users.slice(0, 3).map((user, index) => (
      <Tooltip key={index} title={user.name} arrow>
        <Avatar
          src={user.image || defaultAvatar}
          alt={user.name}
          className="assigned-user-avatar"
          style={{ marginRight: '5px', width: '30px', height: '30px' }}
        />
      </Tooltip>
    ))}
    {task.assigned_users.length > 3 && (
      <span
        style={{
          backgroundColor: '#e0e0e0',
          borderRadius: '50%',
          padding: '0 8px',
          lineHeight: '30px',
          height: '30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        +{task.assigned_users.length - 3}
      </span>
    )}
  </div>
) : (
  <p className="no-data">No users assigned</p>
)}
</td>








            <td>
             
            <div className="action-container">
<div className="action-dots" onClick={() => toggleActionMenu(task.task_id)}> <GridMoreVertIcon /> </div>
{actionMenuVisible[task.task_id] && (
  <div className="action-menu">
    <div className="action-item" onClick={() => handleActionMenuClick('edit', task.task_id)}>
      Edit
    </div>
    <div className="action-item" onClick={() => handleActionMenuClick('delete', task.task_id)}>
      Delete
    </div>
  </div>
)}
</div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>








    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
      />
    </div>








    {isModalOpen && (
      <TaskDetail taskId={selectedTaskId} onClose={closeModal} />
    )}








    {error && (
      <div style={{ color: 'red', marginTop: '20px' }}>{error}</div>
    )}
























{isOpen && (
      <div style={styles.modalOverlay}>
        <div style={styles.modalContent}>
          <h3 style={styles.modalTitle}>Import File</h3>
          <label style={styles.label}>
            Select Project:
            <select
              value={selectedProject}
              onChange={(e) => setSelectedProject(e.target.value)}
              style={styles.select}
            >
              <option value="">Select a project</option>
              {projects.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.project_name}
                </option>
              ))}
            </select>
          </label>
          <label style={styles.label}>
            Add File:
            <input type="file" onChange={handleFileChange} style={styles.fileInput} />
          </label>
          <div style={styles.buttonContainer}>
            <button onClick={handleSubmit} className='color-button'>
              Upload
            </button>
            <button onClick={close} className='color-button'>
              Cancel
            </button>
          </div>
        </div>
      </div>
    )}
  </div>
);
};








export default Task;
















const styles = {
modalOverlay: {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
},
modalContent: {
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '8px',
  width: '400px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
},
label: {
  display: 'block',
  marginBottom: '10px',
  fontSize: '14px',
  color: '#333',
},
select: {
  width: '100%',
  padding: '8px',
  margin: '5px 0 15px',
  border: '1px solid #ccc',
  borderRadius: '4px',
  fontSize: '14px',
},
fileInput: {
  width: '95%',
  padding: '8px',
  margin: '5px 0 15px',
  border: '1px solid #ccc',
  borderRadius: '4px',
  fontSize: '14px',
},
buttonContainer: {
  display: 'flex',
  justifyContent: 'space-between',
},


};
















 
 







