

import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, MenuItem, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




const DailyUpdate = ({ open, handleClose, userProfile }) => {
 const [projects, setProjects] = useState([{ projectName: '' }]);
 const [projectList, setProjectList] = useState([]);
 const [summary, setSummary] = useState('');
 const [challenges, setChallenges] = useState('');
 const [mood, setMood] = useState('Happy');
 const [images, setImages] = useState([]);




 useEffect(() => {
   if (open) {
     const token = localStorage.getItem('authToken');




     if (token) {
       fetch('https://api.myntask.com/task/project_list', {
         method: 'GET',
         headers: {
           'Authorization': `Bearer ${token}`,
           'Content-Type': 'application/json',
         },
       })
         .then(response => response.json())
         .then(data => {
           console.log('Project List Response:', data);
           setProjectList(data || []);
         })
         .catch(error => {
           console.error('Error fetching project list:', error);
         });
     } else {
       console.error('No auth token found.');
     }
   }
 }, [open]);




 const handleProjectChange = (index, value) => {
   const updatedProjects = [...projects];
   updatedProjects[index].projectName = value;
   setProjects(updatedProjects);
 };




 const addAnotherProject = () => {
   setProjects([...projects, { projectName: '' }]);
 };




 const handleImageChange = (e) => {
   setImages([...e.target.files]);
 };




 const handleSubmit = async () => {
   if (!summary) {
     toast.error("Please fill in all required fields.");
     return;
   }




   const token = localStorage.getItem('authToken');
   if (!token) {
     console.error('No auth token found.');
     toast.error("Authentication token is missing.");
     return;
   }




   const formData = new FormData();
   formData.append('project', projects[0].projectName);
   formData.append('summary', summary);
   formData.append('mood', mood.toLowerCase());
   formData.append('challenges', challenges);




   images.forEach((image, index) => {
     formData.append(`reports`, image);
   });




   console.log('Submitting formData:', formData);




   try {
     const response = await fetch('https://api.myntask.com/home/daily_report', {
       method: 'POST',
       headers: {
         'Authorization': `Bearer ${token}`,
         // Note: Do not set 'Content-Type' to 'application/json' for FormData
       },
       body: formData,
     });




     if (!response.ok) {
       // Log detailed response info to help diagnose the issue
       const errorResponse = await response.text();
       console.error('Error response:', response.status, response.statusText, errorResponse);
       throw new Error(`Network response was not ok. Status code: ${response.status}`);
     }




     toast.success("Your daily update is updated successfully");
     handleClose();
   } catch (error) {
     console.error('Error submitting daily update:', error);
     toast.error("Failed to update daily report. Please try again.");
   }
 };




 return (
   <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
     {/* Submit Daily Report Form */}
    <div
      style={{
        textAlign: 'center',
        padding: '16px',
        fontWeight: 'bold',
        fontSize: '1.3rem',
        borderRadius: '10px 10px 0 0',
      }}
    >
      Submit Daily Report
    </div>
 
    <div
      style={{
        padding: '16px',
        borderRadius: '0 0 10px 10px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        maxHeight: '450px', // Set max height
        overflowY: 'auto', // Enable vertical scrolling
      }}
    >
     
      {/* <label>
        Date
        <input
          type="date"
          value={new Date().toISOString().split('T')[0]}
          disabled
          style={styles.dailyinput}
         
        />
      </label> */}
 
      {/* Projects Worked On */}
      {projects.map((project, index) => (
  <div key={index}>
    <label>
      Project
      <select
        value={project.projectName || ''} // Ensure no value is selected by default
        onChange={(e) => handleProjectChange(index, e.target.value)}
        style={styles.dailyinput}
      >
        {/* "Select a project" option */}
        <option value="" disabled>
          Select a project
        </option>
       
        {/* Map over the project list */}
        {projectList.map((projectItem) => (
          <option key={projectItem.id} value={projectItem.id}>
            {projectItem.project_name}
          </option>
        ))}
      </select>
    </label>
  </div>
))}


 
      {/* Summary of Work Done */}
      <label>
        Summary
        <textarea
          value={summary}
          onChange={(e) => setSummary(e.target.value)}
          placeholder="Describe your main activities..."
          rows="2"
          style={styles.dailyinput}
        />
      </label>
 
      {/* Challenges or Dependencies */}
      <label>
        Challenges
        <textarea
          value={challenges}
          onChange={(e) => setChallenges(e.target.value)}
          placeholder="Blockers or challenges faced today..."
          rows="2"
          style={styles.dailyinput}
        />
      </label>
 
      {/* Your Mood Today */}
      <label>
        Mood
        <select
          value={mood}
          onChange={(e) => setMood(e.target.value)}
          style={styles.dailyinput}
        >
          <option value="happy">😊 Happy</option>
          <option value="neutral">😐 Neutral</option>
          <option value="unhappy">😞 Unhappy</option>
        </select>
      </label>
 
     {/* Image Upload */}
         <Button
           variant="contained"
           component="label"
           sx={{ mt: 2, fontWeight: '600' }}
         >
           Upload Images
           <input
             type="file"
             hidden
             multiple
             accept="image/*"
             onChange={handleImageChange}
           />
         </Button>
         <Typography variant="caption" sx={{ mt: 1 }}>
           {images.length > 0 ? `${images.length} image(s) selected` : "No images selected"}
         </Typography>




    </div>
 
    {/* Actions */}
    <div style={{ padding: '16px', display: 'flex', justifyContent: 'space-between' }}>
      <button
        onClick={handleSubmit}
        style={{
          fontWeight: 'bold',
          width: '45%',
          fontSize: '0.9rem',
          padding: '10px',
          borderRadius: '8px',
          backgroundColor: '#007BFF',
          color: '#fff',
          cursor: 'pointer',
        }}
      >
        Save
      </button>
      <button
        onClick={handleClose}
        style={{
          fontWeight: 'bold',
          width: '45%',
          fontSize: '0.9rem',
          padding: '10px',
          borderRadius: '8px',
          backgroundColor: 'transparent',
          border: '1px solid #007BFF',
          color: '#007BFF',
          cursor: 'pointer',
        }}
      >
        Cancel
      </button>
    </div>


   </Dialog>
 );
};




export default DailyUpdate;




const styles = {
  dailyinput : {
    width: '100%',
    padding: '10px',
    borderRadius: '8px',
    fontSize: '0.9rem',
    marginTop: '8px',
  }
}
 




   




 






