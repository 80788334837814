import React, { useEffect } from 'react';

const Notification = () => {
  return (
    <div>
      Notification
    </div>
  );
};

export default Notification;







