import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import './AddExpenseModal.css'; // Import the CSS file




const EditExpense = () => {
 const { expenseId } = useParams(); // Get expenseId from the URL
 const navigate = useNavigate();
 const [expenseDetails, setExpenseDetails] = useState(null);
 const [formData, setFormData] = useState({});
 const [currencies, setCurrencies] = useState([]);
 const [projects, setProjects] = useState([]);
 const [categories, setCategories] = useState([]);






 useEffect(() => {
   if (expenseId) {
     const fetchExpenseDetails = async () => {
       const token = localStorage.getItem('authToken');
       if (!token) return;








       try {
         const response = await fetch(`https://api.myntask.com/expenses/edit/${expenseId}`, {
           method: 'GET',
           headers: {
             'Content-Type': 'application/json',
             Authorization: `Bearer ${token}`,
           },
         });








         if (!response.ok) {
           throw new Error('Failed to fetch expense details');
         }








         const data = await response.json();








         if (Array.isArray(data) && data.length > 0) {
           setExpenseDetails(data[0]);
           setFormData(data[0]);
         } else {
           console.error('Unexpected data format:', data);
         }
       } catch (err) {
         console.error('Error fetching expense details:', err);
       }
     };








     fetchExpenseDetails();
   }
 }, [expenseId]);








 useEffect(() => {
   const fetchCurrency = async () => {
     try {
       const response = await fetch('https://api.myntask.com/company/currency');
       if (!response.ok) {
         throw new Error(`HTTP error! status: ${response.status}`);
       }
       const data = await response.json();
       console.log('Currency fetched:', data);
       setCurrencies(data || []);
     } catch (error) {
       console.error('Error fetching currency:', error);
       setCurrencies([]);
     }
   };








   const fetchProjects = async () => {
     const token = localStorage.getItem('authToken');
     try {
       const response = await fetch('https://api.myntask.com/task/project_list', {
         method: 'GET',
         headers: {
           Authorization: `Bearer ${token}`,
           'Content-Type': 'application/json',
         },
       });
       if (!response.ok) {
         throw new Error(`HTTP error! status: ${response.status}`);
       }
       const data = await response.json();
       console.log('Projects fetched:', data);
       setProjects(data || []);
     } catch (error) {
       console.error('Error fetching Projects:', error);
       setProjects([]);
     }
   };








   const fetchCategory = async () => {
     const token = localStorage.getItem('authToken');
     try {
       const response = await fetch('https://api.myntask.com/expenses/category', {
         method: 'GET',
         headers: {
           Authorization: `Bearer ${token}`,
           'Content-Type': 'application/json',
         },
       });
       if (!response.ok) {
         throw new Error(`HTTP error! status: ${response.status}`);
       }
       const data = await response.json();
       console.log('Categories fetched:', data);
       setCategories(data || []);
     } catch (error) {
       console.error('Error fetching Categories:', error);
       setCategories([]);
     }
   };




  fetchCurrency();
  fetchProjects();
  fetchCategory();


 }, []);








 const handleInputChange = (e) => {
   const { name, value } = e.target;
   setFormData((prev) => ({ ...prev, [name]: value }));
 };










 const handleSubmit = async (e) => {
   e.preventDefault();
   const token = localStorage.getItem('authToken');
   if (!token) return;
 
   const updatedData = {
     id: expenseId,
     item_name: formData.item_name || '',
     currency_id: formData.currency_id || '',
     price: formData.price || '',
     purchase_date: formData.purchase_date || '',
     project_id: formData.project_id || '',
     category_id: formData.category_id || '',
     purchase_from: formData.purchase_from || '',
     description: formData.description || '',
     expense_type: formData.expense_type || '', // Added expense_type
     site_name: formData.site_name || '', // Added site_name
   };
 
   try {
     const response = await fetch('https://api.myntask.com/expenses/update', {
       method: 'PUT',
       headers: {
         'Content-Type': 'application/json',
         Authorization: `Bearer ${token}`,
       },
       body: JSON.stringify(updatedData),
     });
 
     if (!response.ok) {
       throw new Error('Failed to update expense');
     }
 
     // Show success alert
     Swal.fire({
       icon: 'success',
       title: 'Expense Updated',
       text: 'Your expense has been successfully updated.',
       confirmButtonText: 'OK',
     }).then(() => {
       // Redirect after confirmation
       navigate('/expense');
     });
 
   } catch (err) {
     // Show error alert
     Swal.fire({
       icon: 'error',
       title: 'Update Failed',
       text: 'There was an error updating the expense. Please try again.',
       confirmButtonText: 'OK',
     });
     
     console.error('Error updating expense:', err);
   }
 };
 






 return (
  <div className="main-container">
  <div className="top-controls">
   <h1 className='title'>Add Expense
     <div className="underline"></div>
   </h1>
 </div>
 {expenseDetails ? (
  <form onSubmit={handleSubmit} className="expenseForm">
     <div className="form-row">
     <div className="formgroup">
   
      <label>Item Name:</label>
      <input
      className="expenseInput"
     
        type="text"
        name="item_name"
        value={formData.item_name || ''}
        onChange={handleInputChange}
       
      />
    </div>
    <div className="formgroup">
      <label>Price:</label>
      <input
      className="expenseInput"
        type="number"
        name="price"
        value={formData.price || ''}
        onChange={handleInputChange}
      />
    </div>
    </div>
    <div className="form-row">
    <div className="formgroup">
      <label>Purchase From:</label>
      <input
      className="expenseInput"
        type="text"
        name="purchase_from"
        value={formData.purchase_from || ''}
        onChange={handleInputChange}
      />
    </div>
    <div className="formgroup">
      <label>Purchase Date:</label>
      <input
      className="expenseInput"
        type="date"
        name="purchase_date"
        value={formData.purchase_date || ''}
        onChange={handleInputChange}
      />
    </div>
    </div>
    <div className="form-row">
    <div className="formgroup">
      <label>Currency:</label>
      <select
       
      className="expenseInput"
        name="currency_id"
        value={formData.currency_id || ''}
        onChange={handleInputChange}
      >
        <option value="">Select Currency</option>
        {currencies.map((currency) => (
          <option key={currency.id} value={currency.id}>
            {currency.currency_code}
          </option>
        ))}
      </select>
    </div>
    <div className="formgroup">
      <label>Project:</label>
      <select
        className="expenseInput"
        name="project_id"
        value={formData.project_id || ''}
        onChange={handleInputChange}
      >
        <option value="">Select Project</option>
        {projects.map((project) => (
          <option key={project.id} value={project.id}>
            {project.project_name}
          </option>
        ))}
      </select>
    </div>
    </div>
    <div className="form-row">
    <div className="formgroup">
      <label>Category:</label>
      <select
        className="expenseInput"
        name="category_id"
        value={formData.category_id || ''}
        onChange={handleInputChange}
      >
        <option value="">Select Category</option>
        {categories.map((category) => (
          <option key={category.id} value={category.id}>
            {category.category_name}
          </option>
        ))}
      </select>
    </div>
    <div className="formgroup">
      <label>Expense Type:</label>
      <select
        className="expenseInput"
        name="expense_type"
        value={formData.expense_type || ''}
        onChange={handleInputChange}
      >
        <option value="">Select Expense Type</option>
        {/* Add expense types if available */}
        <option value="personal_expense">Personal Expense</option>
                 <option value="site_expense">Site Expense</option>
      </select>
    </div>
    </div>
    <div className="form-row">
    <div className="formgroup">
      <label>Site Name:</label>
      <input
      className="expenseInput"
        type="text"
        name="site_name"
        value={formData.site_name || ''}
        onChange={handleInputChange}
      />
    </div>
    <div className="formgroup">
      <label>Description:</label>
      <textarea
        className="expenseInput"
        name="description"
        value={formData.description || ''}
        onChange={handleInputChange}
      />
    </div>
    </div>
    <div className="form-buttons">
      <button type="submit" className="color-button">Save</button>
      <button type="button" onClick={() => navigate('/expense')} className="cancelButton">
        Cancel
      </button>
    </div>
  </form>
) : (
  <p>Loading...</p>
)}


   </div>
 );
};








export default EditExpense;







































