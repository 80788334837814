import React, { useEffect, useState } from 'react';
import { Modal, Box} from '@mui/material';
import EditDepartmentModal from '../Components/EditDepartmentModal';
import { GridMoreVertIcon } from '@mui/x-data-grid';








function Department() {
  const [departments, setDepartments] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const [newDepartment, setNewDepartment] = useState({ department: '', parent: '' });
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [action, setAction] = useState(''); // Default is '' for "No action"
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [actionMenuVisible, setActionMenuVisible] = useState({});
  const [selectedId, setSelectedId] = useState(null); // For tracking the department ID to be edited/deleted
  const [permissions, setPermissions] = useState({});
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [viewData, setViewData] = useState(null);








  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No authentication token found');
      return;
    }




    const fetchDepartments = async () => {
      try {
        const response = await fetch('https://api.myntask.com/employee/department_list', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });




        if (!response.ok) {
          throw new Error('Failed to fetch departments');
        }
     
        const data = await response.json();
        console.log('department',data);




        setDepartments(data.data);
        setPermissions(data.department_permission);
        console.log('permissions',data.department_permission);




      } catch (err) {
        console.error('Error fetching departments:', err);
        setError('Error fetching departments');
      }
    };




    const fetchDepartmentOptions = async () => {
      try {
        const response = await fetch('https://api.myntask.com/employee/department', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
















        if (!response.ok) {
          throw new Error('Failed to fetch department options');
        }
















        const data = await response.json();
        setDepartmentOptions(data);
      } catch (err) {
        console.error('Error fetching department options:', err);
        setError('Error fetching department options');
      }
    };
















    fetchDepartments();
    fetchDepartmentOptions();
  }, []);




  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);




  const handleAddDepartment = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No authentication token found');
      return;
    }




    try {
      const response = await fetch('https://api.myntask.com/employee/department_save', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: newDepartment.department,
          parent_id: newDepartment.parent || null,
        }),
      });




      if (!response.ok) {
        throw new Error('Failed to save department');
      }




      const data = await response.json();
      console.log('Saved department:', data);




      if (data.insertId) {
        setDepartments((prevDepartments) => [
          ...prevDepartments,
          { id: data.insertId, department: newDepartment.department, parent: newDepartment.parent || 'None' },
        ]);
      } else {
        throw new Error('Invalid response from the server');
      }




      handleClose();
    } catch (err) {
      console.error('Error saving department:', err);
      setError('Error saving department');
    }
  };




  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewDepartment((prev) => ({ ...prev, [name]: value }));
  };








  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };




  const filteredDepartments = departments.filter((department) =>
    department.department.toLowerCase().includes(searchTerm.toLowerCase())
  );




  const handleCheckboxChange = (e, departmentId) => {
    if (e.target.checked) {
      setSelectedDepartments([...selectedDepartments, departmentId]);
    } else {
      setSelectedDepartments(selectedDepartments.filter(id => id !== departmentId));
    }
  };




  const handleApplyAction = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No authentication token found');
      return;
    }




    try {
      if (action === 'delete') {
        for (const id of selectedDepartments) {
          const response = await fetch('https://api.myntask.com/employee/department_delete', {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ department_id: id }),
          });




          if (!response.ok) {
            throw new Error(`Failed to delete department with ID ${id}`);
          }
        }




        // Remove deleted departments from the state
        setDepartments(departments.filter(dept => !selectedDepartments.includes(dept.id)));
        setSelectedDepartments([]);
        setAction('');
      } else if (action === 'edit') {
        if (selectedDepartments.length > 0) {
          console.log('Editing department:', selectedDepartments[0]);
          setEditModalOpen(true); // Open edit modal
        } else {
          setError('No department selected to edit');
        }
      }
    } catch (err) {
      console.error('Error applying action:', err);
      setError('Error applying action');
    }
  };




  const toggleActionMenu = (id) => {
    setActionMenuVisible((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
    setSelectedId(id);
  };




  const handleAction = (actionType, departmentData) => {
    if (actionType === 'view') {
      setViewData(departmentData);
      setViewModalOpen(true);
    } else {
      setAction(actionType);
      setSelectedDepartments([departmentData.id]); // Set the department ID for edit/delete
      if (actionType === 'delete') {
        handleApplyAction(); // Apply delete action
      } else if (actionType === 'edit') {
        setEditModalOpen(true); // Open edit modal
      }
    }
  };








  return (
    <div className='main-container'>








      <div className="top-controls">
       <h1 className='title'>Department
       <div className="underline"></div>
      </h1>
      {/* Buttons row for batch actions */}
      <div className="buttons-row">
        {selectedDepartments.length > 0 && (
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <select onChange={(e) => setAction(e.target.value)} value={action} className="color-button">
              <option value="">Select Action</option>
              <option value="edit">Edit</option>
              <option value="delete">Delete</option>
            </select>
            <button onClick={handleApplyAction} className="color-button">Apply</button>
          </div>
        )}




        <button className="color-button" onClick={handleOpen}>
          Add Department
        </button>
      </div>




    </div>








      {/* First Row: Filters and Search Bar */}
    <div className="top-controls">
      <div className="filters-and-search">








        <button className="border-button" >
          Clear Filters
        </button>
        <button className="border-button" >
          Import
        </button>








        <input
          type="text"
          placeholder="Search Departments"
          value={searchTerm}
          onChange={handleSearchChange}
          className="searchbar"
        />
       
      </div>
    </div>




      {error && <p style={{ color: 'red' }}>{error}</p>}
      {filteredDepartments.length > 0 ? (
        <table>
          <thead>
            <tr>
             
              <th>Select</th>
              <th>Name</th>
              <th>Parent</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredDepartments.map((department, index) => (
              <tr key={department.id}>
               
                <td>
      <input
        type="checkbox"
        checked={selectedDepartments.includes(department.id)}
        onChange={(e) => handleCheckboxChange(e, department.id)}
      />
    </td>




                <td>{department.department}</td>
                <td>{department.parent || 'None'}</td>
                <td>
                <div className="action-container">
                  <div className="action-dots" onClick={() => toggleActionMenu(department.id)}>
                    {/* Use any icon for menu dots */}
                    <GridMoreVertIcon />
                  </div>
                  {actionMenuVisible[department.id] && (
                    <div className="action-menu">
                      <div className="action-item" onClick={() => handleAction('edit', department)}>
                        Edit
                      </div>
                      <div className="action-item" onClick={() => handleAction('delete', department)}>
                        Delete
                      </div>
                      <div className="action-item" onClick={() => handleAction('view', department)}>
                        View
                      </div>
                    </div>
                  )}
                </div>
              </td>




              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No departments found</p>
      )}




     
     <Modal open={open} onClose={handleClose}>
        <Box sx={{ p: 4, bgcolor: 'background.paper', margin: 'auto', width: '400px', marginTop: 10, position: 'relative' ,borderRadius:'10px'}}>


        <div className="top-controls">
        <h1 className='title'>Add Department
          <div className="underline"></div>
        </h1>




        <div className="buttons-row">
         
            <button  style={{color:"black",padding:'0px',backgroundColor:'transparent',fontSize:'22px'}}  onClick={handleClose}>
              X
            </button>
       
        </div>
      </div>
         
          <label htmlFor="department">Department Name</label>
    <input
      type="text"
      id="department"
      name="department"
      value={newDepartment.department}
      onChange={handleChange}
      className='field'
    />
   
    <label htmlFor="parent">Parent</label>
    <select
      id="parent"
      name="parent"
      value={newDepartment.parent}
      onChange={handleChange}
      className='drop'
    >
      <option value="">
        None
      </option>
      {departmentOptions.map((option) => (
        <option key={option.id} value={option.id}>
          {option.department}
        </option>
      ))}
    </select>
          <button
             className='color-button' style={{marginTop:'20px'}}
            onClick={handleAddDepartment}
          >
            Save
          </button>
        </Box>
      </Modal>




      {editModalOpen && (
        <EditDepartmentModal
          open={editModalOpen}
          handleClose={() => setEditModalOpen(false)}
          departmentId={selectedDepartments[0]} // Assuming you want to edit the first selected department
        />
      )}




           {/* View Modal */}
           <Modal open={viewModalOpen} onClose={() => setViewModalOpen(false)}>
           <Box
    sx={{
      backgroundColor: 'white',
      width: '400px',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      outline: 'none',
    }}
  >
          {viewData && (
            <>
              <h2 style={{ marginBottom: '20px' }}>View Department</h2>
              <p>
                <strong>Department:</strong> {viewData.department}
              </p>
              <p>
                <strong>Parent:</strong> {viewData.parent || 'None'}
              </p>
              {/* Close button */}
              <button onClick={() => setViewModalOpen(false)}
                style={{
                  backgroundColor: '#f44336',
                  color: 'white',
                  border: 'none',
                  padding: '10px 20px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  position: 'absolute',   // Add absolute positioning
                  bottom: '20px',         // Position it 20px from the bottom
                  right: '20px',          // Position it 20px from the right
                }}>
                Close
              </button>
            </>
          )}
        </Box>
      </Modal>
     
    </div>
  );
}








export default Department;






























































  
