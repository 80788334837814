



 
   import React, { useEffect, useState } from 'react';
   import { CircularProgress, Pagination, Avatar, Tooltip } from '@mui/material';
   import { useNavigate } from 'react-router-dom';
   import defaultAvatar from '../assets/vector.jpeg';
   import { GridMoreVertIcon } from '@mui/x-data-grid';
   import { toast, ToastContainer } from 'react-toastify';
   import './../Components/Main.css';
   import jsPDF from 'jspdf';
   import 'jspdf-autotable';
   
   
   const PAGE_SIZE = 15;
   
   const Projects = () => {
     const [projects, setProjects] = useState([]);
     const [loading, setLoading] = useState(true);
     const [error, setError] = useState('');
     const [selectedProjects, setSelectedProjects] = useState([]);
     const [action, setAction] = useState('');
     const [searchQuery, setSearchQuery] = useState('');
     const [currentPage, setCurrentPage] = useState(1);
     const [totalPages, setTotalPages] = useState(1);
     const [statuses, setStatuses] = useState([]);
     const [actionMenuVisible, setActionMenuVisible] = useState({});
     const [selectedProjectId, setSelectedProjectId] = useState(null);
     const [hoveredProjectId, setHoveredProjectId] = useState(null);
      const [selectedStatus, setSelectedStatus] = useState(''); // State for the selected status
   
     const navigate = useNavigate();
   
   
       const fetchProjects = async (status) => {
         const token = localStorage.getItem('authToken');
         if (!token) {
           setError('No authentication token found.');
           setLoading(false);
           return;
         }
   
         try {
           const response = await fetch(`https://api.myntask.com/project/list?status_name=${status || ''}`, {
             method: 'GET',
             headers: {
               'Content-Type': 'application/json',
               Authorization: `Bearer ${token}`,
             },
           });
   
           if (!response.ok) {
             throw new Error(`Network response was not ok. Status: ${response.status}`);
           }
   
           const data = await response.json();
           if (Array.isArray(data)) {
             setProjects(data);
             setTotalPages(Math.ceil(data.length / PAGE_SIZE));
           } else {
             setError('Unexpected data format received.');
           }
           console.log('projects are', projects);
         } catch (error) {
           setError('Failed to fetch projects. Please try again later.');
         } finally {
           setLoading(false);
         }
       };
   
       const fetchStatuses = async () => {
         const token = localStorage.getItem('authToken');
         if (!token) {
           setError('No authentication token found.');
           return;
         }
   
         try {
           const response = await fetch('https://api.myntask.com/project/status', {
             method: 'GET',
             headers: {
               'Content-Type': 'application/json',
               Authorization: `Bearer ${token}`,
             },
           });
   
           if (!response.ok) {
             throw new Error(`Network response was not ok. Status: ${response.status}`);
           }
   
           const data = await response.json();
           setStatuses(data);
         } catch (error) {
           setError('Failed to fetch statuses. Please try again later.');
         }
       };
   
   
     useEffect(() => {
       fetchStatuses(); // Fetch statuses initially
       fetchProjects(selectedStatus); // Fetch projects based on initial selected status (empty initially)
     }, []);
   
     const handleDownload = () => {
       const doc = new jsPDF();
   
       const tableColumn = ["Code", "Project Name", "Start Date", "Deadline", "Status", "Total Task Points", "Clients", "Members"];
       const tableRows = [];
   
       filteredProjects.forEach(project => {
         const membersNames = project.members && project.members.length > 0
           ? project.members.map(member => member.member_name).join(', ')
           : 'No members available';
       
         const rowData = [
           project.code || 'N/A',
           project.project_name || 'N/A',
           project.start_date || 'N/A',
           project.deadline || 'N/A',
           project.status || 'N/A',
           project.total_task_points || 'N/A',
           project.clients && project.clients.length > 0 ? project.clients[0]?.company_name || 'N/A' : 'No client available',
           membersNames
         ];
   
         tableRows.push(rowData);
       });
   
       doc.autoTable({
         head: [tableColumn],
         body: tableRows,
       });
   
       doc.save('projects_table.pdf');
     };
   
   
     const handlePageChange = (event, value) => {
       setCurrentPage(value);
     };
   
     const handleAddProject = () => {
       navigate('/addproject');
     };
   
     const handleCheckboxChange = (projectId) => {
       setSelectedProjects((prevSelected) =>
         prevSelected.includes(projectId)
           ? prevSelected.filter((id) => id !== projectId)
           : [...prevSelected, projectId]
       );
     };
   
     const handleActionChange = (event) => {
       setAction(event.target.value);
     };
   
     const handleDelete = async (projectIds) => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No authentication token found.');
        toast.error('No authentication token found.');  // Show error toast if no token is found
        return;
      }
   
      try {
        const deletePromises = projectIds.map(async (projectId) => {
          const response = await fetch('https://api.myntask.com/project/delete', {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ project_id: projectId }),
          });
   
          if (!response.ok) {
            throw new Error(`Failed to delete project with ID: ${projectId}`);
          }
   
          const responseData = await response.json();
          return responseData;
        });
   
        await Promise.all(deletePromises);
   
        // Update the UI to reflect deleted projects
        setProjects((prevProjects) =>
          prevProjects.filter((project) => !projectIds.includes(project.project_id))
        );
        setSelectedProjects([]);
        setTotalPages(Math.ceil(projects.length / PAGE_SIZE));
   
        // Display success toast
        toast.success('Projects deleted successfully!');
   
      } catch (error) {
        console.error(error);
        setError('Failed to delete projects. Please try again later.');
       
        // Display error toast
        toast.error('Failed to delete projects. Please try again.');
      }
    };
   
    const handleApply = () => {
      if (action === 'edit' && selectedProjects.length === 1) {
        navigate(`/editproject/${selectedProjects[0]}`);
      } else if (action === 'delete') {
        handleDelete(selectedProjects);  // Pass selectedProjects to handleDelete
      }
      setSelectedProjects([]);
      setAction('');
    };
   
   
     const handleSearchChange = (event) => {
       setSearchQuery(event.target.value.toLowerCase());
     };
   
     const handleDropdownChange = async (event, projectId) => {
      const { value } = event.target;
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No authentication token found.');
        toast.error('No authentication token found.');  // Show error toast if no token
        return;
      }
   
      try {
        const response = await fetch('https://api.myntask.com/project/status_update', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            project_id: projectId,
            status_name: value,
          }),
        });
   
        if (response.ok) {
          // Status update was successful
          console.log('Status updated successfully!');
          toast.success('Project status updated successfully!');
         
          // Update project status in the state
          setProjects((prevProjects) =>
            prevProjects.map((project) =>
              project.project_id === projectId ? { ...project, status: value } : project
            )
          );
        } else {
          // Handle any error response
          console.error('Error updating status:', response.statusText);
          toast.error('Failed to update project status. Please try again.');
        }
      } catch (error) {
        console.error('Error updating status:', error);
        setError('Failed to update project status.');
        toast.error('Failed to update project status. Please try again.');
      }
    };
   
   
     const filteredProjects = projects
     .filter((project) => project.project_name.toLowerCase().includes(searchQuery))
     .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE);
   
     const toggleActionMenu = (id) => {
       setActionMenuVisible((prevState) => ({
         ...prevState,
         [id]: !prevState[id],
       }));
       setSelectedProjectId(id);
     };
   
     const handleActionMenuClick = async (actionType, selectedProjectId) => {
      if (actionType === 'edit') {
        navigate(`/editproject/${selectedProjectId}`);
      } else if (actionType === 'delete') {
        await handleDelete([selectedProjectId]);  // Pass the project ID directly
      } else if (actionType === 'view') {
        navigate(`/Projectdashboard/${selectedProjectId}`);
      }
   
      setActionMenuVisible((prevState) => ({
        ...prevState,
        [selectedProjectId]: false,
      }));
    };
   
   
   
   
   
     const handleProjectClick = (projectId) => {
       navigate(`/Projectdashboard/${projectId}`);
     };
   
     return (
       <div className="main-container">
         <ToastContainer />
         <div className="top-controls">
           <h1 className="title">
             Projects
             <div className="underline"></div>
           </h1>
     
           <div className="buttons-row">
           <button onClick={handleDownload} className="color-button">
               Download Table
             </button>
             {selectedProjects.length > 0 && (
               <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                 <select value={action} onChange={handleActionChange} className="color-button">
                   <option value="" disabled>
                     Select Action
                   </option>
                   <option value="edit">Edit</option>
                   <option value="delete">Delete</option>
                 </select>
                 <button
                   className="color-button"
                   onClick={handleApply}
                   disabled={!action || (action === 'edit' && selectedProjects.length !== 1)}
                 >
                   Apply
                 </button>
               </div>
             )}
             <button onClick={handleAddProject} className="color-button">
               Add Projects
             </button>
           </div>
         </div>
   
         <div className="top-controls">
           <div className="filters-and-search">
             <button className="border-button">Clear Filters</button>
             <button className="border-button">Import</button>
             <input
               type="text"
               placeholder="Search..."
               value={searchQuery}
               onChange={handleSearchChange}
               className="searchbar"
             />
           </div>
         </div>
   
         {loading ? (
           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
             <CircularProgress />
           </div>
         ) : error ? (
           <p className="error-text">{error}</p>
         ) : (
           <>
     
               <table>
                 <thead>
                   <tr>
                     <th></th>
                     <th>Code</th>
                     <th>Project Name</th>
                     <th>Start Date</th>
                     <th>Deadline</th>
                     <th>
           <select className='header-filter'
             value={selectedStatus}
             onChange={(e) => {
               setSelectedStatus(e.target.value);
               fetchProjects(e.target.value); // Fetch projects with the selected status
             }}
           >
             <option value="">All Statuses</option>
             {statuses.map((status) => (
               <option key={status.id} value={status.status_name}>
                 {status.status_name}
               </option>
             ))}
           </select>
         </th>
                     <th>Total Task Points</th>
                     <th>Clients</th>
                     <th>Members</th>
                     <th>Action</th>
                   </tr>
                 </thead>
                 <tbody>
                   {filteredProjects.map((project, index) => (
                     <tr key={project.project_id} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                       <td>
                         <input
                           type="checkbox"
                           checked={selectedProjects.includes(project.project_id)}
                           onChange={() => handleCheckboxChange(project.project_id)}
                         />
                       </td>
                       <td>{project.code || 'N/A'}</td>
                       <td
                         className="clickable-project-name"
                         onClick={() => handleProjectClick(project.project_id)}
                         style={{ cursor: 'pointer', color: '#1976d2', textDecoration: 'underline' }}
                       >
                         {project.project_name}
                       </td>
                       <td>{project.start_date || 'N/A'}</td>
                       <td style={{ color: 'red' }}>{project.deadline || 'N/A'}</td>
   
                       <td className="status-cell">
                         <select
                           id="status"
                           name="status"
                           value={project.status || ''}
                           onChange={(event) => handleDropdownChange(event, project.project_id)}
                           style={{width:'100%'}}
                         >
                           <option value="" disabled>Select Status</option>
                           {statuses.map((status) => (
                             <option key={status.id} value={status.status_name}>
                               {status.status_name}
                             </option>
                           ))}
                         </select>
                         <div
           style={styles.statusBarContainer}
           onMouseEnter={() => setHoveredProjectId(project.project_id)}
           onMouseLeave={() => setHoveredProjectId(null)}
         >
           <div style={styles.statusBarWrapper}>
             <div style={styles.statusBar(project.completion_percentage)} />
             <span
               style={{
                 ...styles.tooltip,
                 ...(hoveredProjectId === project.project_id
                   ? styles.tooltipVisible
                   : {}),
               }}
             >
               {project.completion_percentage}%
             </span>
           </div>
         </div>
   
                       </td>
   
                       <td>{project.total_task_points || 'N/A'}</td>
                       <td>
                         {project.clients && project.clients.length > 0 ? (
                           <div style={{ display: 'flex', alignItems: 'center' }}>
                             <Avatar
                               src={project.clients[0]?.client_image || defaultAvatar}
                               alt={project.clients[0]?.client_name}
                               className="client-avatar"
                               style={{ width: '30px', height: '30px' }}
                             />
                             <div style={{ marginLeft: '10px' }}>{project.clients[0]?.company_name}</div>
                           </div>
                         ) : (
                           <p className="no-data">No client available</p>
                         )}
                       </td>
                       <td>
                         {project.members && project.members.length > 0 ? (
                           <div style={{ display: 'flex', alignItems: 'center' }}>
                             {project.members.slice(0, 3).map((member, index) => (
                               <Tooltip key={index} title={member.member_name} arrow>
                                 <Avatar
                                   src={member.member_image || defaultAvatar}
                                   alt={member.member_name}
                                   className="member-avatar"
                                   style={{ marginRight: '5px', width: '30px', height: '30px' }}
                                 />
                               </Tooltip>
                             ))}
                             {project.members.length > 3 && (
                               <span
                                 style={{
                                   backgroundColor: '#e0e0e0',
                                   borderRadius: '50%',
                                   padding: '0 8px',
                                   lineHeight: '30px',
                                   height: '30px',
                                 }}
                               >
                                 +{project.members.length - 3}
                               </span>
                             )}
                           </div>
                         ) : (
                           <p className="no-data">No members available</p>
                         )}
                       </td>
                       <td>
     <div className="action-container">
       <div className="action-dots" onClick={() => toggleActionMenu(project.project_id)}>
         <GridMoreVertIcon />
       </div>
       {actionMenuVisible[project.project_id] && (
         <div className="action-menu">
<div className="action-item" onClick={() => handleActionMenuClick('view', project.project_id)}>
  View
</div>
<div className="action-item" onClick={() => handleActionMenuClick('edit', project.project_id)}>
  Edit
</div>
<div className="action-item" onClick={() => handleActionMenuClick('delete', project.project_id)}>
  Delete
</div>


         </div>
       )}
     </div>
   </td>
   
                     </tr>
                   ))}
                 </tbody>
               </table>
           
             <div style={{ justifyContent: 'center', display: 'flex' }}>
               <Pagination
                 count={totalPages}
                 page={currentPage}
                 onChange={handlePageChange}
                 color="primary"
                 style={{ marginTop: '20px' }}
               />
             </div>
           </>
         )}
       </div>
     );
   };
   
   export default Projects;
   
   
   const styles = {
     statusBarContainer: {
       display: 'flex',
       alignItems: 'center',
       position: 'relative',
     },
     statusBarWrapper: {
       position: 'relative',
       width: '100%',
       backgroundColor: '#f0f0f0',
       borderRadius: '4px',
       height: '5px',
       marginBottom: '5px',
       cursor: 'pointer',
     },
     statusBar: (completion_percentage) => ({
       width: `${completion_percentage}%`,
       backgroundColor:
         completion_percentage >= 75
           ? '#4BC0C0'  // Green
           : completion_percentage >= 50
           ? '#ffce9e'  // Yellow
           : '#FF6384', // Red
       height: '100%',
       borderRadius: '4px',
     }),
   
     tooltip: {
       visibility: 'hidden',
       opacity: 0,
       backgroundColor: '#999',
       color: '#fff',
       textAlign: 'center',
       padding: '4px',
       borderRadius: '4px',
       position: 'absolute',
       top: '-25px',
       left: '50%',
       transform: 'translateX(-50%)',
       transition: 'opacity 0.3s ease',
       fontSize: '12px',
       whiteSpace: 'nowrap',
     },
     tooltipVisible: {
       visibility: 'visible',
       opacity: 1,
     },
   };
   
   
   
 
 
 




