import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; 
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import './Holiday.css';
import { Modal, Button, TextField, MenuItem } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import AddHoliday from '../Components/AddHoliday';  // Adjust the path based on where your AddHoliday component is located





function HolidayCalendar() {
  const [holidays, setHolidays] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const calendarRef = useRef(null);
  const [showAddHoliday, setShowAddHoliday] = useState(false);  // Add this state


  const navigate = useNavigate(); // Initialize navigate


  // State for the add holiday form
  const [newHoliday, setNewHoliday] = useState({
    date: '',
    occasion: '',
    department: '',
    designation: '',
    employmentType: '',
  });




  // Departments, designations, etc. (example data)
  const departments = ['HR', 'Sales', 'Engineering'];
  const designations = ['Manager', 'Engineer', 'Staff'];
  const employmentTypes = ['Full-Time', 'Part-Time', 'Contract'];




  // Function to fetch holidays from the API
  const fetchHolidays = async (month) => {
    const token = localStorage.getItem('authToken');

    if (!token) {
      setError('No auth token found. Please log in.');
      setLoading(false);
      return;
    }




    const apiUrl = `https://api.myntask.com/holiday/list?month=${month}`;
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });




      if (!response.ok) {
        throw new Error(`Failed to fetch holidays, status code: ${response.status}`);
      }




      const data = await response.json();
      const formattedHolidays = data.map(holiday => {
        const dateParts = holiday.date.split('-');
        const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
        return {
          title: holiday.occassion,
          date: formattedDate,
          allDay: true,
        };
      });




      setHolidays(formattedHolidays);
      setError(null);
    } catch (error) {
      setError('Failed to load holiday data.');
    } finally {
      setLoading(false);
    }
  };




  useEffect(() => {
    const currentMonth = new Date().getMonth() + 1;
    fetchHolidays(currentMonth);
  }, []);




  const handleMonthChange = (arg) => {
    const newMonth = arg.view.currentStart.getMonth() + 1;
    fetchHolidays(newMonth);
  };




  // Handle opening and closing modal
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);




  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewHoliday(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };




  const handleSaveHoliday = async () => {
    const token = localStorage.getItem('authToken');

    if (!token) {
      alert('No auth token found.');
      return;
    }




    const apiUrl = 'https://api.myntask.com/holiday/add'; // Assuming this is the correct API URL
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newHoliday),
      });




      if (response.ok) {
        console.log('Holiday saved successfully!');
        fetchHolidays(new Date().getMonth() + 1); // Refresh the calendar
        handleCloseModal();
      } else {
        console.error('Failed to save holiday');
      }
    } catch (error) {
      console.error('Error saving holiday:', error);
    }
  };




  const handleShowAddHoliday = () => {
    navigate('/add-holiday'); // Navigate to AddHoliday component
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }


  return (
    <div className="holiday-calendar-container">
      <ToastContainer />
      <div className="top-controls">
        <h1 className='title'>Holiday Calendar
          <div className="underline"></div>
        </h1>
     {/* Button to show AddHoliday component */}
     <div className="buttons-row">
          <button className="color-button" onClick={handleShowAddHoliday}>
            Add Holiday
          </button>
        </div>

        {/* Conditionally render AddHoliday component */}
        {showAddHoliday && <AddHoliday />}

      </div>
  
      {/* Calendar */}
      <div className="holiday">
      <FullCalendar
  plugins={[dayGridPlugin, interactionPlugin]}
  initialView="dayGridMonth"
  events={holidays}
  eventColor="#3777FF"
  ref={calendarRef}
  height="parent" // Set to 'parent' so it respects the parent container height
  contentHeight="auto" // Ensures the height is based on the container
  expandRows={true}
  headerToolbar={{
    left: 'title',
    right: 'prev,next today',
  }}
  datesSet={handleMonthChange}
  className="custom-calendar" // Adds the custom class for styling
/>



      </div>
  
   
    </div>
  );
  
}




export default HolidayCalendar;




