import React, { useEffect, useState } from 'react';
import './../Components/Form.css';
import { useNavigate } from 'react-router-dom';




const AddClient = () => {
  const [formData, setFormData] = useState({
    salutation: "",
    client_name: "",
    email: "",
    country_id: "",
    mobile: "",
    gender: "",
    language: "",
    client_category: "",
    client_sub_category: "",
    login_allowed: "enable",
    receive_email_notification: 1,
    company_name: "",
    official_website: "",
    tax_name: "",
    gst_number: "",
    office_phone_number: "",
    city: "",
    state: "",
    postal_code: "",
    company_address: "",
    shipping_address: "",
    note: "",
    electronic_address: "",
    electronic_address_scheme: "",
    phonecode: "", // Added phonecode field
  });




  const [countries, setCountries] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const navigate = useNavigate();




  useEffect(() => {
    // Fetch country list from the API
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://api.myntask.com/home/country_list");
        if (!response.ok) {
          throw new Error("Failed to fetch country list");
        }
        const data = await response.json();
        setCountries(data); // Assuming the API returns an array of countries




        // Assuming each country object has a `phonecode` property
        const codes = data.map(country => ({ id: country.id, code: country.phonecode, name: country.name }));
        setCountryCodes(codes);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };




    // Fetch client categories from the API
    const fetchCategories = async () => {
      try {
        const token = localStorage.getItem("authToken"); // Get the token from local storage
        const response = await fetch("https://api.myntask.com/client/category", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch client categories");
        }
        const data = await response.json();
        setCategories(data); // Assuming the API returns an array of categories
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };




    fetchCountries();
    fetchCategories();
  }, []);




  const fetchSubCategories = async (categoryId) => {
    try {
      const token = localStorage.getItem("authToken"); // Get the token from local storage
      const response = await fetch(`https://api.myntask.com/client/sub_category/${categoryId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch client sub-categories");
      }
      const data = await response.json();
      setSubCategories(data); // Assuming the API returns an array of sub-categories
    } catch (error) {
      console.error("Error fetching sub-categories:", error);
    }
  };




  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };




  const handleCountryChange = (e) => {
    const countryId = e.target.value;
    setFormData((prevState) => ({ ...prevState, country_id: countryId }));




    // Find the corresponding phone code and set it
    const selectedCountry = countryCodes.find(country => country.id === parseInt(countryId, 10));
    if (selectedCountry) {
      setFormData((prevState) => ({
        ...prevState,
        phonecode: selectedCountry.code,
      }));
    }
  };




  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: parseInt(value)
    }));
  };




  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };




  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    setFormData((prevState) => ({ ...prevState, client_category: categoryId }));
    fetchSubCategories(categoryId); // Fetch sub-categories based on selected category
  };




  const handleSubCategoryChange = (e) => {
    const subCategoryId = e.target.value;
    setFormData((prevState) => ({ ...prevState, client_sub_category: subCategoryId }));
  };




  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("authToken"); // Get the token from local storage
      console.log('data is ',formData);
      const response = await fetch("https://api.myntask.com/client/save", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Set the Authorization header
        },
        body: JSON.stringify(formData),
      });




      if (!response.ok) {
        throw new Error("Failed to save client");
      }




      const result = await response.json();
      console.log("Client saved successfully", result);
      // Optionally, reset the form or handle the result as needed
      navigate("/clients");
   
    } catch (error) {
      console.error("Error:", error);
    }
  };




  return (
    <div className="main-container">
    <section className="section">
  <h3 className="sectionTitle">Account Details</h3>


  <div className="formRow">
    <div className="formColumn">
      <div className="formGroup">
        <label className="label">Salutation:</label>
        <input
          className="input"
          type="text"
          name="salutation"
          value={formData.salutation}
          onChange={handleChange}
          placeholder="Enter salutation" // Added placeholder
        />
      </div>
      <div className="formGroup">
        <label className="label">Client Name:</label>
        <input
          className="input"
          type="text"
          name="client_name"
          value={formData.client_name}
          onChange={handleChange}
          placeholder="Enter client name" // Added placeholder
        />
      </div>
      <div className="formGroup">
        <label className="label">Email:</label>
        <input
          className="input"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Enter email address" // Added placeholder
        />
      </div>
      <div className="formGroup">
        <label className="label">Country:</label>
        <select
          className="input select-box" // Add 'select-box' class to control dropdown width
          name="country_id"
          value={formData.country_id}
          onChange={handleCountryChange}
        >
          <option value="">Select Country</option> {/* Placeholder for select */}
          {countries.map((country) => (
            <option key={country.id} value={country.id}>
              {country.name}
            </option>
          ))}
        </select>
      </div>


      <div className="formGroup">
        <label className="label">Phone Code:</label>
        <input
          className="input"
          type="text"
          name="phonecode"
          value={formData.phonecode}
          onChange={handleChange}
          placeholder="Auto-filled" // Added placeholder (since it's read-only)
          readOnly
        />
      </div>
      <div className="formGroup">
        <label className="label">Mobile:</label>
        <input
          className="input"
          type="text"
          name="mobile"
          value={formData.mobile}
          onChange={handleChange}
          placeholder="Enter mobile number" // Added placeholder
        />
      </div>
    </div>


    <div className="formColumn">
      <div className="formGroup">
        <label className="label">Gender:</label>
        <select
          className="input"
          name="gender"
          value={formData.gender}
          onChange={handleChange}
        >
          <option value="">Select Gender</option> {/* Placeholder for select */}
          <option value="female">Female</option>
          <option value="male">Male</option>
          <option value="others">Others</option>
        </select>
      </div>
      <div className="formGroup">
        <label className="label">Language:</label>
        <input
          className="input"
          type="text"
          name="language"
          value={formData.language}
          onChange={handleChange}
          placeholder="Enter preferred language" // Added placeholder
        />
      </div>
      <div className="formGroup">
        <label className="label">Client Category:</label>
        <select
          className="input"
          name="client_category"
          value={formData.client_category}
          onChange={handleCategoryChange}
        >
          <option value="">Select Category</option> {/* Placeholder for select */}
          {categories.map((category) => (
            <option key={category.category_id} value={category.category_id}>
              {category.category_name}
            </option>
          ))}
        </select>
      </div>
      <div className="formGroup">
        <label className="label">Client Sub-Category:</label>
        <select
          className="input"
          name="client_sub_category"
          value={formData.client_sub_category}
          onChange={handleSubCategoryChange}
        >
          <option value="">Select Sub-Category</option> {/* Placeholder for select */}
          {subCategories.map((subCategory) => (
            <option key={subCategory.sub_category_id} value={subCategory.sub_category_id}>
              {subCategory.category_name}
            </option>
          ))}
        </select>
      </div>


      <div className="formGroup">
        <label className="label">Login Allowed:</label>
        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', width: '60%' }}>
          <label>
            <input
              type="radio"
              name="login_allowed"
              value="Enable"
              checked={formData.login_allowed === 'Enable'}
              onChange={handleLoginChange}
            />
            Enable
          </label>
          <label>
            <input
              type="radio"
              name="login_allowed"
              value="Disable"
              checked={formData.login_allowed === 'Disable'}
              onChange={handleLoginChange}
            />
            Disable
          </label>
        </div>
      </div>
      <div className="formGroup">
        <label className="label">Receive Email Notification:</label>
        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', width: '60%' }}>
          <label>
            <input
              type="radio"
              name="receive_email_notification"
              value={0}
              checked={formData.receive_email_notification === 0}
              onChange={handleRadioChange}
            />
            Enable
          </label>
          <label>
            <input
              type="radio"
              name="receive_email_notification"
              value={1}
              checked={formData.receive_email_notification === 1}
              onChange={handleRadioChange}
            />
            Disable
          </label>
        </div>
      </div>
    </div>
  </div>
</section>




<section className="section">
  <h3 className="sectionTitle">Company Details</h3>


  <div className="formRow">
    <div className="formColumn">
      <div className="formGroup">
        <label className="label">Company Name:</label>
        <input
          type="text"
          name="company_name"
          value={formData.company_name}
          onChange={handleChange}
          className="input"
          placeholder="Enter company name"
        />
      </div>
      <div className="formGroup">
        <label className="label">Official Website:</label>
        <input
          type="text"
          name="official_website"
          value={formData.official_website}
          onChange={handleChange}
          className="input"
          placeholder="Enter official website"
        />
      </div>
      <div className="formGroup">
        <label className="label">Tax Name:</label>
        <input
          type="text"
          name="tax_name"
          value={formData.tax_name}
          onChange={handleChange}
          className="input"
          placeholder="Enter tax name"
        />
      </div>
      <div className="formGroup">
        <label className="label">GST Number:</label>
        <input
          type="text"
          name="gst_number"
          value={formData.gst_number}
          onChange={handleChange}
          className="input"
          placeholder="Enter GST number"
        />
      </div>


      <div className="formGroup">
        <label className="label">Office Phone Number:</label>
        <input
          type="text"
          name="office_phone_number"
          value={formData.office_phone_number}
          onChange={handleChange}
          className="input"
          placeholder="Enter office phone number"
        />
      </div>
      <div className="formGroup">
        <label className="label">City:</label>
        <input
          type="text"
          name="city"
          value={formData.city}
          onChange={handleChange}
          className="input"
          placeholder="Enter city"
        />
      </div>
      <div className="formGroup">
        <label className="label">State:</label>
        <input
          type="text"
          name="state"
          value={formData.state}
          onChange={handleChange}
          className="input"
          placeholder="Enter state"
        />
      </div>
    </div>


    <div className="formColumn">
      <div className="formGroup">
        <label className="label">Postal Code:</label>
        <input
          type="text"
          name="postal_code"
          value={formData.postal_code}
          onChange={handleChange}
          className="input"
          placeholder="Enter postal code"
        />
      </div>


      <div className="formGroup">
        <label className="label">Company Address:</label>
        <input
          type="text"
          name="company_address"
          value={formData.company_address}
          onChange={handleChange}
          className="input"
          placeholder="Enter company address"
        />
      </div>
      <div className="formGroup">
        <label className="label">Shipping Address:</label>
        <input
          type="text"
          name="shipping_address"
          value={formData.shipping_address}
          onChange={handleChange}
          className="input"
          placeholder="Enter shipping address"
        />
      </div>
      <div className="formGroup">
        <label className="label">Electronic Address Scheme:</label>
        <input
          type="text"
          name="electronic_address_scheme"
          value={formData.electronic_address_scheme}
          onChange={handleChange}
          className="input"
          placeholder="Enter electronic address scheme"
        />
      </div>


      <div className="formGroup">
        <label className="label">Electronic Address:</label>
        <input
          type="text"
          name="electronic_address"
          value={formData.electronic_address}
          onChange={handleChange}
          className="input"
          placeholder="Enter electronic address"
        />
      </div>
      <div className="formGroup">
        <label className="label">Note:</label>
        <textarea
          name="note"
          value={formData.note}
          onChange={handleChange}
          className="input"
          placeholder="Enter any notes"
        />
      </div>
    </div>
  </div>
</section>


         
        <button className="color-button" onClick={handleSubmit}>
            Save Client
          </button>
       
    </div>
  );
};




export default AddClient;








