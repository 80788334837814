import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';



const Asset = () => {
  const [assets, setAssets] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();


  useEffect(() => {
    const token = localStorage.getItem("authToken");
    fetch('https://api.myntask.com/assets/list', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          setAssets(data);
        } else {
          console.error('Expected array but received:', data);
          setAssets([]); // Set as empty array to avoid issues
        }
      })
      .catch(error => console.error('Error fetching assets:', error));
  }, []);


  const handleAssetClick = (id) => {
    navigate(`/assetDetail/${id}`);
  };


  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };


  const filteredAssets = assets.filter(asset => {
    const query = searchQuery.toLowerCase();
    return (
      asset.asset_name.toLowerCase().includes(query) ||
      asset.assets_status.toLowerCase().includes(query) ||
      (asset.lent_to && asset.lent_to.user_name.toLowerCase().includes(query)) ||
      (asset.date && asset.date.toLowerCase().includes(query))
    );
  });


  const containerStyle = {
    width: '95%',
    margin: '20px auto',
    textAlign: 'center',
  };


  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '20px',
  };


  const searchBarStyle = {
    padding: '10px',
    fontSize: '16px',
    width: '100%',
    maxWidth: '400px',
    margin: '0 auto 20px auto',
    display: 'block',
    borderRadius: '5px',
    border: '1px solid #ccc',
  };


  return (
    <div style={containerStyle}>
      <h1>Asset</h1>
      <input
        type="text"
        placeholder="Search assets..."
        value={searchQuery}
        onChange={handleSearchChange}
        style={searchBarStyle}
      />
      <table>
        <thead>
          <tr>
            <th>Image</th>
            <th>Asset Name</th>
            <th>Status</th>
            <th>Lent To</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {filteredAssets.length > 0 ? (
            filteredAssets.map((asset) => (
              <tr key={asset.assets_id}>
                <td>
                  <img src={asset.image || 'default_image.jpg'} alt={asset.asset_name} width="50" />
                </td>
                <td
                 
                  onClick={() => handleAssetClick(asset.assets_id)}
                >
                  {asset.asset_name}
                </td>
                <td>{asset.assets_status}</td>
                <td>
                  {asset.assets_status === 'lent' ? (
                    <div className="lent-to-container">
                      <img
                        src={asset.lent_to.user_image || 'default_user.jpg'}
                        alt={asset.lent_to.user_name}
                        className="user-image"
                      />
                      <div className="user-info">
                        <p className="user-name">{asset.lent_to.user_name}</p>
                        <p className="user-designation">{asset.lent_to.designation}</p>
                      </div>
                    </div>
                  ) : 'N/A'}
                </td>
                <td>{asset.date || 'N/A'}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No assets available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};


export default Asset;






