import React, { useState, useEffect } from 'react';
import './EmailSettings.css'; // Import the CSS file

const EmailSettings = () => {
  const [emailSettings, setEmailSettings] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('http://api.myntask.com/setting/email_setting')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Fetched Email Settings:', data); // Log the response data
        setEmailSettings(data);
        setLoading(false);
      })
      .catch(err => {
        console.error('Error fetching email settings:', err); // Log any errors
        setError(err);
        setLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEmailSettings((prevSettings) => ({
      ...prevSettings,
      [name]: type === 'checkbox' ? (checked ? 1 : 0) : value,
    }));
  };

  const handleSave = () => {
    console.log('Sending data to API:', emailSettings); // Log the payload for debugging
    fetch('http://api.myntask.com/setting/email_setting_update', { // Ensure this URL is correct
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(emailSettings),
    })
      .then(response => {
        if (!response.ok) {
          return response.text().then(text => { // Read the response text
            throw new Error(text || 'Network response was not ok');
          });
        }
        return response.json();
      })
      .then(data => {
        console.log('Successfully updated email settings:', data);
        alert('Email settings updated successfully!');
      })
      .catch(err => {
        console.error('Error updating email settings:', err);
        alert('Error updating email settings.');
      });
  };

  // Determine if SMTP or MAIL should be checked
  const isSmtpChecked = emailSettings.mail_driver === 'smtp';
  const isMailChecked = emailSettings.mail_driver === 'mail';

  // Filter out fields that should not be displayed
  const filteredSettings = Object.entries(emailSettings).filter(
    ([key]) => !['id', 'created_at', 'updated_at'].includes(key)
  );

  if (loading) return <p className="loading">Loading...</p>;
  if (error) return <p className="error">Error: {error.message}</p>;

  return (
    <div className="email-settings-container">
      <h2 className="title">Email Settings</h2>
      <form className="email-settings-form">
        <div className="form-group">
          <label>
            <input
              type="checkbox"
              checked={isSmtpChecked}
              onChange={() => setEmailSettings(prev => ({ ...prev, mail_driver: 'smtp' }))}
              className="form-check-input"
            />
            SMTP
          </label>
        </div>
        <div className="form-group">
          <label>
            <input
              type="checkbox"
              checked={isMailChecked}
              onChange={() => setEmailSettings(prev => ({ ...prev, mail_driver: 'mail' }))}
              className="form-check-input"
            />
            MAIL
          </label>
        </div>
        {filteredSettings.map(([key, value]) => (
          <div className="form-group" key={key}>
            <label htmlFor={key}>{formatLabel(key)}:</label>
            {key === 'email_verified' ? (
              <input
                type="checkbox"
                id={key}
                name={key}
                checked={value === 1}
                onChange={handleChange}
                className="form-check-input"
              />
            ) : (
              <input
                type={key === 'mail_password' ? 'password' : 'text'} // Adjust for password field
                id={key}
                name={key}
                value={value || ''}
                onChange={handleChange}
                className="form-control"
              />
            )}
          </div>
        ))}
        <button type="button" onClick={handleSave} className="save-button">Save</button>
      </form>
    </div>
  );
};

const formatLabel = (key) => {
  return key
    .replace(/_/g, ' ')
    .replace(/\b\w/g, char => char.toUpperCase());
};

export default EmailSettings;
