   
    import React, { useState, useEffect } from 'react';
    import DatePicker from 'react-datepicker';
    import 'react-datepicker/dist/react-datepicker.css';
    import { ToastContainer, toast } from 'react-toastify'; // Import toast notification
    import 'react-toastify/dist/ReactToastify.css';
    
    
    
    
    const AttendanceMark = () => {
     const [departments, setDepartments] = useState([]);
     const [selectedDepartment, setSelectedDepartment] = useState('');
     const [employees, setEmployees] = useState([]);
     const [selectedEmployee, setSelectedEmployee] = useState(null); // Single selected employee
     const [showDropdown, setShowDropdown] = useState(false); // To toggle the employee dropdown
     const [locations, setLocations] = useState([]); // State for locations
     const [location, setLocation] = useState(''); // For selected location
     const [startDate, setStartDate] = useState(new Date());
     const [endDate, setEndDate] = useState(new Date());
     const [clockIn, setClockIn] = useState('10:30');
     const [clockOut, setClockOut] = useState('08:00');
     const [late, setLate] = useState('no');
     const [halfDay, setHalfDay] = useState('no');
     const [workingFrom, setWorkingFrom] = useState('Office');
     const [overwrite, setOverwrite] = useState(false);
    
    
    
    
     const token = localStorage.getItem('authToken'); // Get the token from localStorage
    
    
    
    
     // Fetch departments from the API
     useEffect(() => {
       const fetchDepartments = async () => {
         try {
           const response = await fetch('https://api.myntask.com/employee/department', {
             headers: {
               Authorization: `Bearer ${token}`,
               'Content-Type': 'application/json',
             },
           });
    
    
    
    
           if (!response.ok) {
             throw new Error('Failed to fetch department data');
           }
    
    
    
    
           const data = await response.json();
           setDepartments(data); // Set departments from the API response
         } catch (error) {
           console.error('Error fetching departments:', error);
         }
       };
    
    
    
    
       fetchDepartments();
     }, [token]);
    
    
    
    
     // Fetch locations from the API
     useEffect(() => {
       const fetchLocations = async () => {
         try {
           const response = await fetch('https://api.myntask.com/attendance/locations', {
             headers: {
               Authorization: `Bearer ${token}`,
               'Content-Type': 'application/json',
             },
           });
    
    
    
    
           if (!response.ok) {
             throw new Error('Failed to fetch location data');
           }
    
    
    
    
           const data = await response.json();
           setLocations(data); // Set locations from the API response
         } catch (error) {
           console.error('Error fetching locations:', error);
         }
       };
    
    
    
    
       fetchLocations();
     }, [token]);
    
    
    
    
     // Fetch employees from the API based on the selected department
     useEffect(() => {
       if (selectedDepartment) {
         const fetchEmployees = async () => {
           try {
             const response = await fetch(
               `https://api.myntask.com/employee/member?department_id=${selectedDepartment}`,
               {
                 headers: {
                   Authorization: `Bearer ${token}`,
                   'Content-Type': 'application/json',
                 },
               }
             );
    
    
    
    
             if (!response.ok) {
               throw new Error('Failed to fetch employee data');
             }
    
    
    
    
             const data = await response.json();
             setEmployees(data.map((emp) => ({
               label: emp.member_name,
               value: emp.id,
               image: emp.member_image,
             })));
           } catch (error) {
             console.error('Error fetching employees:', error);
           }
         };
    
    
    
    
         fetchEmployees();
       }
     }, [selectedDepartment, token]);
    
    
    
    
     // Toggle employee dropdown
     const toggleDropdown = () => {
       setShowDropdown(!showDropdown);
     };
    
    
    
    
     // Handle single employee selection
     const handleEmployeeSelect = (id) => {
       const selected = employees.find((emp) => emp.value === id);
       setSelectedEmployee(selected); // Set only one employee
       setShowDropdown(false); // Close dropdown after selecting an employee
     };
    
    
    
    
     // Fresh handleSubmit function
    // Handle form submission and send data to the API
    const handleSubmit = async (e) => {
     e.preventDefault();
    
    
    
    
     // Ensure the dates are properly formatted
     const formattedStartDate = startDate ? startDate.toISOString().split('T')[0] : null;
     const formattedEndDate = endDate ? endDate.toISOString().split('T')[0] : null;
    
    
    
    
     if (!formattedStartDate || !formattedEndDate) {
       toast.error('Please select valid start and end dates.');
       return;
     }
    
    
    
    
     // Prepare the data for the API request
     const payload = {
       user_id: selectedEmployee?.value,
       location_id: location,
       clock_in_time: clockIn ? `${clockIn}:00` : null, // Ensure time is in HH:MM:SS format
       clock_out_time: clockOut ? `${clockOut}:00` : null, // Ensure time is in HH:MM:SS format
       late,
       half_day: halfDay,
       working_from: workingFrom.toLowerCase(),
       from: formattedStartDate,  // Send properly formatted start date
       to: formattedEndDate,      // Send properly formatted end date
       attendance_overwrite: overwrite ? 'yes' : 'no',
     };
    
    
    
    
     try {
       const response = await fetch('https://api.myntask.com/attendance/mark_attendance', {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
           Authorization: `Bearer ${token}`,
         },
         body: JSON.stringify(payload),
       });
    
    
    
    
       // Handle response based on status
       const contentType = response.headers.get('content-type');
       let responseData;
    
    
    
    
       if (response.ok) {
         if (contentType && contentType.includes('application/json')) {
           responseData = await response.json();  // Handle JSON response
         } else {
           responseData = await response.text();  // Handle text response
         }
    
    
    
    
         console.log('Attendance marked successfully:', responseData);
         toast.success('Attendance marked successfully!');
     
       } else {
         // Handle error case but don't show error toast
         responseData = await response.text();  // If there's an error, still read the response
         console.error('Error marking attendance:', responseData);
       }
     } catch (error) {
       console.error('Error marking attendance:', error);
     }
    };
    
    
    // const handleCancel = () => {
    //   navigate('/attendance'); // Replace '/attendance' with your actual route
    // };
    
    
     return (
       <div className="main-container">
        <ToastContainer />
        <div className="top-controls">
          <h1 className='title'>Mark Attendance
            <div className="underline"></div>
          </h1>
        </div>
    
    
    
    
           <div style={{width:'100%'}}>
             {/* Department Dropdown */}
             <div className="formRow">
             <div className="formColumn">
             <div className="formGroup">
               <label className="label">Department</label>
               <select
                 value={selectedDepartment}
                 onChange={(e) => setSelectedDepartment(e.target.value)}
                 className="input"
               >
                 <option value="">-- Select Department --</option>
                 {departments.map((department) => (
                   <option key={department.id} value={department.id}>
                     {department.department}
                   </option>
                 ))}
               </select>
             </div>
    
    
    
    
             {/* Employee Selection */}
             <div className="formGroup">
               <label className="label">Employees *</label>
               <div className="input" style={{padding:'0px',border:'none'}}>
                 <input
                   type="text"
                   placeholder="Select Employee"
                   onClick={toggleDropdown}
                   value={selectedEmployee ? selectedEmployee.label : ''}
                   readOnly
                  className="input"
                  style={{width:'100%'}}
                 />
                 {showDropdown && (
                   <div className="employee-list">
                     {employees.map((employee) => (
                       <div
                         key={employee.value}
                         className="employee-item"
                         onClick={() => handleEmployeeSelect(employee.value)}
                       >
                         <img src={employee.image} alt={employee.label} className="employee-image" />
                         <span>{employee.label}</span>
                       </div>
                     ))}
                   </div>
                 )}
               </div>
             </div>
    
    
    
    
             {/* Location Dropdown */}
             <div className="formGroup">
               <label className="label">Location</label>
               <select value={location} onChange={(e) => setLocation(e.target.value)}
                className="input">
                 <option value="">-- Select Location --</option>
                 {locations.map((loc) => (
                   <option key={loc.id} value={loc.id}>
                     {loc.location}
                   </option>
                 ))}
               </select>
             </div>
    
    
    
    
             {/* Date and Time Selection */}
         
             <div className="formGroup">
               <label className="label">Clock In *</label>
               <input
                 type="text"
                 value={clockIn}
                 onChange={(e) => setClockIn(e.target.value)}
                 required
                 className="input"
               />
             </div>
    
    
             <div className="formGroup">
               <label className="label">Clock Out</label>
               <input
                 type="text"
                 value={clockOut}
                 onChange={(e) => setClockOut(e.target.value)}
                 className="input"
               />
             </div>
    
    
             </div>
    
    
            <div className="formColumn">
    
    
            <div className="formGroup">
               <label className="label">Working From *</label>
               <select className="input" value={workingFrom} onChange={(e) => setWorkingFrom(e.target.value)} required>
                 <option value="Office">Office</option>
                 <option value="Home">Home</option>
               </select>
             </div>
    
    
             <div className="formGroup">
               <label className="label">Mark Attendance By</label>
             
                 <DatePicker
                   selected={startDate}
                   onChange={(dates) => {
                     const [start, end] = dates;
                     setStartDate(start);
                     setEndDate(end);
    
    
                   }}
                   style={{width:'100%'}}
                   className="input"
                   startDate={startDate}
                   endDate={endDate}
                   selectsRange
                   inline={false}
                 />
               
             
             </div>
    
    
             {/* Late and Half Day options */}
             <div className="formGroup">
               <label className="label">Late</label>
              <div style={{flexDirection:'row',display:'flex',justifyContent:'space-between',width:'60%'}}>
                <div>
                 <input
                   type="radio"
                   name="late"
                   value="yes"
                   checked={late === 'yes'}
                   onChange={() => setLate('yes')}
                 />
                 <label className="label">Yes</label>
                 </div>
                 <div>
                 <input
                   type="radio"
                   name="late"
                   value="no"
                   checked={late === 'no'}
                   onChange={() => setLate('no')}
                 />
                 <label>No</label>
                 </div>
               </div>
             </div>
    
    
    
    
             <div className="formGroup" >
             
               <label className="label">Half Day</label>
               <div style={{flexDirection:'row',display:'flex',justifyContent:'space-between',width:'60%'}}>
                <div>
                 <input
                   type="radio"
                   name="halfDay"
                   value="yes"
                   checked={halfDay === 'yes'}
                   onChange={() => setHalfDay('yes')}
                 />
                 <label className="label">Yes</label>
                 </div>
                 <div>
                 <input
                   type="radio"
                   name="halfDay"
                   value="no"
                   checked={halfDay === 'no'}
                   onChange={() => setHalfDay('no')}
                 />
                 <label>No</label>
                 </div>
               </div>
             </div>
    
    
             <div className="formGroup" >
             <label className="label">Attendance Overwrite</label>
               <input
                 type="checkbox"
                 checked={overwrite}
                 onChange={() => setOverwrite(!overwrite)}
                 className="checkboxInput"
               />
               
             </div>
            </div>
            ,</div>
    
    
            
             <div className="buttons-row" style={{marginTop:'15px'}}>
               <button onClick={handleSubmit} className="color-button">Save</button>
               <button className="color-button" >Cancel</button>
             </div>
             
           </div>
           <ToastContainer />
       
       </div>
     );
    };
    
    
    
    
    export default AttendanceMark
    
    
       
      
    