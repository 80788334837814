import React, { useEffect, useState } from 'react';
import './AddLeadPage.css';

const AddLeadPage = () => {
  const [newLead, setNewLead] = useState({
    name: '',
    email: '',
    source_id: '',
    company_name: '',
    website: '',
    mobile: '',
    office_phone_number: '',
    country: '',
    state: '',
    city: '',
    postal_code: '',
    address: '',
  });

  const [countries, setCountries] = useState([]);
  const [sources, setSources] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewLead((prevLead) => ({ ...prevLead, [name]: value }));
  };

  const handleSaveLead = async () => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        throw new Error('No token found');
      }

      const response = await fetch('https://api.myntask.com/leads/add_lead', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newLead),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error('Failed to add lead');
      }

      const data = await response.json();
      alert('Lead added successfully!');
    } catch (err) {
      alert('Failed to add lead.');
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://api.myntask.com/home/country_list");
        if (!response.ok) {
          throw new Error("Failed to fetch country list");
        }
        const data = await response.json();
        setCountries(data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    const fetchSources = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await fetch("https://api.myntask.com/leads/lead_source", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error("Failed to fetch Sources");
        }
        const data = await response.json();
        setSources(data);
      } catch (error) {
        console.error("Error fetching Sources:", error);
      }
    };

    fetchCountries();
    fetchSources();
  }, []);

  return (
    <div className="add-lead-form-container">
      <h1 className="add-lead-header">Add Lead</h1>
      <div className="add-lead-form-group">
        <div className="form-item">
          <label className="add-lead-label">Name:</label>
          <input type="text" name="name" value={newLead.name} onChange={handleInputChange} className="add-lead-input" />
        </div>
        <div className="form-item">
          <label className="add-lead-label">Email:</label>
          <input type="email" name="email" value={newLead.email} onChange={handleInputChange} className="add-lead-input" />
        </div>
        <div className="form-item">
          <label className="add-lead-label">Source ID:</label>
          <select name="source_id" value={newLead.source_id} onChange={handleInputChange} className="add-lead-input">
            <option value="">Select source</option>
            {sources.map(source => (
              <option key={source.id} value={source.id}>{source.type}</option>
            ))}
          </select>
        </div>
        <div className="form-item">
          <label className="add-lead-label">Company Name:</label>
          <input type="text" name="company_name" value={newLead.company_name} onChange={handleInputChange} className="add-lead-input" />
        </div>
        <div className="form-item">
          <label className="add-lead-label">Website:</label>
          <input type="text" name="website" value={newLead.website} onChange={handleInputChange} className="add-lead-input" />
        </div>
        <div className="form-item">
          <label className="add-lead-label">Mobile:</label>
          <input type="text" name="mobile" value={newLead.mobile} onChange={handleInputChange} className="add-lead-input" />
        </div>
        <div className="form-item">
          <label className="add-lead-label">Office Phone Number:</label>
          <input type="text" name="office_phone_number" value={newLead.office_phone_number} onChange={handleInputChange} className="add-lead-input" />
        </div>
        <div className="form-item">
          <label className="add-lead-label">Country:</label>
          <select name="country" value={newLead.country} onChange={handleInputChange} className="add-lead-input">
            <option value="">Select Country</option>
            {countries.map(country => (
              <option key={country.id} value={country.id}>{country.name}</option>
            ))}
          </select>
        </div>
        <div className="form-item">
          <label className="add-lead-label">State:</label>
          <input type="text" name="state" value={newLead.state} onChange={handleInputChange} className="add-lead-input" />
        </div>
        <div className="form-item">
          <label className="add-lead-label">City:</label>
          <input type="text" name="city" value={newLead.city} onChange={handleInputChange} className="add-lead-input" />
        </div>
        <div className="form-item">
          <label className="add-lead-label">Postal Code:</label>
          <input type="text" name="postal_code" value={newLead.postal_code} onChange={handleInputChange} className="add-lead-input" />
        </div>
        <div className="form-item">
          <label className="add-lead-label">Address:</label>
          <input type="text" name="address" value={newLead.address} onChange={handleInputChange} className="add-lead-input" />
        </div>
        <div className="add-lead-button-group">
          <button onClick={handleSaveLead} className="add-lead-save-button">Save Lead</button>
          <button onClick={() => window.history.back()} className="add-lead-cancel-button">Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default AddLeadPage;
