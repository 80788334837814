import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './../Components/Main.css';




const LeadContact = () => {
  const [leads, setLeads] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();




  useEffect(() => {
    const fetchLeads = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No token found');
        return;
      }




      try {
        const response = await fetch('https://api.myntask.com/leads/list', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });




        if (!response.ok) {
          throw new Error('Failed to fetch leads');
        }




        const data = await response.json();
        setLeads(data);
      } catch (err) {
        console.error('Error fetching leads:', err);
        setError('Failed to load leads.');
      }
    };




    fetchLeads();
  }, []);




  const handleClientNameClick = (leadId) => {
    navigate(`/leadProfile/${leadId}`);
  };




  const handleAddLeadClick = () => {
    navigate('/addLead'); // Navigate to Add Lead page
  };




  return (
    <div style={{padding: '15px 30px'}}>
        <div className="top-controls">
        <h1 className='title'>Lead Contact
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
         
     
            <button className="color-button"  onClick={handleAddLeadClick}>
              Add client
            </button>
     
        </div>
      </div>
     
   
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {leads.length > 0 ? (
        <table >
          <thead>
            <tr>
             
              <th>Company Name</th>
              <th>Client Name</th>
              <th>Client Email</th>
              <th>Contact Name</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {leads.map((lead) => (
              <tr key={lead.id}>
               
                <td >{lead.company_name || 'N/A'}</td>
                <td
                  style={{color: 'blue', cursor: 'pointer' }}
                  onClick={() => handleClientNameClick(lead.id)}
                >
                  {lead.client_name}
                </td>
                <td >{lead.client_email || 'N/A'}</td>
                <td >{lead.name}</td>
                <td >{lead.created_at}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No leads available</p>
      )}
    </div>
  );
};




export default LeadContact;






