import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  TextField,
  Typography,
} from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';


const TaskReport = () => {
  const [taskReports, setTaskReports] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const tasksPerPage = 10;


  const token = localStorage.getItem('authToken');


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.myntask.com/report/task', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        console.log('Response:', data);
        setTaskReports(data.task_list || []);
        setFilteredTasks(data.task_list || []);
      } catch (error) {
        console.error('Error fetching task report:', error);
      }
    };


    fetchData();
  }, [token]);


  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    const filtered = taskReports.filter(
      (task) =>
        (task.task?.toLowerCase().includes(event.target.value.toLowerCase()) ||
        task.code?.toLowerCase().includes(event.target.value.toLowerCase()))
    );
    setFilteredTasks(filtered);
  };


  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };


  const totalTasks = filteredTasks.length;
  const displayedTasks = filteredTasks.slice((currentPage - 1) * tasksPerPage, currentPage * tasksPerPage);


  const taskStatusCount = {
    Completed: taskReports.filter(task => task.status === 'Completed').length,
    Incomplete: taskReports.filter(task => task.status === 'Incomplete').length,
    ToDo: taskReports.filter(task => task.status === 'To Do').length,
  };


  const pieData = [
    { name: 'Completed', value: taskStatusCount.Completed },
    { name: 'Incomplete', value: taskStatusCount.Incomplete },
    { name: 'To Do', value: taskStatusCount.ToDo },
  ];


  const COLORS = ['#00C49F', '#FFBB28', '#FF8042'];


  return (
    <div className="main-container">
     <div className="top-controls">
        <h1 className='title'>Task Report
          <div className="underline"></div>
        </h1>
      </div>


      <Box sx={{ marginBottom: '20px', backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '8px' }}>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={pieData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              fill="#8884d8"
              label
            >
              {pieData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </Box>


     
       <div className="top-controls">
          <div className="filters-and-search">
           
            <input
              type="text"
              placeholder="Search by name or email..."
              value={searchTerm}
              onChange={handleSearch}
              className="searchbar"
            />
          </div>
        </div>
        <table>
  <thead>
    <tr>
      <th>Code</th>
      <th>Task</th>
      <th>Project</th>
      <th>Due Date</th>
      <th>Assigned To</th>
      <th>Status</th>
    </tr>
  </thead>
  <tbody>
    {displayedTasks.map((task) => (
      <tr key={task.task_id}>
        <td>{task.code || 'N/A'}</td>
        <td>{task.task || 'N/A'}</td>
        <td>{task.project || 'N/A'}</td>
        <td>{task.due_date || 'N/A'}</td>
        <td>
          {task.assigned_users && task.assigned_users.length > 0 ? (
            task.assigned_users.map((user) => (
              <img
                key={user.user_id}
                src={user.image}
                alt={user.user_name}
                style={{ width: 30, height: 30, borderRadius: '50%', marginRight: '8px' }}
              />
            ))
          ) : (
            'N/A'
          )}
        </td>
        <td>{task.status || 'N/A'}</td>
      </tr>
    ))}
  </tbody>
</table>




      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', paddingBottom: '20px' }}>
        <Pagination
          count={Math.ceil(totalTasks / tasksPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </div>
  );
};


export default TaskReport;





