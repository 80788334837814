import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import './css/Event.css'; // Add your custom CSS

function Event() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://api.myntask.com/event/list', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch events');
        }
        const data = await response.json();

        console.log('API Response:', data);

        const formattedEvents = data.map(event => {
          // Convert the start_date_time to a Date object
          const dateParts = event.start_date_time.split(' ');
          const month = dateParts[0];
          const day = parseInt(dateParts[1]); // removes "th", "st", etc.
          const year = parseInt(dateParts[2]);

          // Correct date construction
          const startDate = new Date(`${month} ${day}, ${year}`);

          console.log('Formatted Date:', startDate);

          return {
            id: event.id,
            title: event.event_name,
            start: startDate.toISOString().split('T')[0], // Format as YYYY-MM-DD
            allDay: true,
          };
        });

        console.log('Formatted Events:', formattedEvents);
        setEvents(formattedEvents);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, []);

  const handleDateClick = (info) => {
    alert(`Clicked on: ${info.dateStr}`);
  };

  return (
    <div className="event-container">
      <button className="add-event-btn">+ Add Event</button>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
        }}
        events={events}
        dateClick={handleDateClick}
        editable={true}
        selectable={true}
      />
    </div>
  );
}

export default Event;
