import React, { useEffect, useState } from 'react';
import './SuperAdminPackages.css'; // Import CSS file for styling


const SuperAdminPackages = () => {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const token = localStorage.getItem('token'); // Retrieve the token from localStorage
        const response = await fetch('https://api.myntask.com/package/list', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });


        if (!response.ok) {
          throw new Error('Failed to fetch packages');
        }


        const data = await response.json();
        setPackages(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };


    fetchPackages();
  }, []);


  if (loading) {
    return <div>Loading...</div>;
  }


  if (error) {
    return <div>Error: {error}</div>;
  }


  return (
    <div>
      <h1>Packages</h1>
      <div className="header">
          <button className="add-package-btn">Add Package</button>
        </div>
      <div className="card-container">
       
        <table className="packages-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Monthly Price</th>
              <th>Annual Price</th>
              <th>File Storage</th>
              <th>Max Employees</th>
              <th className="module-exists-column">Module Exists</th>
              <th>Module Not Exists</th>
            </tr>
          </thead>
          <tbody>
            {packages.map((pkg, index) => (
              <tr key={index}>
                <td>{pkg.name}</td>
                <td>{pkg.monthly_price}</td>
                <td>{pkg.annual_price}</td>
                <td>{pkg.file_storage}</td>
                <td>{pkg.max_employees}</td>
                <td className="module-exists-column">
                  <ul className="module-list module-exists">
                    {pkg.module_exists.map((module, idx) => (
                      <li key={idx}>{module}</li>
                    ))}
                  </ul>
                </td>
                <td>
                  <ul className="module-list module-not-exists">
                    {pkg.module_not_exists.map((module, id) => (
                      <li key={id}>{module}</li>
                    ))}
                  </ul>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};


export default SuperAdminPackages;




