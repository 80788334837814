import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function RolePermissionSetting() {
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  useEffect(() => {
    const fetchRoles = async () => {
      const token = localStorage.getItem('authToken'); // Get the token from local storage

      if (!token) {
        setError('No token found. Please log in.');
        return;
      }

      const apiUrl = 'https://api.myntask.com/rolepermission/roles'; // The API URL

      try {
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        setRoles(data); // Save the fetched data in the state
        console.log('Roles data:', data);
      } catch (error) {
        console.error('Error fetching roles:', error);
        setError(error.message);
      }
    };

    fetchRoles();
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  const handleButtonClick = (role) => {
    // Navigate to the EmployeeComponent, passing the role object which includes the role_id
    navigate(`/employeecomponent/${role.role_id}`, { state: { role } });
  };

  return (
    <div>
      <h1>Role & Permission Setting</h1>
      {error && <p>Error: {error}</p>}
      <div style={buttonContainerStyle}>
        {roles.length > 0 ? (
          roles.map((role) => (
            <button 
              key={role.role_id} 
              style={buttonStyle}
              onClick={() => handleButtonClick(role)} // Handle button click
            >
              {role.role_name} <span style={countStyle}>({role.member})</span>
            </button>
          ))
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
}

export default RolePermissionSetting;

const buttonContainerStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
  marginTop: '20px',
};

const buttonStyle = {
  padding: '10px 20px',
  backgroundColor: '#007bff',
  color: '#fff',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  fontSize: '16px',
};

const countStyle = {
  marginLeft: '10px',
  fontWeight: 'bold',
};
