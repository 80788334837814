import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { Button, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'; // Import necessary Material-UI components


const ParentComponent = () => {
    const [vendors, setVendors] = useState([]); // State to store fetched vendor data
    const navigate = useNavigate(); // Create navigate function


    // Function to fetch vendors data
    const fetchVendors = async () => {
        const token = localStorage.getItem('authToken'); // Get token from localStorage


        try {
            const response = await fetch('https://api.myntask.com/vendor/list', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });


            console.log('Response:', response); // Log the response for debugging


            if (!response.ok) {
                const errorText = await response.text();
                console.error('Response Error Text:', errorText);
                throw new Error('Network response was not ok');
            }


            const data = await response.json(); // Parse JSON response
            console.log('Fetched vendors:', data); // Log the fetched data for debugging


            // Check the structure of the data and set it correctly
            setVendors(data); // Adjust this based on the actual structure
        } catch (error) {
            console.error('Error fetching vendors:', error);
        }
    };


    // Fetch data on component mount
    useEffect(() => {
        fetchVendors();
    }, []);


    // Function to navigate to vendor detail screen
    const handleVendorClick = (vendorId) => {
        navigate(`/vendor-detail/${vendorId}`); // Navigate to vendor detail screen with the vendor ID
    };


    return (
<div className="main-container">
        <div className="top-controls">
          <h1 className='title'>Vendors
            <div className="underline"></div>
          </h1>
          <div className="buttons-row">
           
              <button className="color-button" onClick={() => navigate('/add-vendor')}>
      Add Vendor
              </button>
           
          </div>
        </div>
 


  {/* Vendors Table */}
  <div >
    <table >
      <thead>
        <tr>
          <th >Code</th>
          <th >Company</th>
          <th >Email</th>
          <th >City</th>
          <th >Phone</th>
        </tr>
      </thead>
      <tbody>
        {vendors.map((vendor) => (
          <tr key={vendor.id}>
            <td >{vendor.code || 'N/A'}</td>
            <td >
              <span
                style={{ color: '#1976d2', cursor: 'pointer' }}
                onClick={() => handleVendorClick(vendor.id)}
              >
                {vendor.company_name}
              </span>
            </td>
            <td >{vendor.email || 'N/A'}</td>
            <td >{vendor.city || 'N/A'}</td>
            <td >{vendor.phone || 'N/A'}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>


    );
};


export default ParentComponent;






