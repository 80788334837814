import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Typography,

} from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';




const EditProject = () => {
  const { id } = useParams(); // Extract the project ID from the URL
  const [projectData, setProjectData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [categories, setCategories] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [clients, setClients] = useState([]);
  const statuses = ['not started', 'in progress', 'completed', 'on hold']; // Example statuses




  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('authToken'); // Retrieve token from localStorage
      if (!token) {
        setError('No authentication token found.');
        setLoading(false);
        return;
      }


      try {
        // Fetch project data
        const projectResponse = await fetch(`https://api.myntask.com/project/edit/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, // Include token in the header
          },
        });


        if (!projectResponse.ok) {
          throw new Error(`Network response was not ok. Status: ${projectResponse.status}`);
        }


        const projectData = await projectResponse.json();
        setProjectData(projectData[0]); // Assuming data is an array with one object


        // Fetch categories
        const categoryResponse = await fetch('https://api.myntask.com/project/category', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });


        if (!categoryResponse.ok) {
          throw new Error(`Network response was not ok. Status: ${categoryResponse.status}`);
        }


        const categoryData = await categoryResponse.json();
        setCategories(categoryData);


        // Fetch currencies
        const currencyResponse = await fetch('https://api.myntask.com/company/currency', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });


        if (!currencyResponse.ok) {
          throw new Error(`Network response was not ok. Status: ${currencyResponse.status}`);
        }


        const currencyData = await currencyResponse.json();
        setCurrencies(currencyData);


        // Fetch clients
        const clientResponse = await fetch('https://api.myntask.com/project/client', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });


        if (!clientResponse.ok) {
          throw new Error(`Network response was not ok. Status: ${clientResponse.status}`);
        }


        const clientData = await clientResponse.json();


        // Ensure clientData is an array
        if (Array.isArray(clientData)) {
          setClients(clientData); // Set the data only if it's an array
        } else {
          setError('Unexpected data format for clients.');
          setClients([]); // Clear the data if not an array
        }
      } catch (error) {
        console.error(error); // Log the actual error for debugging
        setError('Failed to fetch data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };


    fetchData();
  }, [id]);




  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProjectData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };




  const handleStatusChange = (e) => {
    setProjectData((prevData) => ({
      ...prevData,
      public: e.target.value,
    }));
  };




  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    setProjectData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };




  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    const token = localStorage.getItem('authToken'); // Retrieve token from localStorage

    if (!token) {
      setError('No authentication token found.');
      toast.error('No authentication token found.'); // Display error toast
      return;
    }

    const updatedData = {
      project_id: id,
      project_short_code: projectData.project_short_code || '',
      project_name: projectData.project_name || '',
      start_date: projectData.start_date || '',
      deadline: projectData.deadline || '',
      category_id: projectData.category_id || null,
      client_id: projectData.client_id || null,
      department_id: projectData.department_id || '',
      project_summary: projectData.project_summary || '',
      assign_to: projectData.assign_to || '',
      status: projectData.status || '',
      currency_id: projectData.currency_id || '',
      project_budget: projectData.project_budget || null,
      client_access: projectData.client_access === 'enabled' ? 1 : 0,
      public: projectData.public === 'public' ? 1 : 0,
      allow_client_notification: projectData.allow_client_notification === 'enabled' ? 1 : 0,
      manual_timelog: projectData.manual_timelog === 'enabled' ? 1 : 0,
    };

    try {
      const response = await fetch('https://api.myntask.com/project/update', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Include token in the header
        },
        body: JSON.stringify(updatedData),
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok. Status: ${response.status}`);
      }

      const responseData = await response.json(); // Parse JSON response body
      console.log('Server response:', responseData);

      toast.success('Project updated successfully!'); // Display success toast

      // Optionally, you can also update the UI or state based on the response
      // setProjectData(responseData.updatedProject); // Example: update the state with the updated project data

    } catch (error) {
      console.error('Failed to update project:', error);
      toast.error('Failed to update project.'); // Display error toast
      setError('Failed to update project. Please try again later.');
    }
  };



  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }




  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }




  return (
    <div className='formContainer'>
      <ToastContainer />
      <h3 className="center-heading">Edit Project</h3>
      {projectData && (
        <form className="employeeForm" onSubmit={handleSubmit}>
          <div className="container1">
            <label className="label">Project Short Code</label>
            <input
              type="text"
              name="project_short_code"
              value={projectData.project_short_code || ''}
              onChange={handleInputChange}
              className="field"
            />
          </div>

          <div className="container1">
            <label className="label">Project Name</label>
            <input
              type="text"
              name="project_name"
              value={projectData.project_name || ''}
              onChange={handleInputChange}
              className="field"
            />
          </div>

          <div className="container1">
            <label className="label">Start Date</label>
            <input
              type="date"
              name="start_date"
              value={projectData.start_date ? new Date(projectData.start_date).toISOString().split('T')[0] : ''}
              onChange={handleInputChange}
              className="field"
            />
          </div>

          <div className="container1">
            <label className="label">Deadline</label>
            <input
              type="date"
              name="deadline"
              value={projectData.deadline ? new Date(projectData.deadline).toISOString().split('T')[0] : ''}
              onChange={handleInputChange}
              className="field"
            />
          </div>

          <div className="container1">
            <label className="label">Category</label>
            <select
              name="category_id"
              value={projectData.category_id || ''}
              onChange={handleInputChange}
              className="drop"
            >
              <option value="">None</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.category_name}
                </option>
              ))}
            </select>
          </div>

          <div className="container1">
            <label className="label">Currency</label>
            <select
              name="currency_id"
              value={projectData.currency_id || ''}
              onChange={handleInputChange}
              className="drop"
            >
              <option value="">None</option>
              {currencies.map((currency) => (
                <option key={currency.id} value={currency.id}>
                  {currency.currency_code} ({currency.currency_symbol})
                </option>
              ))}
            </select>
          </div>

          <div className="container1">
            <label className="label">Client</label>
            <select
              name="client_id"
              value={projectData.client_id || ''}
              onChange={handleInputChange}
              className="drop"
            >
              <option value="">None</option>
              {clients.map((client) => (
                <option key={client.client_id} value={client.client_id}>
                  {client.client_name}
                </option>
              ))}
            </select>
          </div>

          <div className="container1">
            <label className="label">Department ID</label>
            <input
              type="text"
              name="department_id"
              value={projectData.department_id || ''}
              onChange={handleInputChange}
              className="field"
            />
          </div>

          <div className="container1">
            <label className="label">Project Summary</label>
            <textarea
              name="project_summary"
              value={projectData.project_summary || ''}
              onChange={handleInputChange}
              className='text'
            />
          </div>

          <div className="container1">
            <label className="label">Assign To</label>
            <input
              type="text"
              name="assign_to"
              value={projectData.assign_to || ''}
              onChange={handleInputChange}
              className="field"
            />
          </div>

          <div className="container1">
            <label className="label">Status</label>
            <select
              name="status"
              value={projectData.status || ''}
              onChange={handleDropdownChange}
              className="drop"
            >
              {statuses.map((status) => (
                <option key={status} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>

          <div className="container1">
            <label className="label">Project Budget</label>
            <input
              type="number"
              name="project_budget"
              value={projectData.project_budget || ''}
              onChange={handleInputChange}
              className="field"
            />
          </div>

          <div className="container1">
            <label className="label">Client Access</label>
            <select
              name="client_access"
              value={projectData.client_access === 1 ? 'enabled' : 'disabled'}
              onChange={handleDropdownChange}
              className="drop"
            >
              <option value="enabled">Enabled</option>
              <option value="disabled">Disabled</option>
            </select>
          </div>

          <div className="container1">
            <label className="label">Allow Client Notification</label>
            <select
              name="allow_client_notification"
              value={projectData.allow_client_notification || 'disable'}
              onChange={handleDropdownChange}
              className="drop"
            >
              <option value="enabled">Enabled</option>
              <option value="disabled">Disabled</option>
            </select>
          </div>

          <div className="container1">
            <label className="label">Manual Timelog</label>
            <select
              name="manual_timelog"
              value={projectData.manual_timelog || 'disable'}
              onChange={handleDropdownChange}
              className="drop"
            >
              <option value="enabled">Enabled</option>
              <option value="disabled">Disabled</option>
            </select>
          </div>

          <div className="container1">
            <label className="label">Visibility</label>
            <div>
              <input
                type="radio"
                name="public"
                value="public"
                checked={projectData.public === 1}
                onChange={handleStatusChange}
              />{' '}
              Public
              <input
                type="radio"
                name="public"
                value="private"
                checked={projectData.public === 0}
                onChange={handleStatusChange}
                style={{ marginLeft: '20px' }}
              />{' '}
              Private
            </div>
          </div>

          <button
            type="submit"
            className='submit-button'
          >
            Save
          </button>
        </form>
      )}
    </div>

  );
};




export default EditProject;
