

// src/components/Layout.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';




import {
  Box,
  Toolbar,
 Modal,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Collapse,
  ListItemIcon,
  Menu,
  MenuItem,
  CircularProgress,
  Typography,
  Badge, 
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { FiPlusCircle } from "react-icons/fi";
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { styled, useTheme,  } from '@mui/material/styles';
// import { Badge, IconButton, Modal, Typography, Box } from '@mui/material';


import {
  FaAlignLeft, FaArrowRightToBracket, FaArrowsSpin, FaAudible, FaBarsProgress, FaBloggerB, FaBorderTopLeft, FaBridgeCircleCheck, FaBuffer,
  FaBuildingUser, FaCalendarDay, FaCcAmazonPay, FaChalkboard, FaChartColumn, FaCheckDouble, FaCheckToSlot, FaClipboardList, FaComputer, FaCopy,
  FaCreditCard, FaElevator, FaEllipsisVertical, FaFax, FaFileCirclePlus, FaFileContract, FaFoursquare, FaHardDrive, FaHospitalUser, FaHouseChimneyUser,
  FaHouseCircleExclamation, FaHouzz, FaIndent, FaIndustry, FaJxl, FaList, FaListCheck, FaMoneyBillTrendUp, FaMoneyCheck, FaPenToSquare, FaPeopleGroup,
  FaPersonChalkboard, FaPesetaSign, FaPowerOff, FaRectangleList, FaRegCalendarMinus, FaRegChartBar, FaRegCircleXmark, FaRegClipboard, FaRegCreditCard, FaRegHardDrive,
  FaRegHourglassHalf, FaRegIdBadge, FaRegNewspaper, FaRegRectangleList, FaRegThumbsUp, FaRegWindowMaximize, FaRegWindowRestore, FaSackDollar,
  FaScroll, FaSheetPlastic, FaShopify, FaSliders, FaSquareEnvelope, FaSquarePollHorizontal, FaTable, FaTableList, FaTablet, FaThinkPeaks, FaTrello, FaUpload,
   FaWallet, FaWindowMaximize, FaWindowRestore, FaWpforms,
  FaXmarksLines,
} from 'react-icons/fa6';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';
import { FaBookOpen } from 'react-icons/fa';
import MarkAttendance from './MarkAttendance';
import { Button } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import myntask from './../assets/myntask.png';








const drawerWidth = 230;
















const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));








const Layout = ({ children }) => {
  const navigate = useNavigate();
  const [currentTime, setCurrentTime] = useState('');
  const [currentDay, setCurrentDay] = useState('');
  const [mobileOpen, setMobileOpen] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [userProfile, setUserProfile] = useState({});
  const [activeItem, setActiveItem] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const[attendance,setAttendance]= useState({});
 const [latitude, setLatitude] = useState(null);
 const [longitude, setLongitude] = useState(null);
 const [isClockedIn, setIsClockedIn] = useState(false); // Track whether the user is clocked in
 const [loading, setLoading] = useState(false); // Track loading state
 const [isModalOpen, setModalOpen] = useState(false);
 const [notifications, setNotifications] = useState([]);
 const [notificationCount, setNotificationCount] = useState(0); 
 const [error, setError] = useState(null);




 








 const getUserLocationAndSaveAttendance = async (setIsClockedIn) => {
  setLoading(true); // Start loading when the process begins




  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;




        // After getting the location, call handleSaveAttendance with lat and lng
        const success = await handleSaveAttendance(lat, lng);
        
        // If attendance is marked successfully, update the clock-in state
        if (success) {
          setIsClockedIn(true);
        }




        setLoading(false); // End loading after the process is done
      },
      (error) => {
        console.error('Error getting user location:', error);
        toast.error('Error getting user location');
        setLoading(false); // End loading on error
      }
    );
  } else {
    toast.error('Geolocation is not supported by this browser.');
    setLoading(false); // End loading if geolocation is not supported
  }
};
const fetchNotifications = async () => {
  const token = localStorage.getItem('authToken');
  if (!token) {
    setError('No token found. Please log in.');
    return;
  }


  try {
    const response = await fetch('http://api.myntask.com/notification/list', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });


    if (!response.ok) {
      throw new Error('Failed to fetch notifications');
    }


    const data = await response.json();
    
    console.log('API Response notifications:', data);
    console.log('Notification Count:', data.notificationCount);


    if (data.notifications && Array.isArray(data.notifications)) {
      setNotifications(data.notifications);
      setNotificationCount(data.notificationCount); // Update the notification count
    } else {
      setNotifications([]);
      setNotificationCount(0); // Set count to 0 if no notifications are found
    }
  } catch (error) {
    console.error('Error fetching notifications:', error);
    setError(error.message);
  }
};








const showModal = () => {
  fetchNotifications(); // Fetch notifications when the modal is opened
  setModalOpen(true);
};


const hideModal = () => setModalOpen(false);








// Auto-refresh notifications every 5 seconds
useEffect(() => {
  fetchNotifications(); // Fetch when the component first loads


  const intervalId = setInterval(() => {
    fetchNotifications();
  }, 5000); // Refresh every 5 seconds


  return () => clearInterval(intervalId); // Clean up interval on component unmount
}, []);


// Auto-refresh the notifications every 5 seconds
useEffect(() => {
  fetchNotifications(); // Fetch notifications when modal is first opened


  const intervalId = setInterval(() => {
    fetchNotifications();
  }, 5000); // Refresh every 5 seconds


  return () => clearInterval(intervalId); // Clean up the interval on component unmount
}, []); // Empty dependency array ensures it runs once when the component mounts




const getUserLocationAndClockOut = async () => {
  setLoading(true); // Start loading when the process begins




  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        await handleClockOut(lat, lng);




        setLoading(false); // End loading after clock-out
      },
      (error) => {
        console.error('Error getting user location for clock out:', error);
        toast.error('Error getting user location for clock out');
        setLoading(false); // End loading on error
      }
    );
  } else {
    toast.error('Geolocation is not supported by this browser.');
    setLoading(false); // End loading if geolocation is not supported
  }
};




// Save the attendance after getting the user's location
const handleSaveAttendance = async (latitude, longitude) => {
  const url = 'https://api.myntask.com/attendance/save';
  const data = { latitude, longitude };




  console.log('Marking attendance with data:', data);




  try {
    const token = localStorage.getItem("authToken");
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });




    const responseText = await response.text();




    if (response.ok) {
      try {
        const result = JSON.parse(responseText); // Parse JSON if possible
        console.log('Attendance marked successfully:', result);
        toast.success('Attendance marked successfully');
        return true;
      } catch (error) {
        console.log('Attendance marked successfully (Non-JSON response):', responseText);
        toast.success(responseText);
        return true;
      }
    } else {
      const result = JSON.parse(responseText);
      console.error('Failed to save attendance:', result.message || result.error);
      toast.error(result.message || 'Failed to save attendance');
      return false;
    }
  } catch (error) {
    console.error('Error saving attendance:', error);
    toast.error('Error saving attendance');
    return false;
  }
};




// Clock Out Function
const handleClockOut = async (latitude, longitude) => {
  const clockOutTimestamp = dayjs().format('YYYY-MM-DD HH:mm:ss'); // Get the current time in the required format
  const url = 'https://api.myntask.com/attendance/save'; // Reusing the same save endpoint for clock-out
  const data = {
    latitude,
    longitude,
    clock_out: clockOutTimestamp, // Send the clock-out time
  };




  console.log('Clocking out with data:', data);




  try {
    const token = localStorage.getItem("authToken");
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });




    const responseText = await response.text();
    console.log('Clock-out response:', responseText);




    try {
      const result = JSON.parse(responseText); // Try to parse JSON
      if (response.ok) {
        toast.success('Clocked out successfully');
      } else {
        toast.error(result.message || 'Failed to clock out');
      }
    } catch (error) {
      console.warn('Non-JSON response:', responseText); // Log non-JSON response
      if (response.ok) {
        toast.success(responseText); // Show success message if it's a success
      } else {
        toast.error(responseText); // Show error message from the response
      }
    }
  } catch (error) {
    console.error('Error clocking out:', error);
    toast.error('Error clocking out');
  }
};
 // Fetch attendance status from localStorage when the component mounts
 useEffect(() => {
  const storedAttendance = JSON.parse(localStorage.getItem('attendanceStatus')) || {};
  setAttendance(storedAttendance);




  // Determine clock-in status based on stored attendance
  if (storedAttendance.clockInStatus === 'Yes') {
    setIsClockedIn(true); // User is already clocked in
  } else {
    setIsClockedIn(false); // User is not clocked in
  }
}, []);




  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };




  const handleMenuClose = () => {
    setAnchorEl(null);
  };




  const handleClickMenu = (path) => {
    navigate(path); // Navigate to the provided path
  };












  useEffect(() => {
    const updateCurrentTime = () => {
      const now = new Date();
      const time = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      const day = now.toLocaleDateString([], { weekday: 'long', month: 'long', day: 'numeric' });
      setCurrentTime(time);
      setCurrentDay(day);
    };




    updateCurrentTime();
    const timer = setInterval(updateCurrentTime, 1000);




    return () => clearInterval(timer);
  }, []);








  const handleClick = (path) => {
    setActiveItem(path);
    navigate(path);




  };








  useEffect(() => {
    // Fetch user role from localStorage
    const role = localStorage.getItem('is_superadmin');
    setUserRole(role);




    const storedProfile = JSON.parse(localStorage.getItem('userProfile')) || {};
    setUserProfile(storedProfile);
    
  }, []);




  
  const handleLogout = () => {
    // Show a confirmation dialog to the user
    const confirmLogout = window.confirm('Do you really want to log out?');








    // Check if the user clicked "Yes"
    if (confirmLogout) {
      // Clear all data from local storage
      localStorage.clear();








      // Redirect to the login page
      navigate('/');
    }
    // If the user clicked "No", do nothing (i.e., do not log out)
  };




  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);  // Toggles the drawer state
  };




  const handleOpenn = () => setOpen(true);
  const handleClosee = () => setOpen(false);








  const handleDropdownClick = (dropdown) => {
    if (openDropdown === dropdown) {
      setOpenDropdown(null); // Close the dropdown if it’s already open
    } else {
      setOpenDropdown(dropdown); // Open the clicked dropdown and close others
    }
  };




  const drawer = (
    <div>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}  // Toggle drawer open/close
        sx={{ mr: 2, display: { sm: 'none' } }}  // Only show on small screens
      >
        <MenuIcon />
      </IconButton>
      {/* <div style={{}}>
      <img src={myntask} alt="Myntask Logo" style={{ width: '200px',}} />
    </div> */}
      <DrawerHeader sx={{ display: 'flex', alignItems: 'center',justifyContent:'flex-start'}}>
      <img
      src={myntask}
      alt="Myntask Logo"
      style={{ width: '80%', height: 'auto'}} // Adjust the width/height as needed
    />
      </DrawerHeader>








      <Divider />
      <List>
        <ListItem
          button
          onClick={() => handleClick('/dashboard')}
          sx={{
            backgroundColor: activeItem === '/dashboard' ? '#3b82f6' : 'transparent',
            color: activeItem === '/dashboard' ? '#fff' : 'inherit',
            borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/dashboard' ? '#fff' : 'inherit', // Change icon color when active
    },
   '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
          }}
        >
           <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> <FaTrello /> </ListItemIcon>




          <ListItemText className='list' primary="My Dashboard" />
        </ListItem>
        <ListItem button onClick={() => handleClick('/advanced-dashboard')}
          sx={{
            backgroundColor: activeItem === '/advanced-dashboard' ? '#3b82f6' : 'transparent',
            color: activeItem === '/advanced-dashboard' ? '#fff' : 'inherit',
            borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
            '& .MuiListItemIcon-root': {
      color: activeItem === '/advanced-dashboard' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
          }}>
          <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> <FaCreditCard /> </ListItemIcon>
          <ListItemText primary="Advanced Dashboard" />
        </ListItem>




      
        <ListItem button onClick={() => handleDropdownClick('leads')}
          sx={{borderRadius: '10px',padding:'5px 8px',marginBottom:'5px','&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    }}}>
 <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}>
 <FaCalendarDay />
 </ListItemIcon>
 <ListItemText primary="CRM" />
 {openDropdown === 'leads' ? <ExpandLess /> : <ChevronRightOutlinedIcon/>}
</ListItem>
<Collapse in={openDropdown === 'leads'} timeout="auto" unmountOnExit>
         <List component="div" disablePadding>
           <ListItem button onClick={() => handleClick('/lead-contact')}
             sx={{
               backgroundColor: activeItem === '/lead-contact' ? '#3b82f6' : 'transparent',
               color: activeItem === '/lead-contact' ? '#fff' : 'inherit',
               borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/lead-contact' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
               pl: 4
             }}>
             {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> <FaBuffer /> </ListItemIcon> */}
             <ListItemText primary="Lead Contact" />
           </ListItem>
           <ListItem button onClick={() => handleClick('/deals')}
             sx={{
               backgroundColor: activeItem === '/deals' ? '#3b82f6' : 'transparent',
               color: activeItem === '/deals' ? '#fff' : 'inherit',
               borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/deals' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
               pl: 4
             }}>
             {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> <FaCheckDouble /> </ListItemIcon> */}
             <ListItemText primary="Deals" />
           </ListItem>
         </List>
       </Collapse>








        <ListItem button onClick={() => handleDropdownClick('hr')}
          sx={{borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
            '&:hover': {
            backgroundColor: '#3b82f6',
            color: '#fff',
            '& .MuiListItemIcon-root': {
              color: '#fff', // Change icon color on hover
            }
          }}}>
  <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}>
  <FaElevator />
  </ListItemIcon>
  <ListItemText primary="HR" />
  {openDropdown === 'hr' ? <ExpandLess /> : <ChevronRightOutlinedIcon/>}
</ListItem>
<Collapse in={openDropdown === 'hr'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button onClick={() => handleClick('/employee')}
              sx={{
                backgroundColor: activeItem === '/employee' ? '#3b82f6' : 'transparent',
                color: activeItem === '/employee' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/employee' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaPeopleGroup /> </ListItemIcon> */}
              <ListItemText primary="Team Member" />
            </ListItem>
            <ListItem button onClick={() => handleClick('/holiday')}
              sx={{
                backgroundColor: activeItem === '/holiday' ? '#3b82f6' : 'transparent',
                color: activeItem === '/holiday' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/holiday' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaRegCalendarMinus /></ListItemIcon> */}
              <ListItemText primary="Holiday" />
            </ListItem>
            <ListItem button onClick={() => handleClick('/leave')}
              sx={{
                backgroundColor: activeItem === '/leave' ? '#3b82f6' : 'transparent',
                color: activeItem === '/leave' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/leave' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaRegCircleXmark /></ListItemIcon> */}
              <ListItemText primary="Leave" />
            </ListItem>
            <ListItem button onClick={() => handleClick('/shift-roaster')}
              sx={{
                backgroundColor: activeItem === '/shift-roaster' ? '#3b82f6' : 'transparent',
                color: activeItem === '/shift-roaster' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/shift-roaster' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaRegHourglassHalf /></ListItemIcon> */}
              <ListItemText primary="Shift Roaster" />
            </ListItem>
            <ListItem button onClick={() => handleClick('/attendance')}
              sx={{
                backgroundColor: activeItem === '/attendance' ? '#3b82f6' : 'transparent',
                color: activeItem === '/attendance' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/attendance' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaRegIdBadge /></ListItemIcon> */}
              <ListItemText primary="Attendance" />
            </ListItem>
            {/* <ListItem button onClick={() => handleClick('/appreciation')}
              sx={{
                backgroundColor: activeItem === '/appreciation' ? '#3b82f6' : 'transparent',
                color: activeItem === '/appreciation' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/appreciation' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaRegThumbsUp /></ListItemIcon>
              <ListItemText primary="Appreciation" />
            </ListItem> */}
            <ListItem button onClick={() => handleClick('/designation')}
              sx={{
                backgroundColor: activeItem === '/designation' ? '#3b82f6' : 'transparent',
                color: activeItem === '/designation' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/designation' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaBorderTopLeft /></ListItemIcon> */}
              <ListItemText primary="Designation" />
            </ListItem>
            <ListItem button onClick={() => handleClick('/department')}
              sx={{
                backgroundColor: activeItem === '/department' ? '#3b82f6' : 'transparent',
                color: activeItem === '/department' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/department' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaBuildingUser /></ListItemIcon> */}
              <ListItemText primary="Department" />
            </ListItem>
            {/* <ListItem button onClick={() => handleClick('/payroll')}
              sx={{
                backgroundColor: activeItem === '/payroll' ? '#3b82f6' : 'transparent',
                color: activeItem === '/payroll' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/payroll' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> <FaSheetPlastic /> </ListItemIcon>
              <ListItemText primary="Payroll" />
            </ListItem> */}
            {/* <ListItem button onClick={() => handleClick('/report')}
              sx={{
                backgroundColor: activeItem === '/report' ? '#3b82f6' : 'transparent',
                color: activeItem === '/report' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/report' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> <FaChalkboard /> </ListItemIcon>
              <ListItemText primary="Reports" />
            </ListItem> */}
            <ListItem button onClick={() => handleClick('/employee-salary')}
              sx={{
                backgroundColor: activeItem === '/employee-salary' ? '#3b82f6' : 'transparent',
                color: activeItem === '/employee-salary' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/employee-salary' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> <FaBuffer /> </ListItemIcon> */}
              <ListItemText primary="Employee Salary" />
            </ListItem>
            <ListItem button onClick={() => handleClick('/recruit-dashboard')}
              sx={{
                backgroundColor: activeItem === '/recruit-dashboard' ? '#3b82f6' : 'transparent',
                color: activeItem === '/recruit-dashboard' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/recruit-dashboard' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaCheckToSlot /> </ListItemIcon> */}
              <ListItemText primary="Recruit Dashboard" />
            </ListItem>
            {/* <ListItem button onClick={() => handleClick('/job')}
              sx={{
                backgroundColor: activeItem === '/job' ? '#3b82f6' : 'transparent',
                color: activeItem === '/job' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/job' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaChartColumn /></ListItemIcon>
              <ListItemText primary="Jobs" />
            </ListItem> */}
            {/* <ListItem button onClick={() => handleClick('/job-application')}
              sx={{
                backgroundColor: activeItem === '/job-application' ? '#3b82f6' : 'transparent',
                color: activeItem === '/job-application' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/job-application' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaBloggerB /></ListItemIcon>
              <ListItemText primary="Job Application" />
            </ListItem> */}
            {/* <ListItem button onClick={() => handleClick('/interview')}
              sx={{
                backgroundColor: activeItem === '/interview' ? '#3b82f6' : 'transparent',
                color: activeItem === '/interview' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/interview' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaClipboardList /></ListItemIcon>
              <ListItemText primary="Interview-Schedule" />
            </ListItem> */}
            {/* <ListItem button onClick={() => handleClick('/offer-letter')}
              sx={{
                backgroundColor: activeItem === '/offer-letter' ? '#3b82f6' : 'transparent',
                color: activeItem === '/offer-letter' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/offer-letter' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaCopy /></ListItemIcon>
              <ListItemText primary="Offer Letter" />
            </ListItem> */}
            {/* <ListItem button onClick={() => handleClick('/skill')}
              sx={{
                backgroundColor: activeItem === '/skill' ? '#3b82f6' : 'transparent',
                color: activeItem === '/skill' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/skill' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaCopy /></ListItemIcon>
              <ListItemText primary="Skills" />
            </ListItem> */}
            {/* <ListItem button onClick={() => handleClick('/candidate-database')}
              sx={{
                backgroundColor: activeItem === '/candidate-database' ? '#3b82f6' : 'transparent',
                color: activeItem === '/candidate-database' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/candidate-database' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaAlignLeft /></ListItemIcon>
              <ListItemText primary="Candidate Database" />
            </ListItem> */}
            {/* <ListItem button onClick={() => handleClick('/recruit-report')}
              sx={{
                backgroundColor: activeItem === '/recruit-report' ? '#3b82f6' : 'transparent',
                color: activeItem === '/recruit-report' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/recruit-report' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaAudible /></ListItemIcon>
              <ListItemText primary="Recruit Report" />
            </ListItem> */}
            {/* <ListItem button onClick={() => handleClick('/career-site')}
              sx={{
                backgroundColor: activeItem === '/career-site' ? '#3b82f6' : 'transparent',
                color: activeItem === '/career-site' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/career-site' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaBuildingUser /></ListItemIcon>
              <ListItemText primary="Career Site" />
            </ListItem> */}
            <ListItem button onClick={() => handleClick('/event')}
          sx={{
            backgroundColor: activeItem === '/event' ? '#3b82f6' : 'transparent',
            color: activeItem === '/event' ? '#fff' : 'inherit',
            borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
            
    '& .MuiListItemIcon-root': {
      color: activeItem === '/event' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
    pl: 4
          }}>
          {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> <FaClipboardList /> </ListItemIcon> */}
          <ListItemText primary="Events" />
        </ListItem>
        <ListItem button onClick={() => handleClick('/notice-board')}
          sx={{
            backgroundColor: activeItem === '/notice-board' ? '#3b82f6' : 'transparent',
            color: activeItem === '/notice-board' ? '#fff' : 'inherit',
            borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/notice-board' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },   
    pl: 4




          }}>
          {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> <FaRegClipboard /> </ListItemIcon> */}
          <ListItemText primary="NoticeBoard" />
        </ListItem>
        <ListItem button onClick={() => handleClick('/knowledge-base')}
          sx={{
            backgroundColor: activeItem === '/knowledge-base' ? '#3b82f6' : 'transparent',
            color: activeItem === '/knowledge-base' ? '#fff' : 'inherit',
            borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/knowledge-base' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
    pl: 4
          }}>
          {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> <FaTableList /> </ListItemIcon> */}
          <ListItemText primary="Knowledge Base" />
        </ListItem>
          </List>
</Collapse>
        




 <ListItem button onClick={() => handleDropdownClick('work')}
          sx={{borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
            '&:hover': {
            backgroundColor: '#3b82f6',
            color: '#fff',
            '& .MuiListItemIcon-root': {
              color: '#fff', // Change icon color on hover
            }
          }}}>
  <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}>
  <FaMoneyCheck />
  </ListItemIcon>
  <ListItemText primary="Work" />
  {openDropdown === 'work' ? <ExpandLess /> : <ChevronRightOutlinedIcon/>}
</ListItem>
<Collapse in={openDropdown === 'work'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button onClick={() => handleClick('/task')}
              sx={{
                backgroundColor: activeItem === '/task' ? '#3b82f6' : 'transparent',
                color: activeItem === '/task' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/task' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> <FaListCheck /> </ListItemIcon> */}
              <ListItemText primary="Tasks" />
            </ListItem>
            <ListItem button onClick={() => handleClick('/project')}
              sx={{
                backgroundColor: activeItem === '/project' ? '#3b82f6' : 'transparent',
                color: activeItem === '/project' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/project' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> <FaPenToSquare /> </ListItemIcon> */}
              <ListItemText primary="Projects" />
            </ListItem>
            {/* <ListItem button onClick={() => handleClick('/timesheet')}
              sx={{
                backgroundColor: activeItem === '/timesheet' ? '#3b82f6' : 'transparent',
                color: activeItem === '/timesheet' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/timesheet' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> <FaTable /> </ListItemIcon>
              <ListItemText primary="Time Sheet" />
            </ListItem> */}
            {/* <ListItem button onClick={() => handleClick('/contracts')}
              sx={{
                backgroundColor: activeItem === '/contracts' ? '#3b82f6' : 'transparent',
                color: activeItem === '/contracts' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/contracts' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> <FaWindowRestore /> </ListItemIcon>
              <ListItemText primary="Contracts" />
            </ListItem> */}
            {/* <ListItem button onClick={() => handleClick('/project-roadmap')}
              sx={{
                backgroundColor: activeItem === '/project-roadmap' ? '#3b82f6' : 'transparent',
                color: activeItem === '/project-roadmap' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/project-roadmap' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaWpforms /> </ListItemIcon>
              <ListItemText primary="Project Roadmap" />
            </ListItem> */}
            <ListItem button onClick={() => handleClick('/clients')}
          sx={{
            backgroundColor: activeItem === '/clients' ? '#3b82f6' : 'transparent',
            color: activeItem === '/clients' ? '#fff' : 'inherit',
            borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/clients' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
            pl: 4
          }}>
          {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> <FaHouseChimneyUser /> </ListItemIcon> */}
          <ListItemText primary="Clients" />
        </ListItem>
        <ListItem button onClick={() => handleClick('/expense')}
              sx={{
                backgroundColor: activeItem === '/expense' ? '#3b82f6' : 'transparent',
                color: activeItem === '/expense' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/expense' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> <FaMoneyBillTrendUp /> </ListItemIcon> */}
              <ListItemText primary="Expenses" />
            </ListItem>




          </List>
 </Collapse>








        <ListItem button onClick={() => handleDropdownClick('purchase')}
          sx={{borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
            '&:hover': {
            backgroundColor: '#3b82f6',
            color: '#fff',
            '& .MuiListItemIcon-root': {
              color: '#fff', // Change icon color on hover
            }
          }}}>
  <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}>
  <FaFileCirclePlus />




  </ListItemIcon>
  <ListItemText primary="Purchase" />
  {openDropdown === 'purchase' ? <ExpandLess /> : <ChevronRightOutlinedIcon/>}
</ListItem>
<Collapse in={openDropdown === 'purchase'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button onClick={() => handleClick('/vendor')}
              sx={{
                backgroundColor: activeItem === '/vendor' ? '#3b82f6' : 'transparent',
                color: activeItem === '/vendor' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/vendor' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaPersonChalkboard /></ListItemIcon> */}
              <ListItemText primary="Vendor" />
            </ListItem>
            <ListItem button onClick={() => handleClick('/product')}
              sx={{
                backgroundColor: activeItem === '/product' ? '#3b82f6' : 'transparent',
                color: activeItem === '/product' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/product' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaShopify /></ListItemIcon> */}
              <ListItemText primary="Products" />
            </ListItem>
            <ListItem button onClick={() => handleClick('/purchase-order')}
              sx={{
                backgroundColor: activeItem === '/purchase-order' ? '#3b82f6' : 'transparent',
                color: activeItem === '/purchase-order' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/purchase-order' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaWallet /></ListItemIcon> */}
              <ListItemText primary="Purchase Order" />
            </ListItem>
            <ListItem button onClick={() => handleClick('/purchase-requisation')}
             sx={{
               backgroundColor: activeItem === '/purchase-requisation' ? '#3b82f6' : 'transparent',
               color: activeItem === '/purchase-requisation' ? '#fff' : 'inherit',
               borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/purchase-requisation' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
               pl: 4
             }}>
             {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaWpforms /></ListItemIcon> */}
             <ListItemText primary="Purchase Requisition" />
           </ListItem>
            <ListItem button onClick={() => handleClick('/bill')}
              sx={{
                backgroundColor: activeItem === '/bill' ? '#3b82f6' : 'transparent',
                color: activeItem === '/bill' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/bill' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaSquarePollHorizontal /></ListItemIcon> */}
              <ListItemText primary="Bills" />
            </ListItem>
            {/* <ListItem button onClick={() => handleClick('/vendor-payment')}
              sx={{
                backgroundColor: activeItem === '/vendor-payment' ? '#3b82f6' : 'transparent',
                color: activeItem === '/vendor-payment' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/vendor-payment' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaPesetaSign /></ListItemIcon>
              <ListItemText primary="Vendor Payments" />
            </ListItem> */}
            {/* <ListItem button onClick={() => handleClick('/vendor-credit')}
              sx={{
                backgroundColor: activeItem === '/vendor-credit' ? '#3b82f6' : 'transparent',
                color: activeItem === '/vendor-credit' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/vendor-credit' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaRegCreditCard /></ListItemIcon>
              <ListItemText primary="Vendor Credits" />
            </ListItem> */}
            {/* <ListItem button onClick={() => handleClick('/inventory')}
              sx={{
                backgroundColor: activeItem === '/inventory' ? '#3b82f6' : 'transparent',
                color: activeItem === '/inventory' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/inventory' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaHouzz /></ListItemIcon>
              <ListItemText primary="Inventory" />
            </ListItem> */}
            {/* <ListItem button onClick={() => handleClick('/purchase-reports')}
              sx={{
                backgroundColor: activeItem === '/purchase-reports' ? '#3b82f6' : 'transparent',
                color: activeItem === '/purchase-reports' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/purchase-reports' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaIndustry /></ListItemIcon>
              <ListItemText primary="Purchase Report" />
            </ListItem> */}
            <ListItem button onClick={() => handleClick('/estimates')}
              sx={{
                backgroundColor: activeItem === '/estimates' ? '#3b82f6' : 'transparent',
                color: activeItem === '/estimates' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/estimates' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> <FaThinkPeaks /> </ListItemIcon> */}
              <ListItemText primary="Estimates" />
            </ListItem>
            {/* <ListItem button onClick={() => handleClick('/invoices')}
              sx={{
                backgroundColor: activeItem === '/invoices' ? '#3b82f6' : 'transparent',
                color: activeItem === '/invoices' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/invoices' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> <FaWindowMaximize /> </ListItemIcon>
              <ListItemText primary="Invoices" />
            </ListItem> */}
            {/* <ListItem button onClick={() => handleClick('/proposal')}
              sx={{
                backgroundColor: activeItem === '/proposal' ? '#3b82f6' : 'transparent',
                color: activeItem === '/proposal' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/proposal' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> <FaArrowRightToBracket /> </ListItemIcon>
              <ListItemText primary="Proposal" />
            </ListItem> */}
            {/* <ListItem button onClick={() => handleClick('/payments')}
              sx={{
                backgroundColor: activeItem === '/payments' ? '#3b82f6' : 'transparent',
                color: activeItem === '/payments' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/payments' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> <FaRegChartBar /> </ListItemIcon>
              <ListItemText primary="Payments" />
            </ListItem> */}
            {/* <ListItem button onClick={() => handleClick('/credit-note')}
              sx={{
                backgroundColor: activeItem === '/credit-note' ? '#3b82f6' : 'transparent',
                color: activeItem === '/credit-note' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/credit-note' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> <FaHardDrive /> </ListItemIcon>
              <ListItemText primary="Credit Note" />
            </ListItem> */}
           
            {/* <ListItem button onClick={() => handleClick('/bank-account')}
              sx={{
                backgroundColor: activeItem === '/bank-account' ? '#3b82f6' : 'transparent',
                color: activeItem === '/bank-account' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/bank-account' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> <FaRegNewspaper /> </ListItemIcon>
              <ListItemText primary="BankAccount" />
            </ListItem> */}
            {/* <ListItem button onClick={() => handleClick('/e-invoice')}
              sx={{
                backgroundColor: activeItem === '/e-invoice' ? '#3b82f6' : 'transparent',
                color: activeItem === '/e-invoice' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/e-invoice' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> <FaScroll /> </ListItemIcon>
              <ListItemText primary="E-Invoice" />
            </ListItem> */}
          </List>
 </Collapse>








        <ListItem button onClick={() => handleDropdownClick('reports')}
          sx={{borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
            '&:hover': {
            backgroundColor: '#3b82f6',
            color: '#fff',
            '& .MuiListItemIcon-root': {
              color: '#fff', // Change icon color on hover
            }
          }}}>
  <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}>
  <FaListCheck />
  </ListItemIcon>
  <ListItemText primary="Reports" />
  {openDropdown === 'reports' ? <ExpandLess /> : <ChevronRightOutlinedIcon/>}
</ListItem>
<Collapse in={openDropdown === 'reports'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>




            <ListItem button onClick={() => handleClick('/task-report')}
              sx={{
                backgroundColor: activeItem === '/task-report' ? '#3b82f6' : 'transparent',
                color: activeItem === '/task-report' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/task-report' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaRectangleList /></ListItemIcon> */}
              <ListItemText primary="Task Report" />
            </ListItem>
            {/* <ListItem button onClick={() => handleClick('/time-log-report')}
              sx={{
                backgroundColor: activeItem === '/time-log-report' ? '#3b82f6' : 'transparent',
                color: activeItem === '/time-log-report' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/time-log-report' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaRegHardDrive /></ListItemIcon>
              <ListItemText primary="Time Log Report" />
            </ListItem> */}
            {/* <ListItem button onClick={() => handleClick('/finance-report')}
              sx={{
                backgroundColor: activeItem === '/finance-report' ? '#3b82f6' : 'transparent',
                color: activeItem === '/finance-report' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/finance-report' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaRegRectangleList /></ListItemIcon>
              <ListItemText primary="Finance Report" />
            </ListItem> */}
            {/* <ListItem button onClick={() => handleClick('/income-expense')}
              sx={{
                backgroundColor: activeItem === '/income-expense' ? '#3b82f6' : 'transparent',
                color: activeItem === '/income-expense' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/income-expense' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaRegWindowRestore /></ListItemIcon>
              <ListItemText primary="Income v/s Expense" />
            </ListItem> */}
            <ListItem button onClick={() => handleClick('/leave-report')}
              sx={{
                backgroundColor: activeItem === '/leave-report' ? '#3b82f6' : 'transparent',
                color: activeItem === '/leave-report' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/leave-report' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaXmarksLines /></ListItemIcon> */}
              <ListItemText primary="Leave Report" />
            </ListItem>
            <ListItem button onClick={() => handleClick('/attendance-report')}
              sx={{
                backgroundColor: activeItem === '/attendance-report' ? '#3b82f6' : 'transparent',
                color: activeItem === '/attendance-report' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/attendance-report' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaWpforms /></ListItemIcon> */}
              <ListItemText primary="Attendance Report" />
            </ListItem>
            <ListItem button onClick={() => handleClick('/expense-report')}
              sx={{
                backgroundColor: activeItem === '/expense-report' ? '#3b82f6' : 'transparent',
                color: activeItem === '/expense-report' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/expense-report' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              {/* <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaThinkPeaks /></ListItemIcon> */}
              <ListItemText primary="Expense Report" />
            </ListItem>
            {/* <ListItem button onClick={() => handleClick('/deal-report')}
              sx={{
                backgroundColor: activeItem === '/deal-report' ? '#3b82f6' : 'transparent',
                color: activeItem === '/deal-report' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/deal-report' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaRegWindowMaximize /></ListItemIcon>
              <ListItemText primary="Deal Report" />
            </ListItem> */}
            {/* <ListItem button onClick={() => handleClick('/sales-report')}
              sx={{
                backgroundColor: activeItem === '/sales-report' ? '#3b82f6' : 'transparent',
                color: activeItem === '/sales-report' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/sales-report' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaTableList />
              </ListItemIcon>
              <ListItemText primary="Sales Report" />
            </ListItem> */}




          </List>
</Collapse>












        {/* <ListItem button onClick={() => handleClick('/order')}
          sx={{
            backgroundColor: activeItem === '/order' ? '#3b82f6' : 'transparent',
            color: activeItem === '/order' ? '#fff' : 'inherit',
            borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/order' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
          }}>
          <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> 
    <FaWpforms />
    </ListItemIcon>
          <ListItemText primary="Orders" />
        </ListItem> */}




        {/* <ListItem button onClick={() => handleClick('/ticket')}
          sx={{
            backgroundColor: activeItem === '/ticket' ? '#3b82f6' : 'transparent',
            color: activeItem === '/ticket' ? '#fff' : 'inherit',
            borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/ticket' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
          }}>
          <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> 
          <FaJxl />
          </ListItemIcon>
          <ListItemText primary="Tickets" />
        </ListItem> */}




        <ListItem button onClick={() => handleClick('/settings')}
          sx={{
            backgroundColor: activeItem === '/settings' ? '#3b82f6' : 'transparent',
            color: activeItem === '/settings' ? '#fff' : 'inherit',
            borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/settings' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
          }}>
          <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}>
          <FaEllipsisVertical />
            </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
        <ListItem button onClick={() => handleClick('/message')}
          sx={{
            backgroundColor: activeItem === '/message' ? '#3b82f6' : 'transparent',
            color: activeItem === '/message' ? '#fff' : 'inherit',
            borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/message' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
          }}>
          <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> 
          <FaBookOpen />
          </ListItemIcon>
          <ListItemText primary="Messages" />
        </ListItem>
        <ListItem button onClick={() => handleClick('/asset')}
          sx={{
            backgroundColor: activeItem === '/asset' ? '#3b82f6' : 'transparent',
            color: activeItem === '/asset' ? '#fff' : 'inherit',
            borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/asset' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },




          }}>
          <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> 
          <FaSackDollar />
         </ListItemIcon>
          <ListItemText primary="Assets" />
        </ListItem>




        <ListItem button onClick={() => handleClick('/daily-update')}
          sx={{
            backgroundColor: activeItem === '/daily-update' ? '#3b82f6' : 'transparent',
            color: activeItem === '/daily-update' ? '#fff' : 'inherit',
            borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/daily-update' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },




          }}>
          <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> 
          <FaClipboardList />
         </ListItemIcon>
          <ListItemText primary="Daily Update" />
        </ListItem>
     








        {/* <ListItem button onClick={() => handleDropdownClick('letter')}>
  <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> <FaSquareEnvelope /> </ListItemIcon>
  <ListItemText primary="Letter" />
  {openDropdown === 'letter' ? <ExpandLess /> : <ChevronRightOutlinedIcon/>}
</ListItem>
<Collapse in={openDropdown === 'letter'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button onClick={() => handleClick('/generate')}
              sx={{
                backgroundColor: activeItem === '/generate' ? '#3b82f6' : 'transparent',
                color: activeItem === '/generate' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/generate' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> <FaUpload /> </ListItemIcon>
              <ListItemText primary="Generate" />
            </ListItem>
            <ListItem button onClick={() => handleClick('/template')}
              sx={{
                backgroundColor: activeItem === '/template' ? '#3b82f6' : 'transparent',
                color: activeItem === '/template' ? '#fff' : 'inherit',
                borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/template' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
                pl: 4
              }}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}> <FaTablet /> </ListItemIcon>
              <ListItemText primary="Templates" />
            </ListItem>
          </List>
        </Collapse>




        <ListItem button onClick={() => handleClick('/zoom-meeting')}
          sx={{
            backgroundColor: activeItem === '/zoom-meeting' ? '#3b82f6' : 'transparent',
            color: activeItem === '/zoom-meeting' ? '#fff' : 'inherit',
            borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/zoom-meeting' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
          }}>
          <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaComputer /> </ListItemIcon>
          <ListItemText primary="Zoom Meeting" />
        </ListItem>
        <ListItem button onClick={() => handleClick('/QR-Code')}
          sx={{
            backgroundColor: activeItem === '/QR-Code' ? '#3b82f6' : 'transparent',
            color: activeItem === '/QR-Code' ? '#fff' : 'inherit',
            borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/QR-Code' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
          }}>
          <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaArrowsSpin /> </ListItemIcon>
          <ListItemText primary="QR Code" />
        </ListItem> */}
        
      </List>
    </div>
  );








  const superAdminDrawer = (
    <div>
      <DrawerHeader sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography sx={{ alignSelf: 'center' }}>Myntask</Typography>
      </DrawerHeader>
      <Divider />
      <ListItem button onClick={() => handleClick('/SuperAdmin')}
        sx={{
          backgroundColor: activeItem === '/SuperAdmin' ? '#3b82f6' : 'transparent',
          color: activeItem === '/SuperAdmin' ? '#fff' : 'inherit',
          borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/SuperAdmin' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
        }}>
        <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaBarsProgress />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
      <ListItem button onClick={() => handleClick('/packages')}
        sx={{
          backgroundColor: activeItem === '/packages' ? '#3b82f6' : 'transparent',
          color: activeItem === '/package' ? '#fff' : 'inherit',
          borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/package' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
        }}>
        <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaBuffer /></ListItemIcon>
        <ListItemText primary="Packages" />
      </ListItem>
      <ListItem button onClick={() => handleClick('/companies')}
        sx={{
          backgroundColor: activeItem === '/companies' ? '#3b82f6' : 'transparent',
          color: activeItem === '/companies' ? '#fff' : 'inherit',
          borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/companies' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
        }}>
        <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaFax /></ListItemIcon>
        <ListItemText primary="Companies" />
      </ListItem>
      <ListItem button onClick={() => handleClick('/billing')}
        sx={{
          backgroundColor: activeItem === '/billing' ? '#3b82f6' : 'transparent',
          color: activeItem === '/billing' ? '#fff' : 'inherit',
          borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/billing' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
        }}>
        <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaFileContract /></ListItemIcon>
        <ListItemText primary="Billing" />
      </ListItem>
      <ListItem button onClick={() => handleClick('/admin-FAQ')}
        sx={{
          backgroundColor: activeItem === '/admin-FAQ' ? '#3b82f6' : 'transparent',
          color: activeItem === '/admin-FAQ' ? '#fff' : 'inherit',
          borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/admin-FAQ' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
        }}>
        <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaFoursquare /></ListItemIcon>
        <ListItemText primary="Admin FAQ" />
      </ListItem>
      <ListItem button onClick={() => handleClick('/SuperAdminList')}
        sx={{
          backgroundColor: activeItem === '/SuperAdminList' ? '#3b82f6' : 'transparent',
          color: activeItem === '/SuperAdminList' ? '#fff' : 'inherit',
          borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/SuperAdminList' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
        }}>
        <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaHospitalUser /></ListItemIcon>
        <ListItemText primary="Super Admin" />
      </ListItem>
      <ListItem button onClick={() => handleClick('/offline-request')}
        sx={{
          backgroundColor: activeItem === '/offline-request' ? '#3b82f6' : 'transparent',
          color: activeItem === '/offline-request' ? '#fff' : 'inherit',
          borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/offline-request' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
        }}>
        <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaHouseCircleExclamation /></ListItemIcon>
        <ListItemText primary="Offline Request" />
      </ListItem>
      <ListItem button onClick={() => handleClick('/support-ticket')}
        sx={{
          backgroundColor: activeItem === '/support-ticket' ? '#3b82f6' : 'transparent',
          color: activeItem === '/support-ticket' ? '#fff' : 'inherit',
          borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/support-ticket' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
        }}>
        <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaIndent /></ListItemIcon>
        <ListItemText primary="Support Ticket" />
      </ListItem>
      <ListItem button onClick={() => handleClick('/front-settings')}
        sx={{
          backgroundColor: activeItem === '/front-settings' ? '#3b82f6' : 'transparent',
          color: activeItem === '/front-settings' ? '#fff' : 'inherit',
          borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/front-settings' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
        }}>
        <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaList />
        </ListItemIcon>
        <ListItemText primary="Front Settings" />
      </ListItem>
      <ListItem button onClick={() => handleClick('/SuperSetting')}
        sx={{
          backgroundColor: activeItem === '/SuperSetting' ? '#3b82f6' : 'transparent',
          color: activeItem === '/SuperSetting' ? '#fff' : 'inherit',
          borderRadius: '10px',padding:'5px 8px',marginBottom:'5px',
    '& .MuiListItemIcon-root': {
      color: activeItem === '/SuperSetting' ? '#fff' : 'inherit', // Change icon color when active
    },
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: '#fff',
      '& .MuiListItemIcon-root': {
        color: '#fff', // Change icon color on hover
      }
    },
        }}>
        <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}><FaSliders />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItem>
    </div>
  );








  const renderDrawer = () => {
    if (userRole === '1') {
      return superAdminDrawer;
    } else {
      return drawer;
    }
  };








  return (
    <Box sx={{ display: 'flex' ,minHeight:'100vh'}}>
       <ToastContainer />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
       <Drawer
  variant="temporary"
  open={mobileOpen}
  onClose={handleDrawerToggle}
  ModalProps={{
    keepMounted: true,  // Keeps the Drawer mounted for better performance on mobile
  }}
  sx={{
    display: { xs: 'block', sm: 'none' },
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: drawerWidth,
      mt: 0,
      backgroundColor: '#fff',
      display: 'flex',  // Use flexbox layout
      flexDirection: 'column',  // Stack content vertically
      justifyContent: 'space-between',  // Separate content and footer
      p: 0,  // Remove any additional padding from the Drawer
    },
  }}
>
  {/* Scrollable content */}
  <Box  sx={{
      flex: 1,
      overflowY: 'auto',
      p: '15px',
      '::-webkit-scrollbar': {
        width: '2px', // Width of the scrollbar
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: '#888', // Color of the scrollbar handle
        borderRadius: '8px', // Rounded corners
      },
      '::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#333', // Darker color when hovered
      },
      '::-webkit-scrollbar-track': {
        backgroundColor: '#333', // Color of the scrollbar track
      },
    }}>
    {renderDrawer()}
  </Box>




  {/* Fixed footer */}
  <Box
    sx={{
      p: 2,  // Padding for the footer
      backgroundColor: '#fff',  // Optional background color for the footer
      textAlign: 'center',
    }}
  >
    <Typography variant="body2">Footer Content</Typography>
  </Box>
</Drawer>








<Drawer
  variant="permanent"
  sx={{
    display: { xs: 'none', sm: 'block' },
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: drawerWidth,
      mt: 0,
      backgroundColor: '#fff',
      display: 'flex',  // Use flex layout
      flexDirection: 'column',  // Column layout for drawer
      justifyContent: 'space-between',  // Space between content and footer
      p: '5px',  // Remove padding
    },
  }}
  open
>
  {/* Main content (scrollable) */}
  <Box sx={{ flex: 1, overflowY: 'auto', }}>
    {renderDrawer()}
  </Box>




  {/* Footer (fixed at the bottom) */}
  <Box
    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
  >
    {/* Add the image in the footer */}
    
     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>




<div style={{ textAlign: 'left', marginRight: '6px' }}>
  <h2 style={{ margin: 0, fontSize: '12px' }}>
    {(userProfile.company_name || '').split(' ').slice(0, 3).join(' ')}
  </h2>
  <p style={{ margin: '5px 0px', fontSize: '12px', color: '#666' }}>
    <span style={{ display: 'inline-block', width: '8px', height: '8px', backgroundColor: 'green', borderRadius: '50%', marginRight: '6px' }}></span>
    {userProfile.user_name}
  </p>
</div>
{userProfile.company_logo && (
  <img
    style={{ width: '40px', height: '30px', objectFit: 'contain' }}
    src={userProfile.company_logo}
    alt={userProfile.company_name}
  />
)}




</div>
    
  </Box>
</Drawer>








      </Box>
      <Box
        component="main"
        sx={{
          flex: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          p: 1, // Add padding
          overflowY: 'auto', // Enable scrolling
          height: '100%', // Full viewport height for scrolling
          minHeight:'100vh'
        }}
      >
       <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', p: 0 }}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, display: { sm: 'block', xs: 'block' } }}
      >
        <MenuIcon />
      </IconButton>
      <div style={{ display: 'flex', alignItems: 'center' }}>
      <FiPlusCircle
        style={{ cursor: 'pointer', fontSize: '20px', marginRight: '10px' }}
        onClick={handleMenuOpen}
      />
       <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={() => { handleMenuClose(); handleClickMenu('/addEmployee'); }}>Add Employee</MenuItem>
        <MenuItem onClick={() => { handleMenuClose(); handleClickMenu('/addProject'); }}>Add Project</MenuItem>
        <MenuItem onClick={() => { handleMenuClose(); handleClickMenu('/new-task'); }}>Add Task</MenuItem>
        <MenuItem onClick={() => { handleMenuClose(); handleClickMenu('/addClient'); }}>Add Client</MenuItem>
      </Menu>




        <div style={containerStyle}>
          <div style={timeStyle}>{currentTime}</div>
          <div style={dayStyle}>{currentDay}</div>
        </div>

        {/* <button
      style={{
        backgroundColor: '#FFF', 
        color: isClockedIn ? 'red' : 'green', 
        padding: '9px 11px',
        fontSize: '14px',
        fontWeight: '700',
        borderRadius: '11px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        border: 'none',
        cursor: 'pointer',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        transition: 'all 0.3s ease',
      }}
      onClick={() => {
        if (!loading) {
          if (isClockedIn) {
            getUserLocationAndClockOut(); 
          } else {
            getUserLocationAndSaveAttendance(setIsClockedIn); 
          }
        }
      }} 
      onMouseOver={(e) => (e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.3)')} 
      onMouseOut={(e) => (e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)')} 
    >
      <ExitToAppIcon style={{ fontSize: '20px' }} />
      {isClockedIn ? 'Clock Out' : 'Clock In'} 
      {loading && <CircularProgress size={20} style={{ marginLeft: '10px' }} />} 
    </button> */}


        <IconButton color="inherit" onClick={handleLogout}>
          <FaPowerOff />
        </IconButton>
  
        <IconButton color="inherit" onClick={showModal}>
        <Badge badgeContent={notificationCount} color="error">
  <NotificationsIcon />
</Badge>

      </IconButton>


      {/* Modal for Notifications */}
      <Modal
        open={isModalOpen}
        onClose={hideModal}
        aria-labelledby="notification-modal-title"
        aria-describedby="notification-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            padding: '20px',
            border: '2px solid black',
            boxShadow: '24px 24px 16px rgba(0,0,0,0.3)',
            width: '600px',
            borderRadius: '10px',
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <Typography
            id="notification-modal-title"
            variant="h6"
            component="h2"
            style={{ fontWeight: 'bold', marginBottom: '16px' }}
          >
            Notifications
          </Typography>
          {error ? (
            <Typography color="error">{error}</Typography>
          ) : (
            <Box>
              {notifications.length > 0 ? (
                notifications.map((notification) => (
                  <Box
                    key={notification.notification_id}
                    style={{
                      borderBottom: '1px solid #ddd',
                      padding: '10px 0',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={notification.user_image}
                      alt="User Avatar"
                      style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        marginRight: '10px',
                      }}
                    />
                    <div>
                      <Typography variant="body1">
                        <strong>{notification.heading}</strong>
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {notification.message}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {notification.time_ago}
                      </Typography>
                    </div>
                  </Box>
                ))
              ) : (
                <Typography>No notifications found.</Typography>
              )}
            </Box>
          )}
        </div>
      </Modal>


        <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
        <div
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => handleClick('/profileData')}
        >
          <img
            src={userProfile.image || '/default-avatar.png'}
            alt="User Avatar"
            style={{ width: '40px', height: '40px', objectFit: 'cover', borderRadius: '50%' }}
          />
          <div style={{ marginLeft: '10px' }}>
            <span style={{ fontSize: '14px', fontWeight: '700' }}>{userProfile.user_name || 'User Name'}</span>
            <p style={{ margin: '0px' }}>{userProfile.email || 'email'}</p>
          </div>
        </div>
      </div>
    </Toolbar>
  
        {children}
      </Box>




    </Box>
  );
};












const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '12px',
  color: '#333',
  padding: '10px',
};




const timeStyle = {
  fontSize: '14px',
  fontWeight: 'bold',
};




const dayStyle = {
  fontSize: '12px',
};












export default Layout;

     
























 
