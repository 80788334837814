

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, } from 'recharts';
import './Dashboard.css';
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { FaCalendar, FaMailBulk, FaBirthdayCake, FaUniversity, FaUserPlus, FaUsersSlash, FaCalendarAlt, FaBell, FaClipboardList, FaTicketAlt, FaFileContract, FaThumbsUp, FaClock, FaCalendarCheck, FaGift } from 'react-icons/fa';
import vector from '../assets/vector.jpeg';
import SettingsIcon from '@mui/icons-material/Settings';
import { Button, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DailyUpdate from '../Components/DailyUpdate';


import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ClockIn from '../Components/ClockIn';


const Dashboard = () => {
 const [userProfile, setUserProfile] = useState({});
 const [taskCount, setTaskCount] = useState({ overdue: 0, pending: 0 });
 const [projectCount, setProjectCount] = useState({ in_progress: 0, overdue: 0 });
 const [leaveToday, setLeaveToday] = useState([]);
 const [probationdata, setProbationData] = useState([]);
 const [birthdays, setBirthdays] = useState([]);
 const [workFromHomeToday, setWorkFromHomeToday] = useState('');
 const [todayJoinings, setTodayJoinings] = useState('');
 const [taskList, setTaskList] = useState([]);
 const [noticePeriodDuration, setNoticePeriodDuration] = useState('');
 const [employeeAppreciations, setEmployeeAppreciations] = useState('');
 const [contractDate, setContractDate] = useState('');
 const [notice, setNotice] = useState([]);
 const [expense, setExpense] = useState([]);
 const [openDailyUpdate, setOpenDailyUpdate] = useState(false);
 const [taskListForCalendar, setTaskListForCalendar] = useState([]); // Unique state for task list
const [isCalendarVisible, setIsCalendarVisible] = useState(true); // Unique state for calendar visibility
const [selectedCalendarDate, setSelectedCalendarDate] = useState(new Date()); // Unique state for selected date


const navigate = useNavigate();


 // const expenseColors = ['#1E3A8A', '#3B82F6', '#93C5FD']; // Blue color family


 const expenseColors = ['#4BC0C0', '#FF6384', '#36A2EB', '#FF9F40']; // Green, Red, Blue, Orange
 console.log('notices are', notice);
 const expenseData = [
   { name: 'Paid', value: 4508598.2 },  // Replace with actual data or use stored data
   { name: 'Rejected', value: 152438 },
   { name: 'Pending', value: 375050.5 },
 ];
 const [open, setOpen] = useState(false);
 const [visibleCards, setVisibleCards] = useState({
   myData: true,
   projects: true,
   tasks: true,
   wfhToday: true,
   todayJoinings: true,
   taskList: true,
   overview: true,
   calendar: true,
   notice: true,
   probation: true,
   birthday: true,
   tickets: true,
   expense: true,
   noticeperiod: true,
   internship: true,
   workAnniversary: true,
   appreciation: true,
   contractDate: true
 });


 // Handle opening the modal
 const handleClickOpen = () => {
   setOpen(true);
 };
 // Handle closing the modal
 const handleClose = () => {
   setOpen(false);
 };
 useEffect(() => {
   const storedCards = JSON.parse(localStorage.getItem('visibleCards'));
   if (storedCards) {
     setVisibleCards(storedCards);
   }
 }, []);


 // Function to handle checkbox changes
 const handleCheckboxChange = (event) => {
   const { name, checked } = event.target;
   const updatedCards = { ...visibleCards, [name]: checked };
   setVisibleCards(updatedCards);
   localStorage.setItem('visibleCards', JSON.stringify(updatedCards)); // Save to localStorage
 };
 useEffect(() => {
   window.history.pushState(null, document.title, window.location.href);
   const handlePopState = (event) => {
     window.history.pushState(null, document.title, window.location.href);
   };
   window.addEventListener('popstate', handlePopState);
   const storedProfile = JSON.parse(localStorage.getItem('userProfile')) || {};
   const storedTaskCount = JSON.parse(localStorage.getItem('taskCount')) || { overdue: 0, pending: 0 };
   const storedProjectCount = JSON.parse(localStorage.getItem('projectCount')) || { in_progress: 0, overdue: 0 };
   const storedLeaveToday = JSON.parse(localStorage.getItem('leaveToday')) || [];
   const storedProbationData = JSON.parse(localStorage.getItem('probation')) || [];
   const storedBirthdays = JSON.parse(localStorage.getItem('birthdays')) || [];
   const storedWorkFromHomeToday = localStorage.getItem('work_from_home_today') || '';
   const storedTodayJoinings = localStorage.getItem('today_joinings') || '';
   const storedTaskList = JSON.parse(localStorage.getItem('taskList')) || [];
   const storedNoticePeriodDuration = localStorage.getItem('notice_period_duration') || '';
   const storedEmployeeAppreciations = localStorage.getItem('employee_appreciations') || '';
   const storedContractDate = localStorage.getItem('contract_date') || '';
   const storedNotice = JSON.parse(localStorage.getItem('notice')) || [];
   const storedExpense = JSON.parse(localStorage.getItem('expenses')) || [];
   setUserProfile(storedProfile);
   setTaskCount(storedTaskCount);
   setProjectCount(storedProjectCount);
   setLeaveToday(storedLeaveToday);
   setProbationData(storedProbationData);
   setBirthdays(storedBirthdays);
   setWorkFromHomeToday(storedWorkFromHomeToday);
   setTodayJoinings(storedTodayJoinings);
   setTaskList(storedTaskList);
   setNoticePeriodDuration(storedNoticePeriodDuration);
   setEmployeeAppreciations(storedEmployeeAppreciations);
   setContractDate(storedContractDate);
   setNotice(storedNotice);
   setExpense(storedExpense);
   return () => {
     window.removeEventListener('popstate', handlePopState);
   };
 }, [navigate]);
 //  const handleClockInOut = () => {
 //    setIsClockedIn(!isClockedIn);
 //  };
 console.log('expenses are', expense);


 useEffect(() => {
   const storedProfile = JSON.parse(localStorage.getItem('userProfile')) || {};
   setUserProfile(storedProfile);
   // Other localStorage state initializations...
 }, [navigate]);
 // Update: Speech synthesis to greet the user with their name
 useEffect(() => {
   const speakWelcomeMessage = () => {
     // Check if the welcome message has already been played
     const welcomeMessagePlayed = localStorage.getItem('welcomeMessagePlayed');
     if ('speechSynthesis' in window && userProfile.user_name && !welcomeMessagePlayed) {
       // Create a new instance of SpeechSynthesisUtterance
       const messageText = `Welcome to the minntask, ${userProfile.user_name}`; // Use username if available
       const message = new SpeechSynthesisUtterance(messageText);
       // Set the voice properties (optional)
       message.lang = 'en-IN';  // Language (English)
       message.pitch = 0.8;     // Pitch level
       message.rate = 1;        // Speed rate
       message.volume = 1;      // Volume level (0 to 1)
       // Speak the message
       window.speechSynthesis.speak(message);
       // Set flag in localStorage to prevent the message from playing again
       localStorage.setItem('welcomeMessagePlayed', 'true');
     } else {
       console.log('Speech Synthesis is not supported in this browser or the welcome message has already been played.');
     }
   };
   // Trigger the speech synthesis on component load if user_name is available
   speakWelcomeMessage();
 }, [userProfile.user_name]); // Run the effect when userProfile.user_name changes




 const handleTaskClick = () => {
   navigate('/task');
 };




 const handleClickOpenDailyUpdate = () => {
   setOpenDailyUpdate(true);
 };




 // Handle closing the daily update modal
 const handleCloseDailyUpdate = () => {
   setOpenDailyUpdate(false);
 };


 const handleProjectClick = () => {
   navigate('/project');
 };
 const handleMyDataClick = () => {
   navigate('/profileData');
 };
 const data = [
   { name: 'Tasks Overdue', value: taskCount.overdue },
   { name: 'Pending', value: taskCount.pending },
   { name: 'Projects In Progress', value: projectCount.in_progress },
   { name: 'Projects Overdue', value: projectCount.overdue },
   { name: 'On leave', value: leaveToday.length },
   { name: 'Probation', value: probationdata.length },
 ];
 const COLORS = [
   '#FF6384', // Red
   '#36A2EB', // Blue
   '#FFCE56', // Yellow
   '#4BC0C0', // Teal
   '#9966FF', // Purple
   '#FF9F40', // Orange
 ];
 const statusColors = {
   Incomplete: 'red',
   'To Do': 'orange',
   Doing: 'blue',
   Completed: 'green',
 };


 const handleProfileClick = (userId) => {
   navigate(`/profile/${userId}`);
 };


  // Simulate fetching data for tasks
  useEffect(() => {
    const storedTaskList = JSON.parse(localStorage.getItem('taskListForCalendar')) || [];
    setTaskListForCalendar(storedTaskList);
  }, []);
 
 
  // Handle date change
  const handleCalendarDateChange = (date) => {
    setSelectedCalendarDate(date);
  };
 
 
  // Get tasks for the selected date
  const getTasksForCalendar = (date) => {
    return taskListForCalendar.filter((task) => {
      const taskDate = new Date(task.due_date).toDateString();
      return new Date(date).toDateString() === taskDate;
    });
  };


  const formatTasksForCalendar = (taskList) => {
    return taskList
      .filter(task => task.due_date !== null) // Only include tasks with a due date
      .map(task => ({
        title: task.heading,
        start: new Date(task.due_date).toISOString(), // Ensure proper ISO format
        allDay: true, // Treat tasks as all-day events
      }));
  };


  const localizer = momentLocalizer(moment);


  const events = formatTasksForCalendar(taskList); // Format the task list for the calendar
 
 
 return (
   <div className="dashboard-container">
     <div style={{
       display: 'flex',
       justifyContent: 'flex-end', // Aligns children to the end of the container
       alignItems: 'center',       // Vertically center items within the container
       padding: '5px',            // Optional padding for spacing
       marginBottom:'10px'
     }}>
       {/* Daily Update Modal Component */}
       <Dialog open={openDailyUpdate} onClose={handleCloseDailyUpdate}>
         <DialogTitle>Daily Update</DialogTitle>
         <DialogContent>
           {/* Render User ID and a form here */}
           <p>User ID: {userProfile.user_id}</p>
           <form>
             <textarea
               placeholder="Write your daily update here..."
               style={{
                 width: '100%',
                 height: '100px',
                 padding: '10px',
                 fontSize: '16px',
                 marginTop: '10px',
               }}
             />
           </form>
         </DialogContent>
         <DialogActions>
           <Button onClick={handleCloseDailyUpdate} color="primary">
             Close
           </Button>
           <Button onClick={handleCloseDailyUpdate} color="primary">
             Submit
           </Button>
         </DialogActions>
       </Dialog>
       <ClockIn/>
       <Button
         variant="contained"
         color="primary"
         onClick={handleClickOpenDailyUpdate}
         sx={{
           backgroundColor: 'white',
           color: 'black',
           padding: '4px 10px',
           fontSize: '12px',
           border: '1px solid #ccc',
           boxShadow: 'none',
           '&:hover': {
             backgroundColor: '#f0f0f0',
             boxShadow: 'none',
           },
         }}
       >
         + Daily Update
       </Button>




       {/* <IconButton color="inherit" onClick={handleClickOpen}>
         <SettingsIcon />
       </IconButton> */}
     </div>
     
      <DailyUpdate
       open={openDailyUpdate}
       handleClose={handleCloseDailyUpdate}
       userProfile={userProfile}
     />
   
     <div className="dashboard-cards-container">
       {visibleCards.myData && (
         <div className="dashboard-card" onClick={handleMyDataClick}>
           <div style={{ display: 'flex', alignItems: 'center' }}>
             <div className="dashboard-image-background">
               <img
                 src={userProfile.image}
                 alt="User"
                 style={{ width: '40px', height: '40px', borderRadius: '50%', border: '1px solid #000' }}
               />
             </div>
             <h3 style={{ fontSize: '0.99rem', marginLeft: 10 }}>{userProfile.user_name}</h3>
           </div>
           <div className="dashboard-task-info">
             <p className='dashboard-countlabel'>My tasks: <span className="dashboard-count">{userProfile.open_task}</span></p>
             <p className='dashboard-countlabel'>My Projects: <span className="dashboard-count">{userProfile.projects}</span></p>
           </div>
         </div>
       )}
       {visibleCards.projects && (
         <div className="dashboard-card" onClick={handleProjectClick}>
           <div style={{ display: 'flex', alignItems: 'center' }}>
             <div className="dashboard-icon dashboard-icon-background">
               <FaMailBulk style={{ color: "#FFFFFF" }}/>
             </div>
             <h3 style={{ fontSize: '0.99rem', marginLeft: 10 }}>Projects</h3>
           </div>
           <div className="dashboard-project-info">
             <p className='dashboard-countlabel'>In Progress: <span className="dashboard-count">{projectCount.in_progress}</span></p>
             <p className='dashboard-countlabel'>Overdue: <span className="dashboard-count" style={{ color: 'red' }}>{projectCount.overdue}</span></p>
           </div>
         </div>
       )}
       {visibleCards.tasks && (
         <div className="dashboard-card" onClick={handleTaskClick}>
           <div style={{ display: 'flex', alignItems: 'center' }}>
             <div className="dashboard-icon dashboard-icon-background">
               <FaCalendar style={{ color: "#FFFFFF" }}/>
             </div>
             <h3 style={{ fontSize: '0.99rem', marginLeft: 10 }}>Tasks</h3>
           </div>
           <div className="dashboard-task-info">
             <p className='dashboard-countlabel'>Overdue: <span className="dashboard-count" style={{ color: 'red' }}>{taskCount.overdue}</span></p>
             <p className='dashboard-countlabel'>Pending: <span className="dashboard-count" style={{ color: 'orange' }}>{taskCount.pending}</span></p>
           </div>
         </div>
       )}
       {visibleCards.wfhToday && (
         <div className="dashboard-card">
           <div style={{ display: 'flex', alignItems: 'center' }}>
             <div className="dashboard-icon dashboard-icon-background">
               <FaUniversity style={{ color: "#FFFFFF" }}/>
             </div>
             <h3 style={{ fontSize: '0.99rem', marginLeft: 10 }}>WFH Today</h3>
           </div>
           {workFromHomeToday ? <p className='dashboard-countlabel'>{workFromHomeToday}</p> : <p className='dashboard-countlabel'>No data available</p>}
         </div>
       )}
       {visibleCards.todayJoinings && (
         <div className="dashboard-card">
           <div style={{ display: 'flex', alignItems: 'center' }}>
             <div className="dashboard-icon dashboard-icon-background">
               <FaUserPlus style={{ color: "#FFFFFF" }} />
             </div>
             <h3 style={{ fontSize: '0.99rem', marginLeft: 10 }}>Today's Joinings</h3>
           </div>
           {todayJoinings ? <p className='dashboard-countlabel'>{todayJoinings}</p> : <p className='dashboard-countlabel'>No data available</p>}
         </div>
       )}
     </div>
     <div className="dashboard-flex-row">
       {visibleCards.taskList && (
         <div className="dashboard-first-container" >
           <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
             <div style={{ display: 'flex', alignItems: 'center' }}>
               <div className="dashboard-icon dashboard-icon-background">
                 <FaUsersSlash style={{ color: "#FFFFFF" }}/>
               </div>
               <h3>Task List</h3>
             </div>
             <button
               onClick={() => navigate('/task')}
               style={{
                 background: 'none',
                 border: 'none',
                 color: '#007bff',
                 cursor: 'pointer',
                 fontSize: '14px',
                 textDecoration: 'underline',
               }}
             >
               View Detail
             </button>
           </div>
           {taskList.length > 0 ? (
             <table className="dashboard-task-table">
               <thead className="dashboard-task-table thead">
                 <tr >
                 <th className="dashboard-task-table th" style={{maxWidth:'100px'}}>Code</th>
                   <th className="dashboard-task-table th">Heading</th>
                   <th className="dashboard-task-table th">Status</th>
                   <th className="dashboard-task-table th">Due Date</th>
                 </tr>
               </thead>
               <tbody >
                 {taskList.slice(0, 5).map((task, index) => (
                   <tr key={index} style={{maxHeight:'30px'}}>


            <td style={{maxWidth:'100px'}}>{task.task_id}</td>
               <td >{task.heading}</td>
                     <td
                     >
                       <div className="status-container">
                       <span
                         style={{
                           width: '10px',
                           height: '10px',
                           borderRadius: '50%',
                           backgroundColor: statusColors[task.status], // fallback color
                           display: 'inline-block',
                         }}
                       ></span>
                   <span>{task.status}</span>
                 </div>
                     </td>


                     <td className="dashboard-task-td">
                       {new Date(task.due_date).toLocaleDateString('en-US', {
                         year: 'numeric',
                         month: 'numeric',
                         day: 'numeric',
                       })}
                     </td>
                   </tr>
                 ))}
               </tbody>
             </table>
           ) : (
             <p className='dashboard-noData'>--/ No Record Found /--</p>
           )}
         </div>
       )}


       {visibleCards.overview && (
         <div className="dashboard-first-container" >
           <ResponsiveContainer width="100%" height={350}>
             <PieChart>
               <Pie
                 data={data}
                 dataKey="value"
                 nameKey="name"
                 cx="50%"
                 cy="50%"
                 outerRadius={130}  // Increased outer radius
                 innerRadius={80}   // Increased inner radius
                 fill="#8884d8"
                 label={({ name, value }) => `${name}: ${value}`}
                 labelLine={false}
                 paddingAngle={5}
                 stroke="#fff"
                 strokeWidth={2}
                 style={{ filter: 'drop-shadow(0 0 10px rgba(0,0,0,0.1))', margin: '0px' }}
               >
                 {data.map((entry, index) => (
                   <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                 ))}
               </Pie>
               <Tooltip
                 formatter={(value, name) => [`${value}`, `${name}`]}
                 contentStyle={{
                   fontSize: '0.75rem',
                   padding: '0px',
                   maxWidth: '80px',
                   whiteSpace: 'normal',
                 }}
                 itemStyle={{ padding: '0px' }}
               />
             </PieChart>
           </ResponsiveContainer>


         </div>
       )}
       {visibleCards.expense && (
         <div className="dashboard-first-container">
           <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
             <div className="dashboard-icon dashboard-icon-background">
               <FaMoneyBillTrendUp style={{ color: "#FFFFFF" }}/>
             </div>
             <h3>Expense Detail</h3>
           </div>








           <div style={{ marginTop: '10px', position: 'relative' }}>
             {expense.length > 0 ? (
               <ResponsiveContainer width="100%" height={300}>
                 <PieChart>
                   <Pie
                     data={expense.map(item => ({ name: item.status, value: item.total_price }))} // Transform the data here
                     dataKey="value"
                     nameKey="name"
                     cx="40%"
                     cy="80%" // Positioned at the very bottom of the container
                     startAngle={180} // Starts from the bottom
                     endAngle={0} // Ends at the top
                     innerRadius={70}
                     outerRadius={150}
                     fill="#8884d8"
                     label={({ name, value }) => `${name}: ${value.toLocaleString()}`}
                     labelLine={false}
                     stroke="#fff"
                     strokeWidth={2}
                   >
                     {expense.map((entry, index) => (
                       <Cell key={`cell-${index}`} fill={expenseColors[index % expenseColors.length]} />
                     ))}
                   </Pie>
                   <Tooltip />
                 </PieChart>
               </ResponsiveContainer>
             ) : (
               <p className='dashboard-noData'>--/ No Record Found /--</p>
             )}
           </div>
         </div>
       )}


       {/* Notices Container */}
       {visibleCards.notice && (
         <div className="dashboard-first-container">
           <div
             style={{
               flexDirection: 'row',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'space-between',
               marginBottom: '10px'
             }}
           >
             <div style={{ display: 'flex', alignItems: 'center' }}>
               <div className="dashboard-icon dashboard-icon-background">


                 <FaBell style={{ color: "#FFFFFF" }}/>
               </div>
               <h3>Notices</h3>
             </div>
           </div>
           {notice.length > 0 ? (
             <ul>
               {notice.map((notice, index) => (
                 <li key={index} style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between' }}>
                   
                   <div>
                     <span >{notice.heading}</span>
                   </div>
                   <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '3px',
                      marginLeft: '15px',
                    }}
                  >
                    <FaCalendarAlt style={{ marginRight: '5px'}} />
                     {notice.date}
                   </div>
                 </li>
               ))}
             </ul>
           ) : (
             <p className='dashboard-noData'>--/ No Record Found /--.</p>
           )}
         </div>
       )}
       {/* Probation Data Container */}
       {visibleCards.probation && (
         <div className="dashboard-first-container">
           <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
             <div className="dashboard-icon dashboard-icon-background">
               <FaClipboardList style={{ color: "#FFFFFF" }}/>
             </div>
             <h3>Probation Data</h3>
           </div>
           {probationdata.length > 0 ? (
             <ul>
               {probationdata.map((item, index) => (
                 <li key={index} style={{ display: 'flex', alignItems: 'center' }}>
                   <img
                     src={
                       item.image && item.image !== 'https://api.myntask.com/assets/user-uploads/avatar/null'
                         ? item.image
                         : '/default-avatar.png' // Ensure you have a default avatar image
                     }
                     alt={item.name}
                     className="dashboard-probation-avatar"
                   />
                   <div style={{ flex: 1 }}>
                     <span
                       style={{ marginRight: '5px', cursor: 'pointer', color: '#000', fontWeight: '600' }}
                       onClick={() => handleProfileClick(item.user_id)}
                     >
                       {item.name}
                     </span>
                     <span>({item.department})</span>
                   </div>
                   <div
                     style={{
                       flexDirection: 'row',
                       display: 'flex',
                       alignItems: 'center',
                       padding: '3px',
                       marginLeft: '15px',
                     }}
                   >
                     <FaCalendarAlt style={{ marginRight: '5px'}} />
                     {new Date(item.probation_end_date).toLocaleDateString()}
                   </div>
                 </li>
               ))}
             </ul>
           ) : (
             <p className='dashboard-noData'>--/ No Record Found /--</p>
           )}
         </div>
       )}
       {/* Tickets Container */}
       {visibleCards.tickets && (
         <div className="dashboard-first-container">
           <div
             style={{
               flexDirection: 'row',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'space-between',
               marginBottom: '10px'
             }}
           >
             <div style={{ display: 'flex', alignItems: 'center' }}>
               <div className="dashboard-icon dashboard-icon-background">
                 {/* Replace with appropriate tickets icon */}
                 <FaTicketAlt style={{ color: "#FFFFFF" }}/>
               </div>
               <h3>Tickets</h3>
             </div>
           </div>
           {/* Tickets Content */}
           <p className='dashboard-noData'>--/ No Record Found /--.</p>
         </div>
       )}


       {/* My Calendar Container */}
  {isCalendarVisible && (
  <div className="dashboard-first-container">
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '10px',
        width: '100%', // Full width for the header
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', alignSelf: 'center' }}>
        <div className="dashboard-icon dashboard-icon-background">
          <FaCalendarAlt style={{ color: '#FFFFFF' }} />
        </div>
        <h3>My Calendar</h3>
      </div>
    </div>


    {/* Calendar Component */}
 
<div className="dashboard-center-container">
  <Calendar
    localizer={localizer}  // Ensure you have a localizer set up
    events={events}  // The events to display in the calendar
    startAccessor="start"
    endAccessor="end"
    className="custom-calendar"  // Add custom calendar class
    selectable={true}  // Enable selecting dates
    onSelectEvent={(event) => handleTaskClick(event)}  // Trigger on task/event click
    onSelectSlot={handleCalendarDateChange}  // Handle date selection
  />
</div>




    {/* Render tasks for the selected date */}
   
  </div>
)}


       {visibleCards.birthday && (
         <div className="dashboard-first-container" >
           <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
             <div className="dashboard-icon dashboard-icon-background">
               <FaBirthdayCake style={{ color: "#FFFFFF" }}/>
             </div>
             <h3>Birthdays</h3>
           </div>
           {birthdays.length > 0 ? (
  <ul>
    {birthdays.map((birth, index) => (
      <li key={index} style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={
            birth.image && birth.image !== 'https://api.myntask.com/assets/user-uploads/avatar/null'
              ? birth.image
              : vector  // Assuming 'vector' is the default avatar image
          }
          alt={birth.name}
          className="dashboard-probation-avatar"
        />
        <div style={{ flex: 1 }}>
          <span
            style={{ marginRight: '5px', cursor: 'pointer', color: '#000', fontWeight: '600' }}
            onClick={() => handleProfileClick(birth.user_id)}
          >
            {birth.name}
          </span>
          <span>({birth.department})</span>
        </div>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            padding: '3px',
            marginLeft: '15px',
          }}
        >
          <FaBirthdayCake style={{ marginRight: '5px' }} />
          {new Date(birth.date_of_birth).toLocaleDateString()}
        </div>
      </li>
    ))}
  </ul>
) : (
  <p className="dashboard-noData">--/ No Record Found /--</p>
)}


         </div>
       )}
       {visibleCards.noticeperiod && (
         <div className="dashboard-first-container">
           <div
             style={{
               flexDirection: 'row',
               display: 'flex',
               alignItems: 'center',
               marginBottom: '10px'
             }}
           >
             <div className="dashboard-icon dashboard-icon-background">
               {/* Replace with appropriate icon */}
               <FaClock style={{ color: "#FFFFFF" }}/>
             </div>
             <h3>Notice Period Duration</h3>
           </div>
           {/* Display "--/ No Record Found /--" if noticePeriodDuration is empty */}
           <p className={noticePeriodDuration ? '' : 'dashboard-noData'}>
             {noticePeriodDuration || '--/ No Record Found /--.'}
           </p>
         </div>
       )}
       {/* Internship Date Container */}
       {visibleCards.internship && (
         <div className="dashboard-first-container">
           <div
             style={{
               flexDirection: 'row',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'space-between',
               marginBottom: '10px'
             }}
           >
             <div style={{ display: 'flex', alignItems: 'center' }}>
               <div className="dashboard-icon dashboard-icon-background">
                 {/* Replace with appropriate internship date icon */}
                 <FaCalendarCheck style={{ color: "#FFFFFF" }}/>
               </div>
               <h3>Internship Date</h3>
             </div>
           </div>
           {/* Display "--/ No Record Found /--" if data is empty */}
           <p className={'dashboard-noData'}>
             --/ No Record Found /--
           </p>
         </div>
       )}
       {visibleCards.workAnniversary && (
         <div className="dashboard-first-container">
           <div
             style={{
               flexDirection: 'row',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'space-between',
               marginBottom: '10px'
             }}
           >
             <div style={{ display: 'flex', alignItems: 'center' }}>
               <div className="dashboard-icon dashboard-icon-background">
                 {/* Replace with appropriate work anniversary icon */}
                 <FaGift style={{ color: "#FFFFFF" }}/>
               </div>
               <h3>Work Anniversary</h3>
             </div>
           </div>
           {/* Work Anniversary Content */}
           <p className={'dashboard-noData'}>
             --/ No Record Found /--
           </p>
         </div>
       )}
       {/* Employee Appreciations Container */}
       {visibleCards.appreciation && (
         <div className="dashboard-first-container">
           <div
             style={{
               flexDirection: 'row',
               display: 'flex',
               alignItems: 'center',
               marginBottom: '10px'
             }}
           >
             <div className="dashboard-icon dashboard-icon-background">
               {/* Replace with appropriate icon */}
               <FaThumbsUp style={{ color: "#FFFFFF" }}/>
             </div>
             <h3>Employee Appreciations</h3>
           </div>
           <p className={employeeAppreciations ? '' : 'dashboard-noData'}>
             {employeeAppreciations || '--/ No Record Found /--.'}
           </p>
         </div>
       )}
       {/* Contract Date Container */}
       {visibleCards.contractDate && (
         <div className="dashboard-first-container">
           <div
             style={{
               flexDirection: 'row',
               display: 'flex',
               alignItems: 'center',
               marginBottom: '10px'
             }}
           >
             <div className="dashboard-icon dashboard-icon-background">
               {/* Replace with appropriate icon */}
               <FaFileContract style={{ color: "#FFFFFF" }}/>
             </div>
             <h3>Contract Date</h3>
           </div>
           <p className={contractDate ? '' : 'dashboard-noData'}>
             {contractDate || '--/ No Record Found /--.'}
           </p>
         </div>
       )}
     </div>
   </div>
 );
};
export default Dashboard;




















































 






 









