import React, { useEffect, useState } from 'react';
import AddDealModal from '../Components/AddDealModal';




const Deals = () => {
  const [deals, setDeals] = useState([]);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');








  useEffect(() => {
    const fetchDealsList = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No token found');
        return;
      }




      try {
        const response = await fetch('https://api.myntask.com/leads/deals_list', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });




        if (!response.ok) {
          throw new Error('Failed to fetch deals');
        }




        const data = await response.json();
        setDeals(data);
      } catch (err) {
        console.error('Error fetching deals:', err);
        setError('Failed to load deals.');
      }
    };




    fetchDealsList();
  }, []);




  const handleAddDealClick = () => {
    setIsModalOpen(true);
  };




  const handleCloseModal = () => {
    setIsModalOpen(false);
  };




  const handleSaveDeal = (newDeal) => {
    setDeals((prevDeals) => [...prevDeals, newDeal]);
  };




  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };




  const filteredDeals = deals.filter(deal => {
    return Object.keys(deal).some(key =>
      String(deal[key]).toLowerCase().includes(searchQuery.toLowerCase())
    );
  });




  return (
    <div className="main-container">
    <div className="top-controls">
      <h1 className='title'>Deals
        <div className="underline"></div>
      </h1>
      <div className="buttons-row">
   
          <button className="color-button"  onClick={handleAddDealClick}>
            Add Deal
          </button>
     
      </div>
    </div>


    <div className="top-controls">
        <div className="filters-and-search">
       
          <input
            type="text"
            placeholder="Search by name or email..."
            value={searchQuery}
            onChange={handleSearch}
            className="searchbar"
          />
        </div>
      </div>
   
      {error && <p style={{ color: 'var(--accent-color-3)' }}>{error}</p>}
      {filteredDeals.length > 0 ? (
        <table >
          <thead>
            <tr>
              <th >Name</th>
              <th >Company Name</th>
              <th >Client Name</th>
              <th >Client Email</th>
              <th >Mobile</th>
              <th >Value</th>
              <th >Close Date</th>
              <th >Next Follow Up Date</th>
              <th >Stage</th>
              <th >Agent</th>
              <th >Deal Watcher</th>
            </tr>
          </thead>
          <tbody>
            {filteredDeals.map(deal => (
              <tr key={deal.id} >
                <td >{deal.name}</td>
                <td >{deal.company_name}</td>
                <td >{deal.client_name}</td>
                <td >{deal.client_email}</td>
                <td >{deal.mobile}</td>
                <td >{deal.value}</td>
                <td >{deal.close_date}</td>
                <td >{deal.next_follow_up_date}</td>
                <td >{deal.stage}</td>
                <td >
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <img src={deal.agentImage} alt={deal.agent} style={{ width: '30px', height: '30px', borderRadius: '50%', backgroundColor: 'var(--icon-background-color)' }} />
                    <span>{deal.agent || 'N/A'}</span>
                  </div>
                </td>
                <td >
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <img src={deal.dealWatcherImage} alt={deal.deal_watcher} style={{ width: '30px', height: '30px', borderRadius: '50%', backgroundColor: 'var(--icon-background-color)' }} />
                    <span>{deal.deal_watcher || 'N/A'}</span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No deals found for this lead.</p>
      )}




      {/* Use the AddDealModal component */}
      <AddDealModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSave={handleSaveDeal}
      />
    </div>
  );
};




export default Deals;








