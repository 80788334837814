import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


const PurchaseRequisition = () => {
  const [requisitions, setRequisitions] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [open, setOpen] = useState(false); // Modal open state
  const navigate = useNavigate();
  const token = localStorage.getItem('authToken'); // Retrieve the token from localStorage


  useEffect(() => {
    const fetchRequisitions = async () => {
      try {
        const response = await fetch('https://api.myntask.com/purchase_requisition/list', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`, // Add token to Authorization header
            'Content-Type': 'application/json',
          },
        });


        const data = await response.json();


        // Log the response to console
        console.log('API Response:', data);


        if (!response.ok) {
          throw new Error('Failed to fetch requisitions');
        }


        setRequisitions(data);
      } catch (error) {
        console.error('Error fetching requisitions:', error);
      }
    };


    fetchRequisitions();
  }, [token]);


  const goToNextScreen = () => {
    navigate('/Add-purchase-requisation');
  };


  // Handle product view button click
  const handleProductView = (products) => {
    setSelectedProducts(products);
    setOpen(true); // Open the modal
  };


  const handleClose = () => setOpen(false); // Close modal function


  return (
    <div className="main-container">
     
        <div className="top-controls">
          <h1 className='title'>Purchase Requisition List
            <div className="underline"></div>
          </h1>
          <div className="buttons-row">
              <button className="color-button" onClick={goToNextScreen}>
              Add Purchase Requisition
              </button>
          </div>        
        </div>
       
      {/* Render requisitions in a table */}
      <table >
        <thead>
          <tr>
            <th>PR ID</th>
            <th>User Info</th> {/* Combine username and image */}
            <th>Description</th>
            <th>Project Name</th>
            <th>Category</th>
            <th>Sub-category</th>
            <th>Status</th>
            <th>Actions</th> {/* New column for actions */}
          </tr>
        </thead>
        <tbody>
          {requisitions.map((requisition) => (
            <tr key={requisition.pr_id}>
              <td>{requisition.pr_id}</td>
              <td >
                <img
                  src={requisition.user_image}
                  alt={requisition.user_name}
                  className='image'
                />
                <span>{requisition.user_name}</span>
              </td>
              <td>{requisition.description || 'N/A'}</td>
              <td>{requisition.project_name}</td>
              <td>{requisition.category}</td>
              <td>{requisition.sub_category || 'N/A'}</td>
              <td>{requisition.status}</td>
              <td>
                <button
                  style={{color:'#3b82f6',backgroundColor:'transparent'}}
                  onClick={() => handleProductView(requisition.products)} // Pass products to handleProductView
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>


      {/* Modal for displaying products */}
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
        <Box sx={styles.modalBox}>
          <Typography id="modal-title" variant="h6" component="h2">
            Product Details
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {selectedProducts.length > 0 ? (
              <table >
                <thead>
                  <tr>
                    <th >Product Name</th>
                    <th >Quantity</th>
                    <th >Description</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedProducts.map((product, index) => (
                    <tr key={index}>
                      <td >{product.name}</td>
                      <td >{product.qty}</td>
                      <td >{product.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No products available</p>
            )}
          </Typography>
          <Button onClick={handleClose} style={styles.closeButton}>Close</Button>
        </Box>
      </Modal>
    </div>
  );
};


// Inline styles for the button, header layout, and modal
const styles = {
 
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800, // Increased modal width
    maxWidth: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    textAlign: 'center',
  },
  closeButton: {
    marginTop: '20px',
    backgroundColor: '#007bff',
    color: '#fff',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
 
 
};


export default PurchaseRequisition;





