import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation




function Product() {
 const [products, setProducts] = useState([]);
 const [loading, setLoading] = useState(true);
 const [error, setError] = useState(null);
 const navigate = useNavigate(); // Initialize useNavigate




 useEffect(() => {
   const fetchProducts = async () => {
     setLoading(true);
     const token = localStorage.getItem('authToken'); // Retrieve the token from localStorage




     console.log('Token retrieved from localStorage:', token); // Log the token




     if (!token) {
       setError('No token found. Please log in again.');
       setLoading(false);
       return;
     }




     try {
       const response = await fetch('https://api.myntask.com/product/list', {
         method: 'GET',
         headers: {
           'Authorization': `Bearer ${token}`,
           'Content-Type': 'application/json',
         },
       });




       console.log('Response status:', response.status); // Log the response status
       console.log('Response headers:', response.headers); // Log the response headers




       if (!response.ok) {
         throw new Error(`Network response was not ok, status: ${response.status}`);
       }




       const data = await response.json();
       console.log('API response data:', data); // Log the raw API response data




       if (Array.isArray(data) && data.length === 0) {
         console.warn('Received an empty array, no products available.');
       }




       setProducts(data); // Set the products state
     } catch (error) {
       console.error('Error fetching products:', error);
       setError('Failed to fetch products.');
     } finally {
       setLoading(false);
     }
   };




   fetchProducts();
 }, []);




 // Function to handle navigation to AddProduct screen
 const handleAddProduct = () => {
   navigate('/add-product'); // Replace with your actual route to the AddProduct screen
 };




 if (loading) return <p>Loading...</p>;
 if (error) return <p>{error}</p>;




 return (
   <div  className="main-container">
   <div className="top-controls">
     <h1 className='title'>Product
       <div className="underline"></div>
     </h1>
     <div className="buttons-row">
     
         <button className="color-button" onClick={handleAddProduct}>
           Add Product
         </button>
     
     </div>
   </div>


       {products.length > 0 ? (
         <table >
           <thead>
             <tr>
               <th >ID</th>
               <th >Name</th>
               <th >Status</th>
               <th >Unit Type</th>
               <th >Client Can Purchase</th>
               <th >Price with Taxes</th>
             </tr>
           </thead>
           <tbody>
             {products.map((product, index) => (
               <tr
                 key={product.id}
               >
                 <td >{product.id}</td>
                 <td >{product.name}</td>
                 <td >{product.status}</td>
                 <td >{product.unit_type}</td>
                 <td >{product.client_can_purchase ? 'Yes' : 'No'}</td>
                 <td >{product.price_with_taxes}</td>
               </tr>
             ))}
           </tbody>
         </table>
       ) : (
         <p>No products available.</p>
       )}
 
   </div>
 );
}




export default Product;










