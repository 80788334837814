import React, { useEffect, useState } from 'react';
import './AddExpenseModal.css'; // Import the CSS file
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';


const AddExpenseModal = () => {
  const [formData, setFormData] = useState({
    item_name: '',
    user_id: '',
    currency_id: '',
    price: '',
    purchase_date: '',
    project_id: '',
    category_id: '',
    purchase_from: '',
    description: '',
    expense_type: '',
    site_name: '',
  });


  const [currencies, setCurrencies] = useState([]);
  const [projects, setProjects] = useState([]);
  const [categories, setCategories] = useState([]);
  const [members, setMembers] = useState([]);
  const [billFile, setBillFile] = useState(null);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate(); // Add this line to use navigate


  useEffect(() => {
    const fetchCurrency = async () => {
      const token = localStorage.getItem('authToken');
      console.log('Fetching currencies...');
      try {
        const response = await fetch('https://api.myntask.com/expenses/currency', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setCurrencies(data || []);
      } catch (error) {
        console.error('Error fetching currency:', error);
        setCurrencies([]);
      }
    };


    const fetchProjects = async () => {
      const token = localStorage.getItem('authToken');
      console.log('Fetching projects...');
      try {
        const response = await fetch('https://api.myntask.com/task/project_list', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setProjects(data || []);
      } catch (error) {
        console.error('Error fetching projects:', error);
        setProjects([]);
      }
    };


    const fetchCategory = async () => {
      const token = localStorage.getItem('authToken');
      console.log('Fetching categories...');
      try {
        const response = await fetch('https://api.myntask.com/expenses/category', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setCategories(data || []);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setCategories([]);
      }
    };


    const fetchMembers = async () => {
      const token = localStorage.getItem('authToken');
      console.log('Fetching projects...');
      try {
        const response = await fetch('https://api.myntask.com/employee/member', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setMembers(data || []);
      } catch (error) {
        console.error('Error fetching members:', error);
        setMembers([]);
      }
    };


    fetchCurrency();
    fetchProjects();
    fetchCategory();
    fetchMembers();
  }, []);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };


  const handleBillUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBillFile(file);
    }
  };


  const validateForm = () => {
    let newErrors = {};


    if (!formData.item_name) newErrors.item_name = 'Item Name is required';
    if (!formData.currency_id) newErrors.currency_id = 'Currency is required';
    if (!formData.price) newErrors.price = 'Price is required';
    if (!formData.purchase_date) newErrors.purchase_date = 'Purchase Date is required';
    if (!formData.project_id) newErrors.project_id = 'Project ID is required';


    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };




const handleSubmit = async (e) => {
  e.preventDefault();
 
 


  // Form validation
  if (!validateForm()) {
    Swal.fire({
      icon: 'error',
      title: 'Validation Error',
      text: 'Please fill in all required fields.',
    });
    return;
  }


  // Token retrieval
  const token = localStorage.getItem('authToken');
  if (!token) {
    console.log('No token found');
    return;
  }


  // Data to send
  const dataToSend = {
    item_name: formData.item_name,
    user_id: formData.user_id,
    currency_id: formData.currency_id,
    price: formData.price,
    purchase_date: formData.purchase_date,
    project_id: formData.project_id,
    category_id: formData.category_id,
    purchase_from: formData.purchase_from,
    description: formData.description,
    expense_type: formData.expense_type,
    site_name: formData.site_name,
  };


  // If a bill file is present, use FormData; otherwise, send JSON
  let bodyToSend;
  let headers = {
    Authorization: `Bearer ${token}`,
  };


  if (billFile) {
    const formDataToSend = new FormData();
    Object.keys(dataToSend).forEach((key) => {
      formDataToSend.append(key, dataToSend[key]);
    });
    formDataToSend.append('bill', billFile); // Append bill file


    bodyToSend = formDataToSend; // Use FormData for file upload
    // Don't set 'Content-Type', the browser will automatically set it to 'multipart/form-data'
  } else {
    bodyToSend = JSON.stringify(dataToSend); // Send data as JSON
    headers['Content-Type'] = 'application/json'; // Set Content-Type header for JSON
  }


  try {
    console.log('Saving expense...', dataToSend);


    const response = await fetch('https://api.myntask.com/expenses/save', {
      method: 'POST',
      headers: headers,
      body: bodyToSend,
    });


    const result = await response.text(); // Inspect the response
    if (response.ok) {
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Expense saved successfully!',
      }).then(() => {
        navigate('/expenses'); // Navigate to the expenses list after successful save
      });


      console.log('Expense saved successfully!');


      // Reset form data
      setFormData({
        item_name: '',
        user_id: '',
        currency_id: '',
        price: '',
        purchase_date: '',
        project_id: '',
        category_id: '',
        purchase_from: '',
        description: '',
        expense_type: '',
        site_name: '',
      });
      setBillFile(null);
      setErrors({});
    } else {
      console.log('Server response:', result);
      throw new Error(result || 'Failed to save expense.');
    }
  } catch (error) {
    console.error('Error saving expense:', error);
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: 'Failed to save expense. Please try again.',
    });
  }
};


  return (
    <div className="main-container">
    <div className="top-controls">
     <h1 className='title'>Add Expense
       <div className="underline"></div>
     </h1>
   </div>
  <form className="expenseForm" onSubmit={handleSubmit}>
         <div className="form-row">
           <div className="formgroup">
             <label htmlFor="item_name">
               Item Name <span className="required">*</span>
             </label>




             <input
               className={`expenseInput ${errors.item_name ? 'error-border' : ''}`}
               type="text"
               id="item_name"
               name="item_name"
               value={formData.item_name}
               onChange={handleChange}
               required
             />
             {errors.item_name && <span className="error-text">{errors.item_name}</span>}
           </div>
           <div className="formgroup">
             <label htmlFor="currency_id">
               Currency <span className="required">*</span>
             </label>
             <select
               className={`expenseSelect ${errors.currency_id ? 'error-border' : ''}`}
               id="currency_id"
               name="currency_id"
               value={formData.currency_id}
               onChange={handleChange}
               required
             >
               <option value="" disabled>
                 Select currency
               </option>
               {currencies.map((currency) => (
                 <option key={currency.id} value={currency.id}>
                   {currency.currency_symbol} {currency.currency_code}
                 </option>
               ))}
             </select>
             {errors.currency_id && <span className="error-text">{errors.currency_id}</span>}
           </div>
         </div>
         <div className="form-row">
           <div className="formgroup">
             <label htmlFor="price">
               Price <span className="required">*</span>
             </label>
             <input
               className={`expenseInput ${errors.price ? 'error-border' : ''}`}
               type="number"
               id="price"
               name="price"
               value={formData.price}
               onChange={handleChange}
               required
             />
             {errors.price && <span className="error-text">{errors.price}</span>}
           </div>
           <div className="formgroup">
             <label htmlFor="purchase_date">
               Purchase Date <span className="required">*</span>
             </label>
             <input
               className={`expenseInput ${errors.purchase_date ? 'error-border' : ''}`}
               type="date"
               id="purchase_date"
               name="purchase_date"
               value={formData.purchase_date}
               onChange={handleChange}
               required
             />
             {errors.purchase_date && <span className="error-text">{errors.purchase_date}</span>}
           </div>
         </div>
         <div className="form-row">
           <div className="formgroup">
             <label htmlFor="project_id">
               Project ID <span className="required">*</span>
             </label>
             <select
               className={`expenseSelect ${errors.project_id ? 'error-border' : ''}`}
               id="project_id"
               name="project_id"
               value={formData.project_id}
               onChange={handleChange}
             >
               <option value="" disabled>
                 Select Project
               </option>
               {projects.map((project) => (
                 <option key={project.id} value={project.id}>
                   {project.project_name}
                 </option>
               ))}
             </select>
             {errors.project_id && <span className="error-text">{errors.project_id}</span>}
           </div>
           <div className="formgroup">
             <label htmlFor="purchase_from">Purchase From</label>
             <input
               className="expenseInput"
               type="text"
               id="purchase_from"
               name="purchase_from"
               value={formData.purchase_from}
               onChange={handleChange}
             />
           </div>
         </div>
         <div className="form-row">
           <div className="formgroup">
             <label htmlFor="category_id">Category ID</label>
             <select
               className="expenseSelect"
               id="category_id"
               name="category_id"
               value={formData.category_id}
               onChange={handleChange}
             >
               <option value="" disabled>
                 Select Category
               </option>
               {categories.map((category) => (
                 <option key={category.id} value={category.id}>
                   {category.category_name}
                 </option>
               ))}
             </select>
           </div>
           <div className="formgroup">
            <label htmlFor="member_id">Employee</label>
              <select
                className="input select-box"
                id="user_id"
                name="user_id"
                value={formData.user_id}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select Member
                </option>
                {members.map((member) => (
                  <option key={member.id} value={member.id}>
                    {member.member_name}
                  </option>
                ))}
              </select>
            </div>
         
         </div>
         <div className="form-row">
         
             <div className="formgroup">
               <label htmlFor="expense_type">Expense Type</label>
               <select
                 className="expenseSelect"
                 id="expense_type"
                 name="expense_type"
                 value={formData.expense_type}
                 onChange={handleChange}
               >
                 <option value="" disabled>Select Expense Type</option>
                 <option value="personal_expense">Personal Expense</option>
                 <option value="site_expense">Site Expense</option>
               </select>
             </div>
             <div className="formgroup">
               <label htmlFor="site_name">Site Name</label>
               <input
                 className="expenseInput"
                 type="text"
                 id="site_name"
                 name="site_name"
                 value={formData.site_name}
                 onChange={handleChange}
               />
             </div>
         
         </div>
         <div className="formgroup">
             <label htmlFor="bill">Add Bill</label>
             <input
               type="file"
               id="bill"
               name="bill"
               accept="image/*"
               onChange={handleBillUpload}
               className="expenseInput"
             />
           </div>
         <div className="formgroup" style={{marginTop:'20px'}}>
           <label htmlFor="description">Description</label>
           <textarea
             className="expenseInput"
             id="description"
             name="description"
             value={formData.description}
             onChange={handleChange}
           />
         </div>
         <div className="form-action">
           <button type="submit" className="submit">
             Save
           </button>
         </div>
       </form>


    </div>
  );
};


export default AddExpenseModal;





