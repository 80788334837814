// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';


// function Estimate() {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [searchTerm, setSearchTerm] = useState('');
//   const navigate = useNavigate(); // useNavigate hook from React Router


//   useEffect(() => {
//     const fetchEstimates = async () => {
//       const token = localStorage.getItem('authToken');
     
//       if (!token) {
//         console.error('No token found, please login first.');
//         return;
//       }


//       try {
//         const response = await fetch('https://api.myntask.com/estimate/list', {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${token}`,
//           },
//         });


//         if (!response.ok) {
//           throw new Error('Failed to fetch estimates');
//         }


//         const result = await response.json();
//         console.log('Estimate data:', result);
//         setData(result);
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching estimates:', error);
//         setLoading(false);
//       }
//     };


//     fetchEstimates();
//   }, []);


//   const handleSearch = (e) => {
//     setSearchTerm(e.target.value);
//   };


//   const filteredData = data.filter((estimate) =>
//     estimate.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     estimate.estimate_number.toLowerCase().includes(searchTerm.toLowerCase())
//   );


//   if (loading) {
//     return <div>Loading...</div>;
//   }


//   return (
//     <div style={{ padding: '20px' }}>
//       <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
//         <input
//           type="text"
//           placeholder="Search..."
//           value={searchTerm}
//           onChange={handleSearch}
//           style={{ padding: '8px', width: '300px', borderRadius: '4px', border: '1px solid gray' }}
//         />
//         <button
//           onClick={() => navigate('/addestimate')} // Navigate to AddEstimate component
//           style={{
//             padding: '10px 20px',
//             backgroundColor: 'black',
//             color: 'white',
//             border: 'none',
//             borderRadius: '4px',
//             cursor: 'pointer',
//           }}
//         >
//           Create Estimate
//         </button>
//       </div>
//       <table style={{ width: '100%', borderCollapse: 'collapse' }}>
//         <thead style={{ backgroundColor: 'black', color: 'white' }}>
//           <tr>
//             <th style={{ border: '1px solid gray', padding: '8px' }}>ID</th>
//             <th style={{ border: '1px solid gray', padding: '8px' }}>Estimate Number</th>
//             <th style={{ border: '1px solid gray', padding: '8px' }}>Customer Name</th>
//             <th style={{ border: '1px solid gray', padding: '8px' }}>Total</th>
//             <th style={{ border: '1px solid gray', padding: '8px' }}>Status</th>
//             <th style={{ border: '1px solid gray', padding: '8px' }}>Valid Till</th>
//             <th style={{ border: '1px solid gray', padding: '8px' }}>Image</th>
//           </tr>
//         </thead>
//         <tbody>
//           {filteredData.map((estimate) => (
//             <tr key={estimate.id} style={{ backgroundColor: 'white', color: 'black' }}>
//               <td style={{ border: '1px solid gray', padding: '8px' }}>{estimate.id}</td>
//               <td style={{ border: '1px solid gray', padding: '8px' }}>{estimate.estimate_number}</td>
//               <td style={{ border: '1px solid gray', padding: '8px' }}>{estimate.name}</td>
//               <td style={{ border: '1px solid gray', padding: '8px' }}>{estimate.total}</td>
//               <td style={{ border: '1px solid gray', padding: '8px' }}>{estimate.status}</td>
//               <td style={{ border: '1px solid gray', padding: '8px' }}>{new Date(estimate.valid_till).toLocaleDateString()}</td>
//               <td style={{ border: '1px solid gray', padding: '8px' }}>
//                 {estimate.image ? (
//                   <img
//                     src={estimate.image}
//                     alt="Estimate"
//                     style={{ width: '50px', height: '50px', objectFit: 'cover' }}
//                   />
//                 ) : (
//                   <img
//                     src="/path/to/default/image.jpg"
//                     alt="Default"
//                     style={{ width: '50px', height: '50px', objectFit: 'cover' }}
//                   />
//                 )}
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// }


// export default Estimate;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './../Components/Main.css'; // Import the CSS file


function Estimate() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();


  useEffect(() => {
    const fetchEstimates = async () => {
      const token = localStorage.getItem('authToken');


      if (!token) {
        console.error('No token found, please login first.');
        return;
      }


      try {
        const response = await fetch('https://api.myntask.com/estimate/list', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });


        if (!response.ok) {
          throw new Error('Failed to fetch estimates');
        }


        const result = await response.json();
        console.log('Estimate data:', result);
        setData(result);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching estimates:', error);
        setLoading(false);
      }
    };


    fetchEstimates();
  }, []);


  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };


  const filteredData = data.filter((estimate) =>
    estimate.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    estimate.estimate_number.toLowerCase().includes(searchTerm.toLowerCase())
  );


  if (loading) {
    return <div>Loading...</div>;
  }


  return (
    <div className="main-container">
    <div className="top-controls">
      <h1 className='title'>Estimates
        <div className="underline"></div>
      </h1>
      <div className="buttons-row">
          <button className="color-button" onClick={() => navigate('/addestimate')}>
          Create Estimate
          </button>
     
      </div>
    </div>
    <div className="top-controls">
          <div className="filters-and-search">
           
          <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearch}
          className="searchbar"
        />
       
          </div>
        </div>
     
      <table >
        <thead>
          <tr>
           
            <th>Estimate Number</th>
            <th>Customer Name</th>
            <th>Total</th>
            <th>Status</th>
            <th>Valid Till</th>
            <th>Image</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((estimate) => (
            <tr key={estimate.id}>
             
              <td>{estimate.estimate_number}</td>
              <td>{estimate.name}</td>
              <td>{estimate.total}</td>
              <td>{estimate.status}</td>
              <td>{new Date(estimate.valid_till).toLocaleDateString()}</td>
              <td>
                {estimate.image ? (
                  <img
                    src={estimate.image}
                    alt="Estimate"
                    className="image"
                  />
                ) : (
                  <img
                    src="/path/to/default/image.jpg"
                    alt="Default"
                    className="image"
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}


export default Estimate;






