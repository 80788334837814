import React, { useState } from 'react';
import {
  Box, Button, TextField, Typography, MenuItem, Card, CardContent, Grid
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const languages = ['English', 'Spanish', 'French', 'German', 'Chinese'];
const statuses = ['Active', 'Inactive'];
const currencies = ['USD', 'EUR', 'GBP', 'JPY', 'AUD']; // Example currencies
const timezones = [
  'UTC-12:00', 'UTC-11:00', 'UTC-10:00', 'UTC-09:00', 'UTC-08:00', 'UTC-07:00', 'UTC-06:00', 
  'UTC-05:00', 'UTC-04:00', 'UTC-03:00', 'UTC-02:00', 'UTC-01:00', 'UTC+00:00', 'UTC+01:00', 
  'UTC+02:00', 'UTC+03:00', 'UTC+04:00', 'UTC+05:00', 'UTC+06:00', 'UTC+07:00', 'UTC+08:00', 
  'UTC+09:00', 'UTC+10:00', 'UTC+11:00', 'UTC+12:00'
];

const RedStar = () => <span style={{ color: 'red' }}>*</span>;

function AddCompanies() {
  const navigate = useNavigate();

  const [form, setForm] = useState({
    companyName: '',
    companyEmail: '',
    companyPhone: '',
    companyLogo: '',
    companyWebsite: '',
    companyLanguage: '',
    companyStatus: '',
    companyAddress: '',
    defaultCurrency: '',
    defaultTimezone: '',
    accountName: '',
    accountEmail: '',
  });

  const [errors, setErrors] = useState({
    companyName: false,
    companyEmail: false,
    accountName: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    setErrors({ ...errors, [name]: false });
  };

  const handleFileChange = (e) => {
    setForm({ ...form, companyLogo: e.target.files[0] });
  };

  const handleSave = async () => {
    const newErrors = {
      companyName: form.companyName.trim() === '',
      companyEmail: form.companyEmail.trim() === '',
      accountName: form.accountName.trim() === '',
    };

    if (newErrors.companyName || newErrors.companyEmail || newErrors.accountName) {
      setErrors(newErrors);
      return;
    }

    const data = {
      company_name: form.companyName,
      company_email: form.companyEmail,
      company_phone: form.companyPhone,
      address: form.companyAddress,
      website: form.companyWebsite,
      currency_id: 2, // This value should be derived from form.defaultCurrency
      timezone: form.defaultTimezone,
      language: form.companyLanguage,
      status: form.companyStatus.toLowerCase(), // assuming backend expects lowercase
      email: form.accountEmail,
      name: form.accountName,
    };

    try {
      const response = await fetch('https://api.myntask.com/company/save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log('Form data successfully saved:', result);
      navigate('/superadmincompanies');
    } catch (error) {
      console.error('Failed to save form data:', error);
    }
  };

  const handleCancel = () => {
    navigate('/superadmincompanies'); // Navigate back to the SuperAdminCompanies screen
  };

  return (
    <Box
      p={3}
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{ backgroundColor: '#f5f5f5', minHeight: '100vh' }}
    >
      <Typography variant="h4" gutterBottom>
        Add Company
      </Typography>

      <Card
        variant="outlined"
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          width: '100%',
          maxWidth: 900,
          p: 2,
          mt: 3,
          backgroundColor: '#fff',
        }}
      >
        <CardContent>
          <Typography variant="h5" gutterBottom sx={{ marginBottom: 3 }}>
            Company Detail
          </Typography>

          <Box component="form" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  margin="normal"
                  label={<><span>Company Name</span> <RedStar /></>}
                  name="companyName"
                  value={form.companyName}
                  onChange={handleChange}
                  error={errors.companyName}
                  helperText={errors.companyName && "Company Name is required"}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  margin="normal"
                  label={<><span>Company Email</span> <RedStar /></>}
                  name="companyEmail"
                  value={form.companyEmail}
                  onChange={handleChange}
                  error={errors.companyEmail}
                  helperText={errors.companyEmail && "Company Email is required"}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Company Phone Number"
                  name="companyPhone"
                  value={form.companyPhone}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="contained"
                  component="label"
                  fullWidth
                  sx={{ marginTop: 2, marginBottom: 2 }}
                >
                  Upload Company Logo
                  <input
                    type="file"
                    hidden
                    name="companyLogo"
                    onChange={handleFileChange}
                  />
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Company Website"
                  name="companyWebsite"
                  value={form.companyWebsite}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  margin="normal"
                  select
                  label="Language"
                  name="companyLanguage"
                  value={form.companyLanguage}
                  onChange={handleChange}
                >
                  {languages.map((language) => (
                    <MenuItem key={language} value={language}>
                      {language}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  margin="normal"
                  select
                  label="Status"
                  name="companyStatus"
                  value={form.companyStatus}
                  onChange={handleChange}
                >
                  {statuses.map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  margin="normal"
                  select
                  label="Default Currency"
                  name="defaultCurrency"
                  value={form.defaultCurrency}
                  onChange={handleChange}
                >
                  {currencies.map((currency) => (
                    <MenuItem key={currency} value={currency}>
                      {currency}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  margin="normal"
                  select
                  label="Default Timezone"
                  name="defaultTimezone"
                  value={form.defaultTimezone}
                  onChange={handleChange}
                >
                  {timezones.map((timezone) => (
                    <MenuItem key={timezone} value={timezone}>
                      {timezone}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Company Address"
                  name="companyAddress"
                  value={form.companyAddress}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom sx={{ marginTop: 3 }}>
                  Account Details
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  label={<><span>Account Name</span> <RedStar /></>}
                  name="accountName"
                  value={form.accountName}
                  onChange={handleChange}
                  error={errors.accountName}
                  helperText={errors.accountName && "Account Name is required"}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Account Email"
                  name="accountEmail"
                  value={form.accountEmail}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end" mt={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    sx={{ marginRight: 2 }}
                  >
                    Save
                  </Button>
                  <Button variant="contained" onClick={handleCancel} color="secondary">
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default AddCompanies;

