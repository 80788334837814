import React, { useState, useEffect } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import './AdvancedDashboard.css'; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom';




const AdvancedDashboard = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [dashboardData, setDashboardData] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [hrData, setHrData] = useState(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();




  useEffect(() => {
    const fetchDashboardData = async () => {
      const token = localStorage.getItem('authToken'); // Retrieve the token from localStorage
      if (!token) {
        setError('No authentication token found. Please log in again.');
        console.error('No authentication token found.');
        return;
      }
      try {
        const response = await fetch('https://api.myntask.com/dashboard/advanced', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Pass the token in the Authorization header
          },
        });




        if (!response.ok) {
          throw new Error('Failed to fetch dashboard data.');
        }




        const data = await response.json();
        console.log('Dashboard data fetched successfully:', data);
        setDashboardData(data); // Store the fetched data in state
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        setError('Failed to load dashboard data. Please try again later.');
      }
    };




    fetchDashboardData();
  }, []);




  useEffect(() => {
    const fetchProjectData = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No authentication token found. Please log in again.');
        return;
      }
      try {
        const response = await fetch('https://api.myntask.com/dashboard/advanced/project', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch project data.');
        }




        const data = await response.json();
        console.log('Project data fetched successfully:', data);
        setProjectData(data);
      } catch (err) {
        setError('Failed to load project data. Please try again later.');
      }
    };




    if (activeTab === 'project') {
      fetchProjectData();
    }
  }, [activeTab]);




  useEffect(() => {
    const fetchClientData = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No authentication token found. Please log in again.');
        return;
      }
      try {
        const response = await fetch('https://api.myntask.com/dashboard/advanced/client', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch client data.');
        }




        const data = await response.json();
        console.log('Client data fetched successfully:', data);
        setClientData(data);
      } catch (err) {
        setError('Failed to load client data. Please try again later.');
      }
    };




    if (activeTab === 'client') {
      fetchClientData();
    }
  }, [activeTab]);




  useEffect(() => {
    const fetchHrData = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No authentication token found. Please log in again.');
        return;
      }
      try {
        const response = await fetch('https://api.myntask.com/dashboard/advanced/hr', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch HR data.');
        }
        const data = await response.json();
        console.log('HR data fetched successfully:', data);
        setHrData(data);
      } catch (err) {
        setError('Failed to load HR data. Please try again later.');
      }
    };




    if (activeTab === 'hr') {
      fetchHrData();
    }
  }, [activeTab]);


  const handleNavigation = (path) => {
    navigate(path);
  };


  const renderPendingLeaves = () => {
    if (!dashboardData?.pending_leaves || dashboardData.pending_leaves.length === 0) {
      return <span className="advance-norecord">-- No Record Found --</span>;
  }




    return (
      <div className="advance-scrollable-container">
       
          <table >
            <thead>
              <tr>
                <th >User Name</th>
                <th >Designation</th>
                <th >Leave Date</th>
              </tr>
            </thead>
            <tbody>
              {dashboardData.pending_leaves.map((leave) => (
                <tr  key={leave.leave_id}>
                  <td>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={leave.image}
                        alt={leave.name}
                        className="image"
                      />
                      <div>
                        <span style={{ fontWeight: 'bold' }}>{leave.user_name}</span>
                      </div>
                    </div>
                  </td>
                  <td>{leave.designation}</td>
                  <td>{new Date(leave.leave_date).toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
     
      </div>
    );
};




const renderPendingTasks = () => {
  if (!dashboardData?.pending_task_list) return null;




  return (
    <div className="advance-scrollable-container">
      {dashboardData.pending_task_list.length > 0 ? (
        <table >
          <thead>
            <tr>
              <th>Task</th>
              <th>Due Date</th>
              <th>Status</th>
              <th>Assigned Users</th>
            </tr>
          </thead>
          <tbody>
            {dashboardData.pending_task_list.map((task) => (
              <tr key={task.task_id}>
                <td>{task.task}</td>
                <td>{task.due_date === 'Invalid date' ? 'N/A' : new Date(task.due_date).toLocaleDateString()}</td>
                <td>{task.status}</td>
                <td>{task.assigned_users.map(user => user.name).join(', ')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="advance-noData">-- No Record Found --</p>
      )}
    </div>
  );
};




const renderBarChart = () => {
  if (!dashboardData) return null;




  const chartData = [
    dashboardData.total_clients,
    dashboardData.total_employees,
    dashboardData.total_projects,
    dashboardData.due_invoices,
    dashboardData.hours_logged,
    dashboardData.pending_task_list.length,
    dashboardData.unresolved_tickets,
  ];




  // Check if all data points are zero
  const allDataZero = chartData.every(value => value === 0);




  // If all data points are zero, show a message
  if (allDataZero) {
    return (
      <div className="advance-bar-chart-container">
        <p className="advance-noData">-- No Data Available --</p>
      </div>
    );
  }




  const data = {
    labels: [
      'Total Clients',
      'Total Employees',
      'Total Projects',
      'Due Invoices',
      'Hours Logged',
      'Pending Tasks',
      'Unresolved Tickets',
    ],
    datasets: [
      {
        label: 'Overview Data',
        data: chartData,
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4BC0C0',
          '#9966FF',
          '#FF9F40',
          '#FF6384',
        ],
      },
    ],
  };




  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };




  return (
    <div className="advance-bar-chart-container">
      <Bar data={data} options={options} />
    </div>
  );
};








  const renderProjectTab = () => {
    if (!projectData) {
      return <p className="advance-noData">-- No Record Found --</p>;
    }
 
    const {
      total_project,
      overdue_project,
      hours_logged,
      pending_milestone,
      'in progress': inProgress,
      'not started': notStarted,
      'on hold': onHold,
      'canceled': canceled,
    } = projectData.status_wise_projects;
 
    const data = {
      labels: ['In Progress', 'Not Started', 'On Hold', 'Canceled'],
      datasets: [
        {
          data: [inProgress, notStarted, onHold, canceled],
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
          hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
        },
      ],
    };
 
    const options = {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
        },
      },
    };
 
    return (
      <div className="advance-project-content">
        <div className="advance-row">
  <div className="advance-container-box"  onClick={() => handleNavigation('/project')}>
    <span className="advance-total">Total Projects:</span> <span className="advance-data">{total_project}</span>
  </div>
  <div className="advance-container-box">
    <span className="advance-total">Overdue Projects:</span> <span className="advance-data">{overdue_project}</span>
  </div>
  <div className="advance-container-box">
    <span className="advance-total">Hours Logged:</span> <span className="advance-data">{hours_logged}</span>
  </div>
  <div className="advance-container-box">
    <span className="advance-total">Pending Milestones:</span> <span className="advance-data">{pending_milestone ? pending_milestone : "None"}</span>
  </div>
</div>


        <div style={{ width: '50%', height: '300px', margin: '0 auto' }}>
          <Pie data={data} options={options} />
        </div>
      </div>
    );
  };
 




  const renderClientTab = () => {
    if (!clientData) {
      return <p className="advance-noData">-- No Record Found --</p>;
    }
 
    const {
      total_clients,
      total_leads,
      total_deals,
      deal_conversions,
      contracts_generated,
      contracts_signed,
      deal_count = [],
      source_counts = [],
    } = clientData;
 
    // Data for Deals Pie Chart
    const dealData = {
      labels: deal_count.map(deal => deal.stage_name),
      datasets: [
        {
          data: deal_count.map(deal => deal.total),
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
          hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
        },
      ],
    };
 
    // Data for Sources Pie Chart
    const sourceData = {
      labels: source_counts.map(source => source.source_name),
      datasets: [
        {
          data: source_counts.map(source => source.total),
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
          hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
        },
      ],
    };
 
    const options = {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
        },
      },
    };
 
    return (
      <div className="advance-client-content">
       <div className="advance-row">
  <div className="advance-container-box" onClick={() => handleNavigation('/clients')}>
    <span className="advance-total">Total Clients:</span> <span className="advance-data">{total_clients}</span>
  </div>
  <div className="advance-container-box" onClick={() => handleNavigation('/lead-contact')}>
    <span className="advance-total">Total Leads:</span> <span className="advance-data">{total_leads}</span>
  </div>
  <div className="advance-container-box" onClick={() => handleNavigation('/deal')}>
    <span className="advance-total">Total Deals:</span> <span className="advance-data">{total_deals}</span>
  </div>
  <div className="advance-container-box">
    <span className="advance-total">Deal Conversions:</span> <span className="advance-data">{deal_conversions}</span>
  </div>
  <div className="advance-container-box">
    <span className="advance-total">Contracts Generated:</span> <span className="advance-data">{contracts_generated}</span>
  </div>
  <div className="advance-container-box">
    <span className="advance-total">Contracts Signed:</span> <span className="advance-data">{contracts_signed}</span>
  </div>
</div>


 
        <div className="advance-row-large">
          <div className="advance-container-box">
            <h3>Deals</h3>
            <div style={{ width: '100%', height: '300px', margin: '0 auto' }}>
              <Pie data={dealData} options={options} />
            </div>
          </div>
          <div className="advance-container-box">
            <h3>Sources</h3>
            <div style={{ width: '100%', height: '300px', margin: '0 auto' }}>
              <Pie data={sourceData} options={options} />
            </div>
          </div>
        </div>
      </div>
    );
  };
 




  const renderHrTab = () => {
    if (!hrData) {
      return <p className="advance-noData">-- No Record Found --</p>;
    }
 
    const {
      total_employee,
      today_attendance,
      leave_approved,
      employee_exits,
      average_attendance,
      department_wise_employee_counts = [],
      designation_wise_employee_counts = [],
      gender_wise_employee: { male, female } = {},
      role_wise_employee = [],
      leave_today = [],
      probation_today = [],
    } = hrData;
 
    // Data for Department-wise Bar Chart
    const departmentData = {
      labels: department_wise_employee_counts.map(department => department.department_name),
      datasets: [
        {
          label: 'Employees',
          data: department_wise_employee_counts.map(department => department.total),
          backgroundColor: '#4BC0C0',
          borderColor: '#4BC0C0',
          borderWidth: 1,
        },
      ],
    };
 
    // Data for Designation-wise Bar Chart
    const designationData = {
      labels: designation_wise_employee_counts.map(designation => designation.designation_name),
      datasets: [
        {
          label: 'Employees',
          data: designation_wise_employee_counts.map(designation => designation.total),
          backgroundColor: '#FF6384',
          borderColor: '#FF6384',
          borderWidth: 1,
        },
      ],
    };
 
    // Data for Role-wise Bar Chart
    const roleData = {
      labels: role_wise_employee.map(role => role.role_name),
      datasets: [
        {
          label: 'Employees',
          data: role_wise_employee.map(role => role.user_count),
          backgroundColor: '#FFCE56',
          borderColor: '#FFCE56',
          borderWidth: 1,
        },
      ],
    };
 
    const barOptions = {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
      },
      scales: {
        x: {
          beginAtZero: true,
        },
        y: {
          beginAtZero: true,
        },
      },
    };
 
    return (
      <div className="advance-hr-content">
        <div className="advance-row">
  <div className="advance-container-box"  onClick={() => handleNavigation('/employee')}>
    <span className="advance-total">Total Employees:</span> <span className="advance-data">{total_employee}</span>
  </div>
  <div className="advance-container-box"  onClick={() => handleNavigation('/attendnace')}>
    <span className="advance-total">Today's Attendance:</span> <span className="advance-data">{today_attendance}</span>
  </div>
  <div className="advance-container-box">  onClick={() => handleNavigation('/leave')}
    <span className="advance-total">Approved Leaves:</span> <span className="advance-data">{leave_approved}</span>
  </div>
  <div className="advance-container-box">
    <span className="advance-total">Employee Exits:</span> <span className="advance-data">{employee_exits}</span>
  </div>
  <div className="advance-container-box" onClick={() => handleNavigation('/attendnace')}>
    <span className="advance-total">Average Attendance:</span> <span className="advance-data">{average_attendance}</span>
  </div>
  <div className="advance-container-box">
    <span className="advance-total">Male Employees:</span> <span className="advance-data">{male}</span>
  </div>
  <div className="advance-container-box">
    <span className="advance-total">Female Employees:</span> <span className="advance-data">{female}</span>
  </div>
</div>


 
        <div className="advance-row-large">
          <div className="advance-hr-detail-item advance-container-box">
            <h4>Leave Today</h4>
            {leave_today.length > 0 ? (
              <ul>
                {leave_today.map((leave, index) => (
                  <li key={index}>{leave.name}: {leave.leave_type}</li>
                ))}
              </ul>
            ) : (
              <p>No Data Available</p>
            )}
          </div>
          <div className="advance-hr-detail-item advance-container-box">
            <h4>Probation Today</h4>
            {probation_today.length > 0 ? (
              <ul>
                {probation_today.map((probation, index) => (
                  <li key={index}>{probation.name}: {probation.probation_status}</li>
                ))}
              </ul>
            ) : (
              <p>No Data Available</p>
            )}
          </div>
        </div>
 
        <div className="advance-row-large">
          <div className="advance-container-box">
            <h3>Department-wise Employee Counts</h3>
            <div style={{ width: '100%', height: '300px', margin: '0 auto' }}>
              <Bar data={departmentData} options={barOptions} />
            </div>
          </div>
          <div className="advance-container-box">
            <h3>Designation-wise Employee Counts</h3>
            <div style={{ width: '100%', height: '300px', margin: '0 auto' }}>
              <Bar data={designationData} options={barOptions} />
            </div>
          </div>
          <div className="advance-container-box">
            <h3>Role-wise Employee Counts</h3>
            <div style={{ width: '100%', height: '300px', margin: '0 auto' }}>
              <Bar data={roleData} options={barOptions} />
            </div>
          </div>
        </div>
      </div>
    );
  };
 




  const renderContent = () => {
    if (!dashboardData) {
      console.log('Dashboard data not yet available, rendering loading message...');
      return <p>Loading...</p>; // Show a loading message while data is being fetched
    }
    if (activeTab === 'overview') {
      return (
        <div className="advance-overview-content">
        <div className="advance-row">
        <div className="advance-container-box" onClick={() => handleNavigation('/clients')}>
          <span className="advance-total">Total Clients:</span>
          <span className="advance-data">{dashboardData.total_clients}</span>
        </div>
        <div className="advance-container-box" onClick={() => handleNavigation('/employee')}>
          <span className="advance-total">Total Employees:</span>
          <span className="advance-data">{dashboardData.total_employees}</span>
        </div>
        <div className="advance-container-box" onClick={() => handleNavigation('/project')}>
          <span className="advance-total">Total Projects:</span>
          <span className="advance-data">{dashboardData.total_projects}</span>
        </div>
        <div className="advance-container-box">
          <span className="advance-total">Due Invoices:</span>
          <span className="advance-data">{dashboardData.due_invoices}</span>
        </div>
      </div>


      <div className="advance-row">
        <div className="advance-container-box" >
          <span className="advance-total">Hours Logged:</span>
          <span className="advance-data">{dashboardData.hours_logged}</span>
        </div>
        <div className="advance-container-box" onClick={() => handleNavigation('/task')}>
          <span className="advance-total">Pending Tasks:</span>
          <span className="advance-data">{dashboardData.pending_task_list.length}</span>
        </div>
        <div className="advance-container-box" onClick={() => handleNavigation('/attendance')}>
          <span className="advance-total">Today Attendance:</span>
          <span className="advance-data">{dashboardData.today_attendance}</span>
        </div>
        <div className="advance-container-box" >
          <span className="advance-total">Unresolved Tickets:</span>
          <span className="advance-data">{dashboardData.unresolved_tickets}</span>
        </div>
      </div>




          <div className="advance-row-large">
           
            <div className="advance-container-box large">
              <h3>Overview Bar Chart</h3>
              {renderBarChart()}
            </div>
          </div>
          <div className="advance-row-large">
            <div className="advance-container-box ">
              <h3>Pending Tasks: {dashboardData.pending_task_list.length}</h3>
              {renderPendingTasks()}
            </div>
            {/* <div className="container-box large">
              <h3>Pending Follow-up: {dashboardData.pending_follow_up ?? 'N/A'}</h3>
            </div> */}
             <div className="advance-container-box " >
              <h3>Pending Leaves: {dashboardData.pending_leaves.length}</h3>
              {renderPendingLeaves()}
            </div>
          </div>
          <div className="advance-row-large">
            <div className="advance-container-box large">
              <h3>Open Tickets: {dashboardData.open_tickets ?? 'N/A'}</h3>
            </div>
          </div>
        </div>
      );
    } else if (activeTab === 'project') {
      return renderProjectTab();
    } else if (activeTab === 'client') {
      return renderClientTab();
    } else if (activeTab === 'hr') {
      return renderHrTab();
    }
    return <p>{activeTab.charAt(0).toUpperCase() + activeTab.slice(1)} content goes here.</p>;
  };




  return (
    <div className="advanced-dashboard">
      <div className="top-controls">
        <h1 className='title'>Advanced Dashboard
          <div className="underline"></div>
        </h1>








        <div className="advance-tabs">
       
        <button
          className={activeTab === 'overview' ? 'tab active' : 'tab'}
          onClick={() => setActiveTab('overview')}
        >
          Overview
        </button>
        <button
          className={activeTab === 'project' ? 'tab active' : 'tab'}
          onClick={() => setActiveTab('project')}
        >
          Project
        </button>
        <button
          className={activeTab === 'client' ? 'tab active' : 'tab'}
          onClick={() => setActiveTab('client')}
        >
          Client
        </button>
        <button
          className={activeTab === 'hr' ? 'tab active' : 'tab'}
          onClick={() => setActiveTab('hr')}
        >
          HR
        </button>
         
        </div>
      </div>




   
   
      <div className="advance-content">
        {error ? <p className="advance-error-message">{error}</p> : renderContent()}
      </div>
    </div>
  );
};




export default AdvancedDashboard;













