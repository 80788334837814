import React, { useEffect, useState } from 'react';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Typography,
  Paper,
  Avatar,
  Box,
  Button,
  Modal,
  Fade,
  Backdrop,
  Grid,
} from '@mui/material';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';


// Register the necessary ChartJS components
ChartJS.register(ArcElement, Tooltip, Legend);


const AttendanceReport = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);


  useEffect(() => {
    const fetchAttendanceReport = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No authentication token found.');
        setLoading(false);
        return;
      }


      try {
        const response = await fetch('https://api.myntask.com/report/attendance', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });


        if (!response.ok) {
          throw new Error(`Network response was not ok. Status: ${response.status}`);
        }


        const data = await response.json();
        setAttendanceData(data);
      } catch (error) {
        setError('Failed to fetch attendance report. Please try again later.');
      } finally {
        setLoading(false);
      }
    };


    fetchAttendanceReport();
  }, []);


  const handleOpenModal = (employee) => {
    setSelectedEmployee(employee);
    setOpenModal(true);
  };


  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedEmployee(null);
  };


  const generateChartData = (employee) => {
    return {
      labels: ['Present Days', 'Absent Days', 'Half Days', 'Late Days'],
      datasets: [
        {
          label: 'Attendance Summary',
          data: [
            employee.total_present_days,
            employee.total_absent_days,
            employee.total_half_days,
            employee.total_late_days,
          ],
          backgroundColor: ['#4caf50', '#f44336', '#ff9800', '#2196f3'],
          hoverOffset: 4,
        },
      ],
    };
  };


  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
        <CircularProgress />
      </Box>
    );
  }


  if (error) {
    return <Typography color="error">{error}</Typography>;
  }


  return (
    <Container component="main" maxWidth="lg" sx={{ padding: '8px' }}>
      <Typography variant="h5" align="center" gutterBottom sx={{ fontSize: '1.25rem', marginBottom: '16px' }}>
        Attendance Report
      </Typography>
      <TableContainer component={Paper} sx={{ padding: '8px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: '0.75rem', padding: '6px' }}>Avatar</TableCell>
              <TableCell sx={{ fontSize: '0.75rem', padding: '6px' }}>Name</TableCell>
              <TableCell sx={{ fontSize: '0.75rem', padding: '6px' }}>Designation</TableCell>
              <TableCell sx={{ fontSize: '0.75rem', padding: '6px' }}>Present Days</TableCell>
              <TableCell sx={{ fontSize: '0.75rem', padding: '6px' }}>Absent Days</TableCell>
              <TableCell sx={{ fontSize: '0.75rem', padding: '6px' }}>Half Days</TableCell>
              <TableCell sx={{ fontSize: '0.75rem', padding: '6px' }}>Late Days</TableCell>
              <TableCell sx={{ fontSize: '0.75rem', padding: '6px' }}>Total Time Spent</TableCell>
              <TableCell sx={{ fontSize: '0.75rem', padding: '6px' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attendanceData.map((employee) => (
              <TableRow key={employee.user_id}>
                <TableCell sx={{ padding: '6px' }}>
                  <Avatar src={employee.user_image} alt={employee.user_name} sx={{ width: 30, height: 30 }} />
                </TableCell>
                <TableCell sx={{ fontSize: '0.75rem', padding: '6px' }}>{employee.user_name}</TableCell>
                <TableCell sx={{ fontSize: '0.75rem', padding: '6px' }}>{employee.designation}</TableCell>
                <TableCell sx={{ fontSize: '0.75rem', padding: '6px' }}>{employee.total_present_days}</TableCell>
                <TableCell sx={{ fontSize: '0.75rem', padding: '6px' }}>{employee.total_absent_days}</TableCell>
                <TableCell sx={{ fontSize: '0.75rem', padding: '6px' }}>{employee.total_half_days}</TableCell>
                <TableCell sx={{ fontSize: '0.75rem', padding: '6px' }}>{employee.total_late_days}</TableCell>
                <TableCell sx={{ fontSize: '0.75rem', padding: '6px' }}>{employee.total_time_spent}</TableCell>
                <TableCell sx={{ fontSize: '0.75rem', padding: '6px' }}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleOpenModal(employee)}
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


      {/* Modal for Viewing Employee Details */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={openModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '90%', // Default responsive width
              maxWidth: 600, // Maximum width for larger screens
              bgcolor: 'background.paper',
              borderRadius: '8px',
              boxShadow: 24,
              p: 4,
              overflow: 'auto',
              '@media (max-width:600px)': {
                width: '75%', // Adjust width for very small screens
                maxWidth: 'none', // Remove maximum width restriction on very small screens
              },
            }}
          >
            {/* Close Button */}
            <Button
              onClick={handleCloseModal}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                minWidth: 'auto',
                padding: '4px',
              }}
            >
              X
            </Button>


            {selectedEmployee && (
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6}>
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <Avatar
                      src={selectedEmployee.user_image}
                      alt={selectedEmployee.user_name}
                      sx={{
                        width: 150, // Avatar width
                        height: 150, // Avatar height
                        marginBottom: '16px', // Space between the image and text
                      }}
                    />
                    <Typography variant="h6" align="center">
                      {selectedEmployee.user_name}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" align="center">
                      {selectedEmployee.designation}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Pie
                    data={generateChartData(selectedEmployee)}
                    options={{
                      plugins: {
                        legend: {
                          position: 'bottom',
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
};


export default AttendanceReport;








