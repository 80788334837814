import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';  // Import useNavigate


const ExpenseReport = () => {
  const [expenses, setExpenses] = useState([]);
  const [totalExpense, setTotalExpense] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);


  const navigate = useNavigate();  // Initialize useNavigate


  useEffect(() => {
    const fetchExpenseReport = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('authToken');
        const response = await fetch('https://api.myntask.com/report/expense', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });


        if (!response.ok) {
          throw new Error('Failed to fetch expense report');
        }


        const data = await response.json();
        setTotalExpense(data.totalExpense);
        setExpenses(data.expenses);
      } catch (error) {
        console.error('Error fetching expense report:', error);
        setError('Failed to load expense report');
      } finally {
        setLoading(false);
      }
    };


    fetchExpenseReport();
  }, []);


  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };


  const handleExpenseClick = (expenseId) => {
    navigate(`/view-expense/${expenseId}`);  // Navigate to the view-expense screen with expense ID
  };


  const filteredExpenses = expenses.filter((expense) =>
    Object.values(expense).some((value) =>
      value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentExpenses = filteredExpenses.slice(indexOfFirstItem, indexOfLastItem);


  const totalPages = Math.ceil(filteredExpenses.length / itemsPerPage);


  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };


  if (loading) {
    return <div>Loading...</div>;
  }


  if (error) {
    return <div>{error}</div>;
  }


  return (
    <div style={styles.container}>
      <div style={styles.row}>
        <div style={styles.card}>
          <p style={styles.total}>Total Expense</p>
          <p style={styles.total}>{totalExpense}</p>
        </div>


        <input
          type="text"
          placeholder="Search expenses..."
          value={searchTerm}
          onChange={handleSearch}
          style={styles.searchBar}
        />
      </div>


      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>Item Name</th>
            <th style={styles.th}>Price</th>
            <th style={styles.th}>Purchase Date</th>
            <th style={styles.th}>Purchase From</th>
            <th style={styles.th}>Status</th>
            <th style={styles.th}>User</th>
            <th style={styles.th}>Designation</th>
            <th style={styles.th}>Bill</th>
          </tr>
        </thead>
        <tbody>
          {currentExpenses.map((expense) => (
            <tr key={expense.expense_id}>
              <td
                style={{ ...styles.td, cursor: 'pointer', color: '#007bff' }}  // Add cursor pointer and link color
                onClick={() => handleExpenseClick(expense.expense_id)}  // Handle click
              >
                {expense.item_name}
              </td>
              <td style={styles.td}>{expense.price}</td>
              <td style={styles.td}>{new Date(expense.purchase_date).toLocaleDateString()}</td>
              <td style={styles.td}>{expense.purchase_from || 'N/A'}</td>
              <td style={styles.td}>{expense.expense_status}</td>
              <td style={styles.td}>
                <img
                  src={expense.user_image}
                  alt={expense.user_name}
                  style={styles.userImage}
                />
                {expense.user_name}
              </td>
              <td style={styles.td}>{expense.designation}</td>
              <td style={styles.td}>
                {expense.bill ? (
                  <a href={expense.bill} target="_blank" rel="noopener noreferrer">
                    View Bill
                  </a>
                ) : (
                  'N/A'
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>


      <div style={styles.pagination}>
        <button
          onClick={() => paginate(currentPage - 1)}
          style={styles.pageButton}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span style={styles.pageNumber}>{`Page ${currentPage} of ${totalPages}`}</span>
        <button
          onClick={() => paginate(currentPage + 1)}
          style={styles.pageButton}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};


const styles = {
  container: {
    width: '90%',
    margin: '0 auto',
    textAlign: 'center',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  card: {
    display: 'flex',           // Use flexbox
    justifyContent: 'space-between', // Space between items
    alignItems: 'center',      // Align items vertically in the center
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: '#fff',
    width: '400px',
    height: 'auto',
  },
  total: {
    margin: 0,                // Remove default margin
    fontSize: '16px',         // Adjust font size as needed
    fontWeight: 'bold',       // Optional: Make the text bold
    color: '#7F85FF'
  },
  searchBar: {
    padding: '8px',
    width: '300px',
    borderRadius: '4px',
    border: '1px solid #ddd',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '20px',
  },
  th: {
    border: '1px solid #ddd',
    padding: '8px',
    backgroundColor: '#7F85FF',
    textAlign: 'left',
  },
  td: {
    border: '1px solid #ddd',
    padding: '8px',
  },
  userImage: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    marginRight: '8px',
    verticalAlign: 'middle',
  },
  pagination: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pageButton: {
    padding: '8px 16px',
    margin: '0 8px',
    border: '1px solid #ddd',
    backgroundColor: '#f2f2f2',
    cursor: 'pointer',
    borderRadius: '4px',
  },
  pageNumber: {
    margin: '0 8px',
  },
};


export default ExpenseReport;


