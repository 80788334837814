

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';




const EditTaskModal = () => {
  const { taskId } = useParams();
  const navigate = useNavigate();

  const [taskData, setTaskData] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [projects, setProjects] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [labels, setLabels] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [members, setMembers] = useState([]);




  useEffect(() => {
    const fetchTaskData = async () => {
      if (!taskId) return;




      try {
        const response = await fetch(`https://api.myntask.com/task/edit/${taskId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        });




        if (!response.ok) {
          throw new Error('Failed to fetch task data');
        }




        const data = await response.json();
        setTaskData(data[0]); // Assuming the API returns an array
      } catch (error) {
        setError(error.message);
      }
    };




    fetchTaskData();
  }, [taskId]);




  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTaskData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };








  useEffect(() => {
    const fetchData = async (url, setter) => {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setter(data || []);
      } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
        setter([]);
      }
    };
    fetchData('https://api.myntask.com/task/task_category', setCategories);
    fetchData('https://api.myntask.com/task/project_list', setProjects);
    fetchData('https://api.myntask.com/employee/member', setMembers);
    fetchData('https://api.myntask.com/task/task_status', setStatuses);
  }, []);








  useEffect(() => {
    if (taskData?.project_id) {
      const fetchLabels = async () => {
        const token = localStorage.getItem('authToken');
        try {
          const response = await fetch(`https://api.myntask.com/task/task_label/${taskData.project_id}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setLabels(data || []);
        } catch (error) {
          console.error('Error fetching labels:', error);
          setLabels([]);
        }
      };
      const fetchMilestones = async () => {
        const token = localStorage.getItem('authToken');
        try {
          const response = await fetch(`https://api.myntask.com/project/milestone_list/${taskData.project_id}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setMilestones(data || []);
        } catch (error) {
          console.error('Error fetching milestones:', error);
          setMilestones([]);
        }
      };
      fetchLabels();
      fetchMilestones();
    }
  }, [taskData?.project_id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!taskData) return;




    setLoading(true);




    try {
      const response = await fetch('https://api.myntask.com/task/update', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify(taskData),
      });




      if (!response.ok) {
        throw new Error('Failed to update task');
      }




      // Navigate back to the task list or task detail page on success
      navigate('/tasks'); // Adjust this route as needed
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };




  const handleClose = () => {
    navigate('/task'); // Navigate back to the task screen
  };




  if (!taskData) return <p>Loading...</p>;




  return (
    <div className="formContainer">
      <ToastContainer />
      <h3 className="center-heading">Edit Task</h3>
      {error && <p className="error">Error: {error}</p>}
      <form className="employeeForm" onSubmit={handleSubmit}>
        <div className="container1">
          <label htmlFor="title">Title</label>
          <input
            className="field"
            type="text"
            id="title"
            name="title"
            value={taskData.title || ''}
            onChange={handleInputChange}
          />
        </div>
        <div className="container1">
          <label htmlFor="task_point">Task Point</label>
          <input
            className="field"
            type="text"
            id="task_point"
            name="task_point"
            value={taskData.task_point || ''}
            onChange={handleInputChange}
          />
        </div>
        <div className="container1">
          <label htmlFor="start_date">Start Date</label>
          <input
            className="field"
            type="date"
            id="start_date"
            name="start_date"
            value={taskData.start_date ? new Date(taskData.start_date).toISOString().split('T')[0] : ''}
            onChange={handleInputChange}
          />
        </div>
        <div className="container1">
          <label htmlFor="due_date">Due Date</label>
          <input
            className="field"
            type="date"
            id="due_date"
            name="due_date"
            value={taskData.due_date ? new Date(taskData.due_date).toISOString().split('T')[0] : ''}
            onChange={handleInputChange}
          />
        </div>
        <div className="container1">
          <label>Task Category:</label>
          <select
            className="drop"
            value={taskData.task_category_id || ''}
            onChange={(e) => setTaskData({ ...taskData, task_category_id: e.target.value ? parseInt(e.target.value, 10) : null })}
          >
            <option value="">Select Category</option>
            {categories.map(category => (
              <option key={category.id} value={category.id}>
                {category.category_name}
              </option>
            ))}
          </select>
        </div>
        <div className="container1">
          <label>Project:</label>
          <select
            className="drop"
            value={taskData.project_id || ''}
            onChange={(e) => setTaskData({ ...taskData, project_id: e.target.value ? parseInt(e.target.value, 10) : null })}
          >
            <option value="">Select Project</option>
            {projects.map(project => (
              <option key={project.id} value={project.id}>
                {project.project_name}
              </option>
            ))}
          </select>
        </div>
        <div className="container1">
          <label>Assigned To:</label>
          <select
            className="drop"
            value={taskData.assigned_to || ''}
            onChange={(e) => setTaskData({ ...taskData, assigned_to: e.target.value ? parseInt(e.target.value, 10) : null })}
          >
            <option value="">Select Member</option>
            {members.map(member => (
              <option key={member.id} value={member.id}>
                {member.member_name}
              </option>
            ))}
          </select>
        </div>
        <div className="container1">
          <label>Label:</label>
          <select
            className="drop"
            value={taskData.label_id || ''}
            onChange={(e) => setTaskData({ ...taskData, label_id: e.target.value ? parseInt(e.target.value, 10) : null })}
          >
            <option value="" disabled>Select Label</option>
            {labels.map((label) => (
              <option key={label.id} value={label.id}>
                {label.label_name}
              </option>
            ))}
          </select>
        </div>
        <div className="container1">
          <label>Milestone:</label>
          <select
            className="drop"
            value={taskData.milestone_id || ''}
            onChange={(e) => setTaskData({ ...taskData, milestone_id: e.target.value ? parseInt(e.target.value, 10) : null })}
          >
            <option value="">Select Milestone</option>
            {milestones.map(milestone => (
              <option key={milestone.id} value={milestone.id}>
                {milestone.milestone_title}
              </option>
            ))}
          </select>
        </div>
        <div className="container1">
          <label htmlFor="description">Description</label>
          <textarea
            className="field"
            id="description"
            name="description"
            value={taskData.description || ''}
            onChange={handleInputChange}
          />
        </div>
        <div className="container1">
          <label htmlFor="priority">Priority</label>
          <select
            className="drop"
            id="priority"
            name="priority"
            value={taskData.priority || ''}
            onChange={handleInputChange}
          >
            <option value="">Select Priority</option>
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">High</option>
          </select>
        </div>

        <div className="container1">
          <label>Status ID:</label>
          <select
            className="drop"
            value={taskData.status_id || ''}
            onChange={(e) => setTaskData({ ...taskData, status_id: e.target.value ? parseInt(e.target.value, 10) : null })}
          >
            <option value="" disabled>Select Status</option>
            {statuses.map((status) => (
              <option key={status.id} value={status.id}>
                {status.status}
              </option>
            ))}
          </select>
        </div>
        <div className="container1">
          <label htmlFor="private">Private</label>
          <input
            type="checkbox"
            id="private"
            name="private"
            checked={taskData.private === 1}
            onChange={(e) => setTaskData(prevData => ({
              ...prevData,
              private: e.target.checked ? 1 : 0
            }))}
          />
        </div>
        <div className="container1">
          <label htmlFor="billable">Billable</label>
          <input
            type="checkbox"
            id="billable"
            name="billable"
            checked={taskData.billable === 1}
            onChange={(e) => setTaskData(prevData => ({
              ...prevData,
              billable: e.target.checked ? 1 : 0
            }))}
          />
        </div>


        <button type="submit" disabled={loading} className="submit-button">
          {loading ? 'Saving...' : 'Save Changes'}
        </button>
      </form>
    </div>
  );
};




export default EditTaskModal;















































