import React, { useEffect, useState } from "react";
import { MdOutlineStarHalf, MdOutlineStarPurple500 } from 'react-icons/md';
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom"; // Import useNavigate instead of useHistory
import "./Attendance.css";
import defaultImage from "../assets/vector.jpeg";
import { FaCheck } from "react-icons/fa6";
import { ToastContainer } from "react-toastify";
import { FaStarHalfAlt } from "react-icons/fa";
import Modal from "../Components/AttendanceMark";


const Attendance = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState("");
  const [designations, setDesignations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedMember, setSelectedMember] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const navigate = useNavigate(); // Use useNavigate for navigation
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAttendanceOpen, setAttendanceOpen] = useState(false);
  const [selectedAttendance, setSelectedAttendance] = useState(null);
  const [attendanceDetails, setAttendanceDetails] = useState(null);




  const handleCellClick = async (attendanceData) => {
    if (attendanceData.attendance_id) {
      setSelectedAttendance(attendanceData);
      setAttendanceOpen(true);
     
      // Fetch attendance details
      try {
        const response = await fetch(`https://api.myntask.com/attendance/attendance_detail/${attendanceData.attendance_id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json',
          },
        });
       
        if (!response.ok) {
          throw new Error('Failed to fetch attendance details');
        }
       
        const data = await response.json();
        setAttendanceDetails(data);
      } catch (error) {
        console.error("Error fetching attendance details:", error);
        setAttendanceDetails(null);
      }
    }
  };


  const closeModal = () => {
    setAttendanceOpen(false);
    setSelectedAttendance(null);
    setAttendanceDetails(null); // Reset attendance details
  };


  const handleMarkAttendanceClick = () => {
    navigate('/attendance-mark'); // Navigate to the mark attendance page
  };
 


  useEffect(() => {
    const fetchData = async (url, setter) => {
      const token = localStorage.getItem("authToken");
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setter(data || []);
      } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
        setter([]);
      }
    };


    fetchData("https://api.myntask.com/employee/designation", setDesignations);
    fetchData("https://api.myntask.com/employee/department", setDepartments);
    fetchData("https://api.myntask.com/employee/member", setMembers);
  }, []);


  useEffect(() => {
    const fetchAttendanceData = async () => {
      const token = localStorage.getItem("authToken");


      if (!token) {
        setError("No token found. Please log in.");
        return;
      }


      try {
        const url = `https://api.myntask.com/attendance/list?employee_id=${selectedMember}&department_id=${selectedDepartment}&designation_id=${selectedDesignation}&month=${selectedMonth}&year=${selectedYear}`;


        const response = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });


        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(`Failed to fetch attendance data: ${response.status} - ${errorMessage}`);
        }


        const data = await response.json();
        setAttendanceData(data);
        setFilteredData(data);
      } catch (error) {
        console.error("Error fetching attendance data:", error);
        setError(`Failed to fetch attendance data: ${error.message}`);
      }
    };


    fetchAttendanceData();
  }, [selectedDesignation, selectedDepartment, selectedMember, selectedMonth, selectedYear]);


  useEffect(() => {
    const filtered = attendanceData.filter((entry) => {
      const nameMatch = entry.name.toLowerCase().includes(searchQuery.toLowerCase());
      const attendanceMatch = Object.values(entry.attendance).some((day) =>
        day.present.toLowerCase().includes(searchQuery.toLowerCase())
      );
      return nameMatch || attendanceMatch;
    });
    setFilteredData(filtered);
  }, [searchQuery, attendanceData]);


  const handleLocationClick = (locationUrl) => {
    if (locationUrl) {
      window.open(locationUrl, "_blank");
    } else {
      alert("Location URL is not available.");
    }
  };


  const getAttendanceIcon = (attendanceStatus, dayData) => {
    // Check if dayData is defined and dayKey exists
    if (dayData && dayData.dayKey && dayData.dayKey.includes('Sun')) {
      return <MdOutlineStarPurple500 className="attendance-icon sunday-icon" />;
    }
 
    // Check if it's a half-day
    if (dayData && dayData.half_day === "yes") {
      return <FaStarHalfAlt  className="attendance-icon half-day-icon" />;
    }
 
    // Return normal attendance icons based on attendance status
    if (attendanceStatus === "yes") {
      return <FaCheck className="attendance-icon present-icon" />;
    } else if (attendanceStatus === "no") {
      return <RxCross2 className="attendance-icon absent-icon" />;
    } else {
      return <span>--</span>;
    }
  };
 
 


  const handleMapView = () => {
    navigate("/map"); // Use navigate instead of history.push
  };


  return (
    <div className="map-container">
     <ToastContainer />
    <div className="top-controls">
      <h1 className='title'>Attendance
        <div className="underline"></div>
      </h1>
 
      <div className="buttons-row">
       
            <button onClick={handleMapView} className="color-button">
              Map View
            </button>
       
            <button className="color-button" onClick={handleMarkAttendanceClick}>
             Mark Attendance
            </button>


      </div>
    </div>
      {/* Search Bar and Dropdown Filters */}
      <div className="top-controls">
      <div className="filters-and-search">
       
        <select
          className="border-button"
          value={selectedDesignation}
          onChange={(e) => setSelectedDesignation(e.target.value)}
        >
          <option value="">Designation</option>
          {designations.map((designation) => (
            <option key={designation.id} value={designation.id}>
              {designation.name}
            </option>
          ))}
        </select>
        <select
          className="border-button"
          value={selectedDepartment}
          onChange={(e) => setSelectedDepartment(e.target.value)}
        >
          <option value="">Department</option>
          {departments.map((department) => (
            <option key={department.id} value={department.id}>
              {department.department}
            </option>
          ))}
        </select>
        <select
          className="border-button"
          value={selectedMember}
          onChange={(e) => setSelectedMember(e.target.value)}
        >
          <option value="">Employee</option>
          {members.map((member) => (
            <option key={member.id} value={member.id}>
              {member.member_name}
            </option>
          ))}
        </select>
        <select
          className="border-button"
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e.target.value)}
        >
          <option value="">Month</option>
          {Array.from({ length: 12 }, (_, i) => (
            <option key={i + 1} value={i + 1}>
              {new Date(0, i).toLocaleString("en", { month: "long" })}
            </option>
          ))}
        </select>
        <select
          className="border-button"
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value)}
        >
          <option value="">Year</option>
          {Array.from({ length: 5 }, (_, i) => (
            <option key={i} value={2023 - i}>
              {2023 - i}
            </option>
          ))}
        </select>
        <input
          type="text"
          className="searchbar"
          placeholder="Search by name..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      </div>


      <div
  className="attendance-grid"
  style={{
    '--attendance-column-count': `1fr repeat(${filteredData.length > 0 ? filteredData[0].totalDays : 0}, 1fr)`
  }}
>
  <div className="attendance-header-row">
    <div className="attendance-header-cell">Employee</div>
    {filteredData.length > 0 &&
      Object.keys(filteredData[0].attendance).map((dayKey, index) => (
        <div key={index} className="attendance-header-cell">
          {dayKey}
        </div>
      ))}
  </div>
  {filteredData.length > 0 ? (
    filteredData.map((entry, index) => (
      <div key={index} className="attendance-row">
        <div className="attendance-cell name-cell">
          <img
            src={entry.image || defaultImage}
            alt={entry.name}
            className="user-image"
          />
          <div>
            <div>{entry.name}</div>
            <div className="employee-title">{entry.title}</div>
          </div>
        </div>
        {Object.keys(entry.attendance).map((dayKey, dayIndex) => {
              const attendanceData = entry.attendance[dayKey];
              return (
                <div
                  key={dayIndex}
                  className="attendance-cell"
                  onClick={() => handleCellClick(attendanceData)} // Click handler
                >
                  {getAttendanceIcon(attendanceData?.present, { dayKey, ...attendanceData })}
                </div>
              );
         })}


      </div>
    ))
  ) : (
    <div className="attendance-row">
      <div className="attendance-cell">No data available</div>
    </div>
  )}
</div>




 {/* Modal for attendance details */}
 {isAttendanceOpen && (
 <div className="attendanceModal">
 <div className="attendanceModal-content">
   
   {attendanceDetails ? (
    <>
    <div className="top-controls">
     <h2 className="title">Attendance Details</h2>
     <img
       src={attendanceDetails.user_image}
       alt={attendanceDetails.user_name}
       className="user-image"
     />
   </div>
     <div className="details-container">
       <div className="details-row">
         <strong>Name:</strong>
         <span>{attendanceDetails.user_name}</span>
       </div>
       <div className="details-row">
         <strong>Designation:</strong>
         <span>{attendanceDetails.designation}</span>
       </div>
       <div className="details-row">
         <strong>Date:</strong>
         <span>{attendanceDetails.date || "N/A"}</span>
       </div>
       <div className="details-row">
         <strong>Location:</strong>
         <span>{attendanceDetails.location || "N/A"}</span>
       </div>
       <div className="details-row">
         <strong>Clock In:</strong>
         <span>{attendanceDetails.clock_in || "N/A"}</span>
       </div>
       <div className="details-row">
         <strong>Clock Out:</strong>
         <span>{attendanceDetails.clock_out || "N/A"}</span>
       </div>
       <div className="details-row">
         <strong>Total Time:</strong>
         <span>{attendanceDetails.total_time || "N/A"}</span>
       </div>
     </div>
     <button className="color-button" onClick={closeModal}>Close</button>
    </>
   ) : (
     <p>Loading attendance details...</p>
   )}
 
 </div>
</div>




)}






    </div>
  );
};


export default Attendance;




   
  
