import React, { useState, useEffect } from 'react';
import './AddProjectPage.css';
import { Checkbox, FormControlLabel, Select, MenuItem, InputLabel, FormControl, Modal, Button, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import 'react-toastify/dist/ReactToastify.css';

const AddProjectPage = () => {
  const navigate = useNavigate();

  const [newProject, setNewProject] = useState({
    project_name: '',
    start_date: '',
    deadline: '',
    category_id: '',
    department_id: '',
    client_id: '',
    project_summary: '',
    notes: '',
    public_project: 0,
    currency_id: '',
    project_budget: '',
    total_points: '',
    project_member: [],
  });

  const [categories, setCategories] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [members, setMembers] = useState([]);
  const [clients, setClients] = useState([]);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [openDeleteCategoryModal, setOpenDeleteCategoryModal] = useState(false);

  const fetchData = async (url, setter) => {
    const token = localStorage.getItem('authToken');
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setter(data || []);
    } catch (error) {
      console.error(`Error fetching data from ${url}:`, error);
      setter([]);
    }
  };

  useEffect(() => {
    fetchData('https://api.myntask.com/project/category', setCategories);
    fetchData('https://api.myntask.com/employee/department', setDepartments);
    fetchData('https://api.myntask.com/company/currency', setCurrency);
    fetchData('https://api.myntask.com/employee/member', setMembers);
    fetchData('https://api.myntask.com/project/client', setClients);
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewProject((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? (checked ? 1 : 0) : value,
    }));
  };

  const handleMemberChange = (e) => {
    setNewProject((prevData) => ({
      ...prevData,
      project_member: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('authToken');

    try {
      const response = await fetch('https://api.myntask.com/project/save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newProject),
      });

      if (!response.ok) {
        let errorText;
        try {
          errorText = await response.json();
        } catch {
          errorText = await response.text();
        }
        throw new Error(`Failed to save project: ${response.status} - ${errorText}`);
      }

      toast.success('Project saved successfully!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setTimeout(() => {
        navigate('/project');
      }, 2000);
    } catch (error) {
      console.error('Error saving project:', error);
      toast.error('Error saving project. Please try again.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleAddCategory = () => {
    setOpenCategoryModal(true);
  };

  const handleDeleteCategoryModalOpen = () => {
    setOpenDeleteCategoryModal(true);
  };

  const handleSaveCategory = async () => {
    const token = localStorage.getItem('authToken');
    try {
      const response = await fetch('https://api.myntask.com/project/project_category_add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ name: newCategory }),
      });

      if (!response.ok) {
        throw new Error('Failed to add category');
      }

      toast.success('Category added successfully!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setOpenCategoryModal(false);
      setNewCategory('');
      fetchData('https://api.myntask.com/project/category', setCategories);
    } catch (error) {
      console.error('Error adding category:', error);
      toast.error('Error adding category. Please try again.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    if (window.confirm('Are you sure you want to delete this category?')) {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch(`https://api.myntask.com/project/project_category_delete/${categoryId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to delete category');
        }

        toast.success('Category deleted successfully!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        fetchData('https://api.myntask.com/project/category', setCategories);
      } catch (error) {
        console.error('Error deleting category:', error);
        toast.error('Error deleting category. Please try again.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  return (
    <div className="formContainer">
      <ToastContainer />
      <h3 className="center-heading">Add Project</h3>

      <form className="employeeForm" onSubmit={handleSubmit}>


        <div className="container1">
          <label className="label">Project Name</label>
          <input
            className="field"
            type="text"
            id="project_name"
            name="project_name"
            value={newProject.project_name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="container1">
          <label className="label">Start Date</label>
          <input
            className="field"
            type="date"
            id="start_date"
            name="start_date"
            value={newProject.start_date}
            onChange={handleChange}
          />
        </div>

        <div className="container1">
          <label className="label">Deadline</label>
          <input
            className="field"
            type="date"
            id="deadline"
            name="deadline"
            value={newProject.deadline}
            onChange={handleChange}
          />
        </div>

        <div className="container1">
          <label className="label">Department</label>
          <select
            className="drop"
            id="department_id"
            name="department_id"
            value={newProject.department_id}
            onChange={handleChange}
            required
          >
            <option value="" disabled>Select Department</option>
            {departments.map((department) => (
              <option key={department.id} value={department.id}>
                {department.department}
              </option>
            ))}
          </select>
        </div>

        <div className="container1">
          <label className="label">Client</label>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <select
              className="drop"
              id="client_id"
              name="client_id"
              value={newProject.client_id}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Select Client
              </option>
              {clients.map((client) => (
                <option key={client.client_id} value={client.client_id}>
                  {client.client_name}
                </option>
              ))}
            </select>
            <button type="button" className="add-client-button" onClick={() => navigate('/addClient')}>
              Add Client
            </button>
          </div>
        </div>
        <div className="container1">
          <label className="label">Category</label>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <select
              className="drop"
              id="category_id"
              name="category_id"
              value={newProject.category_id}
              onChange={handleChange}
              required
            >
              <option value="" disabled>Select Category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.category_name}
                </option>
              ))}
            </select>
            <button type="button" className="add-category-button" onClick={handleAddCategory}>
              Add Category
            </button>
            <button type="button" className="delete-category-button" onClick={handleDeleteCategoryModalOpen}>
              Delete Category
            </button>
          </div>
        </div>
        <div className="container1">
          <label className="label">Project Summary</label>
          <input
            className="field"
            type="text"
            id="project_summary"
            name="project_summary"
            value={newProject.project_summary}
            onChange={handleChange}
          />
        </div>

        <div className="container1">
          <label className="label">Notes</label>
          <input
            className="field"
            type="text"
            id="notes"
            name="notes"
            value={newProject.notes}
            onChange={handleChange}
          />
        </div>

        <div className="container1">
          <FormControlLabel
            control={
              <Checkbox
                name="public_project"
                checked={!!newProject.public_project}
                onChange={handleChange}
                color="primary"
              />
            }
            label="Public Project"
          />
        </div>

        <div className="container1">
          <label className="label">Currency</label>
          <select
            className="drop"
            id="currency_id"
            name="currency_id"
            value={newProject.currency_id}
            onChange={handleChange}
            required
          >
            <option value="" disabled>Select Currency</option>
            {currency.map((cur) => (
              <option key={cur.id} value={cur.id}>
                {cur.currency_code}
              </option>
            ))}
          </select>
        </div>

        <div className="container1">
          <label className="label">Project Budget</label>
          <input
            className="field"
            type="number"
            id="project_budget"
            name="project_budget"
            value={newProject.project_budget}
            onChange={handleChange}
            required
          />
        </div>

        <div className="container1">
          <label className="label">Total Points</label>
          <input
            className="field"
            type="number"
            id="total_points"
            name="total_points"
            value={newProject.total_points}
            onChange={handleChange}
            required
          />
        </div>

        <div className="container1">
          <FormControl fullWidth>
            <InputLabel id="project-member-label">Project Member</InputLabel>
            <Select
              labelId="project-member-label"
              id="project_member"
              name="project_member"
              multiple
              value={newProject.project_member}
              onChange={handleMemberChange}
              renderValue={(selected) =>
                members
                  .filter((member) => selected.includes(member.id))
                  .map((member) => member.member_name)
                  .join(', ')
              }
            >
              {members.map((member) => (
                <MenuItem key={member.id} value={member.id}>
                  {member.member_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <button type="submit" className="submit-button">Add Project</button>
      </form>

      {/* Modal for Adding Category */}
      <Modal open={openCategoryModal} onClose={() => setOpenCategoryModal(false)}>
        <div className="modal-container">
          <h2>Add Category</h2>
          <div className="form-group">
            <input
              type="text"
              name="newCategory"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              placeholder="Category Name"
              required
              style={{ width: '100%', padding: '10px', marginBottom: '20px' }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="contained" color="primary" onClick={handleSaveCategory}>
              Save
            </Button>
            <Button variant="outlined" onClick={() => setOpenCategoryModal(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      {/* Modal for Deleting Category */}
      <Modal open={openDeleteCategoryModal} onClose={() => setOpenDeleteCategoryModal(false)}>
        <div className="modal-container delete-modal">
          <h2>Delete Category</h2>
          <div className="category-list delete-category-list">
            {categories.map((category) => (
              <div key={category.id} className="category-item">
                <span className="category-name">{category.category_name}</span>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDeleteCategory(category.id)}
                  color="secondary"
                  className="delete-button"
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <Button variant="outlined" onClick={() => setOpenDeleteCategoryModal(false)}>
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddProjectPage;
