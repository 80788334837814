import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const HRDashboard = () => {
  const data = {
    labels: ['HR', 'Finance', 'Engineering', 'Sales', 'Marketing', 'Support'],
    datasets: [
      {
        label: 'Department-wise Employees',
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
        borderColor: '#FFFFFF',
        borderWidth: 1,
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
        hoverBorderColor: '#FFFFFF',
        data: [20, 15, 30, 25, 10, 18],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  return (
    <div style={styles.dashboardContainer}>
      <h1 style={styles.dashboardTitle}>HR Dashboard</h1>
      
      <div style={styles.summaryContainer}>
        <div style={styles.summaryCard}>
          <h3>Total Employees</h3>
          <p>123</p>
        </div>
        <div style={styles.summaryCard}>
          <h3>Today's Attendance</h3>
          <p>95</p>
        </div>
        <div style={styles.summaryCard}>
          <h3>Leaves Approved</h3>
          <p>10</p>
        </div>
        <div style={styles.summaryCard}>
          <h3>Employee Exits</h3>
          <p>2</p>
        </div>
      </div>

      <div style={styles.chartContainer}>
        <h2>Department-wise Employee Distribution</h2>
        <div style={styles.barChart}>
          <Bar data={data} options={options} />
        </div>
      </div>

      <div style={styles.detailsContainer}>
        <div style={styles.detailsCard}>
          <h3>Gender Distribution</h3>
          <p>Male: 70 | Female: 53</p>
        </div>
        <div style={styles.detailsCard}>
          <h3>Average Attendance</h3>
          <p>85%</p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  dashboardContainer: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f0f4f8',
    minHeight: '100vh',
  },
  dashboardTitle: {
    textAlign: 'center',
    color: '#333',
    marginBottom: '30px',
  },
  summaryContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '30px',
  },
  summaryCard: {
    flex: 1,
    margin: '0 10px',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
  },
  chartContainer: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: '30px',
  },
  barChart: {
    width: '100%',
    height: '300px',
  },
  detailsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  detailsCard: {
    flex: 1,
    margin: '0 10px',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
  },
};

export default HRDashboard;
