import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';


const ViewExpense = () => {
  const { expenseId } = useParams(); // Get the expenseId from the URL
  const [expenseDetails, setExpenseDetails] = useState(null);
  const navigate = useNavigate(); // For navigation


  useEffect(() => {
    if (expenseId) {
      const fetchExpenseDetails = async () => {
        const token = localStorage.getItem('authToken');
        if (!token) {
          return;
        }


        try {
          const response = await fetch(`https://api.myntask.com/expenses/view/${expenseId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });


          if (!response.ok) {
            throw new Error('Failed to fetch expense details');
          }


          const data = await response.json();
          setExpenseDetails(data);
        } catch (err) {
          console.error('Error fetching expense details:', err);
        }
      };


      fetchExpenseDetails();
    }
  }, [expenseId]);


  return (
    <div style={screenStyles.container}>
      <h2 style={{ gridColumn: '1 / span 2' }}>Expense Details</h2>
      {expenseDetails ? (
        <>
          <div><strong>Item Name:</strong></div>
          <div>{expenseDetails.item_name}</div>
          <div><strong>Price:</strong></div>
          <div>{expenseDetails.price}</div>
          <div><strong>Purchase From:</strong></div>
          <div>{expenseDetails.purchase_from}</div>
          <div><strong>Purchase Date:</strong></div>
          <div>{expenseDetails.purchase_date}</div>
          <div><strong>Project:</strong></div>
          <div>{expenseDetails.project_name}</div>
          <div><strong>Category:</strong></div>
          <div>{expenseDetails.category_name}</div>
          <div><strong>Employee Name:</strong></div>
          <div>{expenseDetails.employee_name}</div>
          <div><strong>Employee Designation:</strong></div>
          <div>{expenseDetails.employee_designation}</div>
          <div><strong>Approved By:</strong></div>
          <div>{expenseDetails.approved_user_name}</div>
          <div><strong>Approved User Designation:</strong></div>
          <div>{expenseDetails.approved_user_designation}</div>
          <div><strong>Description:</strong></div>
          <div>{expenseDetails.description}</div>
          <div><strong>Bill:</strong></div>
          <div><a href={expenseDetails.bill} target="_blank" rel="noopener noreferrer">View Bill</a></div>
          <div><strong>Employee Image:</strong></div>
          <div>
            <img
              src={expenseDetails.employee_image}
              alt={expenseDetails.employee_name}
              style={screenStyles.img}
            />
          </div>
          <div><strong>Approved User Image:</strong></div>
          <div>
            <img
              src={expenseDetails.approved_user_image}
              alt={expenseDetails.approved_user_name}
              style={screenStyles.img}
            />
          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}
      <button onClick={() => navigate(-1)} style={screenStyles.backButton}>
        Go Back
      </button>
    </div>
  );
};


const screenStyles = {
  container: {
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    gap: '10px',
    maxWidth: '600px',
    margin: '20px auto',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  },
  backButton: {
    gridColumn: '1 / span 2',
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  img: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
  },
};


export default ViewExpense;




