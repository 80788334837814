import React, { useState, useEffect } from 'react';
import { Pie, Bar } from 'react-chartjs-2';
import 'chart.js/auto'; // Required for Chart.js
import './RecruitDashboard.css'; // External CSS for styling

const RecruitDashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://api.myntask.com/recruit/dashboard', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        console.log('API Response:', data); // Log the API response
        setDashboardData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  if (!dashboardData) {
    return <div>Loading...</div>;
  }

  const { application_status, total_applications, open_jobs } = dashboardData;

  // Pie chart data for Application Status (Full-Time, Part-Time, Internship)
  const pieData = {
    labels: application_status.map((status) => status.status), // Dynamic status labels
    datasets: [
      {
        data: application_status.map((status) => status.total), // Dynamic status data
        backgroundColor: ['#5D3FD3', '#FF6F61', '#00AEEF', '#FFD700', '#90EE90'], // Color palette
        hoverBackgroundColor: ['#5033BE', '#E65B52', '#009DDB', '#EEC700', '#7FFF00'],
      },
    ],
  };

  // Bar chart data for Applications by Department
  const barData = {
    labels: total_applications.map((app) => app.title), // Dynamic job titles
    datasets: [
      {
        label: 'Applications',
        data: total_applications.map((app) => app.total_applications), // Dynamic total applications
        backgroundColor: '#784BA0', // Color for the bar
        borderColor: '#5D3FD3',
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="dashboard-container">
      <div className="cards-row">
        <div className="card">
          <h3>Total Applicants</h3>
          <p className="card-number">{dashboardData.total_application}</p>
          <span className="growth positive">+12%</span> {/* You can calculate this value dynamically */}
        </div>
        <div className="card1">
          <h3>Shortlisted Candidates</h3>
          <p className="card-number">{dashboardData.shortlisted_candidate}</p>
        </div>
        <div className="card1">
          <h3>Total Hired</h3>
          <p className="card-number">{dashboardData.total_hired}</p>
        </div>
        <div className="card1">
          <h3>Today's Interviews</h3>
          <p className="card-number">{dashboardData.today_interview}</p>
        </div>
      </div>

      <div className="charts-row">
        <div className="chart-container pie-chart">
          <h3>Analytics</h3>
          <div className="chart-wrapper">
            <Pie data={pieData} />
          </div>
        </div>
        <div className="chart-container bar-chart">
          <h3>Applications Received by Department</h3>
          <Bar data={barData} />
        </div>
      </div>

      <div className="table-section">
        <h3>Applicant Details</h3>
        <div className="applicant-table">
          <div className="table-header">
            <p>Job Title</p>
            <p>Total Applications</p>
            <p>Applied</p>
            <p>Phone Screen</p>
            <p>Interview</p>
            <p>Hired</p>
            <p>Rejected</p>
          </div>
          <div className="table-body">
            {total_applications.map((applicant) => (
              <div className="applicant-row" key={applicant.id}>
                <p>{applicant.title}</p> {/* Job Title */}
                <p>{applicant.total_applications}</p> {/* Total Applications */}
                <p>{applicant.applied}</p> {/* Applied */}
                <p>{applicant.phone_screen}</p> {/* Phone Screen */}
                <p>{applicant.interview}</p> {/* Interview */}
                <p>{applicant.hired}</p> {/* Hired */}
                <p>{applicant.rejected}</p> {/* Rejected */}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="open-jobs-section">
        <h3>Open Jobs</h3>
        <div className="open-jobs-list">
          {open_jobs.map((job, index) => (
            <div className="open-job-card" key={index}>
              <img src={job.image} alt={job.name} className="job-image" />
              <div className="job-details">
                <p className="job-title">{job.title}</p>
                <p className="job-name">{job.name}</p>
                <p className="job-designation">{job.designation}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RecruitDashboard;
