import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons
import speedLogo from '../assets/speed.png'; // Import the speed logo
import bevelImage from '../assets/bevel.png'; // Import the bevel image for promotion
import { requestForToken } from '../firebase'; // Import Firebase token request function

import './Login.css'; // Import the CSS for styling

const Login = () => {
  const navigate = useNavigate();
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [username, setUsername] = useState(''); // New state for username
  const [companyName, setCompanyName] = useState(''); // New state for company name
  const [error, setError] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false); // State for password visibility
  const [tokenFound, setTokenFound] = useState(false);

  // Request the Firebase client token when the component mounts
  useEffect(() => {
    requestForToken(setTokenFound); // Request for the token when the component mounts
  }, []);

  // Prevent navigation away from login page using the back button
  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    const handlePopState = () => {
      window.history.pushState(null, document.title, window.location.href);
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  // Handle the login logic
  const handleLogin = async () => {
    if (!email || !password) {
      setError('Please enter both email and password.');
      return;
    }

    // Fetch the client token from localStorage (this was stored earlier in firebase.js)
    const clientToken = localStorage.getItem('clientToken') || '';

    try {
      const response = await fetch('https://api.myntask.com/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          email, 
          password,
          user_token: clientToken // Pass the client token as user_token
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Login successful:', data);

      // Store various items in localStorage
      localStorage.setItem('authToken', data.token);
      localStorage.setItem('is_superadmin', data.is_superadmin);
      if (data.profile) {
        localStorage.setItem('userProfile', JSON.stringify(data.profile));
      }
      localStorage.setItem('taskCount', JSON.stringify(data.task_count));
      localStorage.setItem('workFromHome', JSON.stringify(data.work_from_home_today));
      localStorage.setItem('projectCount', JSON.stringify(data.project_count));
      localStorage.setItem('todayJoinings', JSON.stringify(data.today_joinings));
      localStorage.setItem('leaveToday', JSON.stringify(data.leave_today || []));
      localStorage.setItem('birthdays', JSON.stringify(data.birthdays || []));
      localStorage.setItem('probation', JSON.stringify(data.probation_data || []));
      localStorage.setItem('taskList', JSON.stringify(data.task_list || []));
      localStorage.setItem('notice', JSON.stringify(data.notice_list || []));
      localStorage.setItem('expenses', JSON.stringify(data.expenses || []));
      localStorage.setItem('attendanceStatus', JSON.stringify(data.attendance_status));

      // Redirect user based on their role
      if (data.is_superadmin === 1) {
        navigate('/SuperAdmin', { state: { data } });
      } else {
        navigate('/dashboard');
      }

    } catch (error) {
      console.error('Error logging in:', error);
      setError('Failed to log in. Please check your credentials and try again.');
    }
  };

  // Handle the sign-up logic
  const handleSignUp = () => {
    if (!email || !password || !confirmPassword || !username || !companyName) {
      setError('Please fill in all fields.');
      return;
    }
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
    // Add the sign-up logic here
    setIsSignUp(false);
  };

  // Switch between login and sign-up mode
  const switchToSignUp = () => {
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setUsername(''); // Clear the username field
    setCompanyName(''); // Clear the company name field
    setError('');
    setIsSignUp(true);
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible); // Toggle visibility state
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="left-side">
          <h1 className="login-title">{isSignUp ? 'Sign Up' : 'Login'} ✌️</h1>
          <p className="login-subtitle">Connect, manage, and grow — all with one CRM.</p>

          {isSignUp && (
            <>
              <input
                type="text"
                placeholder="Username"
                className="textField"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <input
                type="text"
                placeholder="Company Name"
                className="textField"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </>
          )}

          <input
            type="email"
            placeholder="Email"
            className="textField"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="passwordFieldContainer">
            <input
              type={passwordVisible ? 'text' : 'password'}
              placeholder="Enter your Password"
              className="textField"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span onClick={togglePasswordVisibility} className="eyeIcon">
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          {isSignUp && (
            <input
              type={passwordVisible ? 'text' : 'password'}
              placeholder="Confirm Password"
              className="textField"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          )}

          {error && <p className="error">{error}</p>}

          {!isSignUp && <a href="#" className="forgotPasswordLink">Forgot Password?</a>}

          <button className="button" onClick={isSignUp ? handleSignUp : handleLogin}>
            {isSignUp ? 'Sign Up' : 'Login'}
          </button>

          <p className="switch-text">
            {isSignUp ? 'Already have an account?' : "Don't have an account?"}{' '}
            <span onClick={switchToSignUp} className="switch-link">
              {isSignUp ? 'Login' : 'Sign Up'}
            </span>
          </p>
        </div>

        <div className="right-side">
          <img className="promotion-image-centered" src={bevelImage} alt="Bevel" />
        </div>
      </div>
    </div>
  );
};

export default Login;
