

import React, { useState, useEffect } from 'react';




function PurchaseOrder() {
 const [purchaseOrders, setPurchaseOrders] = useState([]);
 const [error, setError] = useState('');
 const [selectedRow, setSelectedRow] = useState(null); // For tracking selected row
 const [searchQuery, setSearchQuery] = useState(''); // State for the search query


 
 useEffect(() => {
   const fetchPurchaseOrders = async () => {
     const token = localStorage.getItem('authToken');
     if (!token) {
       setError('No authentication token found. Please log in.');
       return;
     }




     try {
       const response = await fetch('https://api.myntask.com/purchase_order/list', {
         method: 'GET',
         headers: {
           'Content-Type': 'application/json',
           'Authorization': `Bearer ${token}`,
         },
       });




       if (!response.ok) {
         throw new Error('Failed to fetch purchase orders');
       }




       const data = await response.json();
       setPurchaseOrders(data);
     } catch (error) {
       setError('Error fetching purchase orders. Please try again later.');
     }
   };




   fetchPurchaseOrders();
 }, []);




 const handleRowClick = (id) => {
   setSelectedRow(id); // Set selected row by ID
 };




 const handleDownload = async (orderId) => {
   const token = localStorage.getItem('authToken');
   if (!token) {
     setError('No authentication token found. Please log in.');
     return;
   }




   try {
     const response = await fetch(`https://api.myntask.com/purchase_order/download/${orderId}`, {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${token}`,
       },
     });




     if (!response.ok) {
       throw new Error('Failed to download purchase order');
     }




     const blob = await response.blob();
     const url = window.URL.createObjectURL(new Blob([blob]));
     const link = document.createElement('a');
     link.href = url;
     link.setAttribute('download', `purchase_order_${orderId}.pdf`);
     document.body.appendChild(link);
     link.click();
     link.parentNode.removeChild(link);
   } catch (error) {
     setError('Error downloading purchase order. Please try again later.');
   }
 };


 // Handle search input change
 const handleSearchChange = (e) => {
  setSearchQuery(e.target.value);
};


// Filter purchase orders based on the search query
const filteredOrders = purchaseOrders.filter((order) => {
  return (
    order.purchase_order_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
    order.primary_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    order.billed_status.toLowerCase().includes(searchQuery.toLowerCase())
  );
});










 return (
   <div className='main-container'>


     <div className="top-controls">
        <h1 className='title'>Purchase Orders
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
         
        <button className="color-button" onClick={() => window.location.href = 'AddOrder'}>
         Add Order
       </button>
        </div>
      </div>


      <div className="top-controls">
        <div className="filters-and-search">
        <input
        className="searchbar"
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearchChange} // Trigger search on input change
      />
        </div>
      </div>


     <table >
       <thead>
         <tr>
           <th>ID</th>
           <th>Purchase Order Number</th>
           <th>Primary Name</th>
           <th>Purchase Date</th>
           <th>Expected Delivery Date</th>
           <th>Billed Status</th>
           <th>Delivery Status</th>
           <th>Total Amount</th>
           <th>Actions</th>
         </tr>
       </thead>
       <tbody>
          {filteredOrders.map((order) => (
            <tr
              key={order.id}
              onClick={() => handleRowClick(order.id)}
              className={selectedRow === order.id ? 'active' : ''}
            >
              <td>{order.id}</td>
              <td>{order.purchase_order_number}</td>
              <td>{order.primary_name}</td>
              <td>{order.purchase_date}</td>
              <td>{order.expected_delivery_date}</td>
              <td>{order.billed_status || 'N/A'}</td>
              <td>{order.delivery_status || 'N/A'}</td>
              <td>₹{order.total_amount || 'N/A'}</td>
              <td>
                <button
                  style={{ border: 'none', backgroundColor: 'transparent', color: '#3b82f6' }}
                  onClick={() => handleDownload(order.id)}
                >
                  Download
                </button>
              </td>
            </tr>
          ))}
        </tbody>
     </table>
   </div>
 );
}




export default PurchaseOrder;












 




 
   
 
 




