import React, { useEffect, useState } from 'react';




const Bill = () => {
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');




  useEffect(() => {
    const fetchBills = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('authToken'); // Make sure you have an auth token
        const response = await fetch('https://api.myntask.com/bill/list', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });




        if (!response.ok) {
          throw new Error('Failed to fetch bills');
        }




        const data = await response.json();
        setBills(data);
      } catch (error) {
        console.error('Error fetching bills:', error);
        setError('Failed to load bills');
      } finally {
        setLoading(false);
      }
    };




    fetchBills();
  }, []);




  if (loading) {
    return <div>Loading...</div>;
  }




  if (error) {
    return <div>{error}</div>;
  }




  return (
    <div className="main-container">
       <div className="top-controls">
        <h1 className='title'>Bills
          <div className="underline"></div>
        </h1>
      </div>
   
      <table >
        <thead>
          <tr>
            <th >Bill Number</th>
            <th >Name</th>
            <th >Date</th>
            <th >Total</th>
            <th >Total Paid</th>
            <th >Unpaid Amount</th>
          </tr>
        </thead>
        <tbody>
          {bills.map((bill) => (
            <tr key={bill.purchase_bill_number}>
              <td >{bill.purchase_bill_number}</td>
              <td >{bill.primary_name}</td>
              <td >{new Date(bill.bill_date).toLocaleDateString()}</td>
              <td >{bill.total}</td>
              <td >{bill.total_paid}</td>
              <td >{bill.unpaidAmount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};










export default Bill;














