import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GridMoreVertIcon } from '@mui/x-data-grid';
import './../Components/Main.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';  // Importing xlsx

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedClients, setSelectedClients] = useState({});
  const [showOptions1, setShowOptions1] = useState(false);
  const [dropdown1, setDropdown1] = useState("");
  const [notification, setNotification] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await fetch("https://api.myntask.com/client/list", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch clients");
        }

        const data = await response.json();
        setClients(data.data); // assuming client data is inside `data.data`
        setPermissions(data.client_permission); // storing permissions
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAddClient = () => {
    navigate("/addClient");
  };

  const handleCheckboxChange = (clientId, userId) => {
    setSelectedClients((prevState) => {
      const newState = { ...prevState, [clientId]: { selected: !prevState[clientId]?.selected, userId } };
      setShowOptions1(Object.keys(newState).some((id) => newState[id].selected));
      return newState;
    });
  };

  const handleDropdown1Change = (e) => {
    setDropdown1(e.target.value);
  };

  const handleApply = async () => {
    const selectedClientId = Object.keys(selectedClients).find(
      (clientId) => selectedClients[clientId].selected
    );

    if (selectedClientId) {
      const { userId } = selectedClients[selectedClientId];
      const token = localStorage.getItem("authToken");

      try {
        if (dropdown1 === "edit") {
          navigate(`/ClientEdit/${selectedClientId}`);
        } else if (dropdown1 === "delete") {
          const response = await fetch("http://api.myntask.com/client/delete", {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              client: selectedClientId,
              user_id: userId,
            }),
          });

          if (!response.ok) {
            toast.error("Failed to delete client");
            throw new Error("Failed to delete client");
          }

          setClients((prevClients) =>
            prevClients.filter((client) => client.id !== parseInt(selectedClientId))
          );
          toast.success("Client deleted successfully.");
        }
      } catch (error) {
        setNotification(`Error: ${error.message}`);
      }
    } else {
      toast.error("Please select a client to proceed.");
    }
  };

  const handleActionButtonClick = (e, clientId) => {
    e.stopPropagation();
    if (anchorEl && selectedClientId === clientId) {
      setAnchorEl(null);
      setSelectedClientId(null);
    } else {
      setAnchorEl(e.currentTarget);
      setSelectedClientId(clientId);
    }
  };

  const handleAction = async (action, clientId, userId) => {
    if (action === "Edit") {
      navigate(`/ClientEdit/${clientId}`);
    } else if (action === "Delete") {
      const token = localStorage.getItem("authToken");

      try {
        const response = await fetch("http://api.myntask.com/client/delete", {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ client: clientId, user_id: userId }),
        });

        if (!response.ok) {
          toast.error("Failed to delete client");
          throw new Error("Failed to delete client");
        }

        setClients((prevClients) =>
          prevClients.filter((client) => client.id !== parseInt(clientId))
        );
        toast.success("Client deleted successfully.");
      } catch (error) {
        setNotification(`Error: ${error.message}`);
      }
    }

    setAnchorEl(null);
    setSelectedClientId(null);
  };

  // Filter clients based on search term
  const filteredClients = clients.filter((client) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      client.client_name.toLowerCase().includes(searchLower) ||
      client.company_name.toLowerCase().includes(searchLower) ||
      (client.email && client.email.toLowerCase().includes(searchLower)) ||
      client.status.toLowerCase().includes(searchLower) ||
      client.created.includes(searchLower)
    );
  });

  // Export to Excel function
  const handleExport = () => {
    const dataToExport = filteredClients.map((client, index) => ({
      No: index + 1,
      'Client Name': client.client_name,
      'Company Name': client.company_name,
      Email: client.email ? client.email : "N/A",
      Status: client.status,
      Created: client.created,
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Clients");

    // Trigger the download
    XLSX.writeFile(workbook, "ClientsData.xlsx");
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="main-container">
      <div className="top-controls">
        <h1 className='title'>Clients
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          {showOptions1 && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <select onChange={handleDropdown1Change} value={dropdown1} className="color-button">
                <option value="">Select Action</option>
                {permissions.edit_clients === "all" && <option value="edit">Edit</option>}
                {permissions.delete_clients === "all" && <option value="delete">Delete</option>}
              </select>
              <button onClick={handleApply} className="color-button">
                Apply
              </button>
            </div>
          )}
          {permissions.add_clients === "all" && (
            <button className="color-button" onClick={handleAddClient}>
              Add client
            </button>
          )}
        </div>
      </div>
      <div className="top-controls">
        <div className="filters-and-search">
          <button className="border-button" onClick={handleExport}>
            Export
          </button>
          <button className="border-button">
            Invite
          </button>
          <input
            type="text"
            placeholder="Search by name or email..."
            value={searchTerm}
            onChange={handleSearch}
            className="searchbar"
          />
        </div>
      </div>
      {notification && <p>{notification}</p>}
      <table>
        <thead>
          <tr>
            <th>Select</th>
            <th>No.</th>
            <th>Client Name</th>
            <th>Company Name</th>
            <th>Email</th>
            <th>Status</th>
            <th>Created</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredClients.map((client, index) => (
            <tr key={client.id}>
              <td>
                <input
                  type="checkbox"
                  checked={!!selectedClients[client.id]?.selected}
                  onChange={() => handleCheckboxChange(client.id, client.user_id)}
                />
              </td>
              <td>{index + 1}</td>
              <td>{client.client_name}</td>
              <td>{client.company_name}</td>
              <td>{client.email ? client.email : "N/A"}</td>
              <td>
                <span
                  className={`status-indicator ${
                    client.status === "active" ? "status-active" : "status-inactive"
                  }`}
                ></span>
                {client.status}
              </td>
              <td>{client.created}</td>
              <td>
                <div className="action-container">
                  <div
                    className="action-dots"
                    onClick={(e) => handleActionButtonClick(e, client.id)}
                  >
                    <GridMoreVertIcon />
                  </div>
                  {anchorEl && selectedClientId === client.id && (
                    <div className="action-menu">
                      {permissions.edit_clients === "all" && (
                        <div className="action-item" onClick={() => handleAction('Edit', client.id, client.user_id)}>
                          Edit
                        </div>
                      )}
                      {permissions.delete_clients === "all" && (
                        <div className="action-item" onClick={() => handleAction('Delete', client.id, client.user_id)}>
                          Delete
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ToastContainer />
    </div>
  );
};

export default Clients;
