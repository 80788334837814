import React, { useState, useEffect } from 'react';
import { Button, TextField, MenuItem } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify'; // Import toast
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import './AddHoliday.css'; // Add styling to make the form look like a modal

function AddHoliday() {
  const [holidayData, setHolidayData] = useState({
    date: '',
    occasion: '',
    department_id: [],
    designation_id: [],
    employment_type: [],
  });

  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);

  // Example employment types
  const employmentTypes = [
    { id: 'full_time', name: 'Full-Time' },
    { id: 'part_time', name: 'Part-Time' },
  ];

  // Fetch departments and designations from the API
  useEffect(() => {
    const token = localStorage.getItem('authToken');

    const fetchDepartments = async () => {
      try {
        const response = await fetch('https://api.myntask.com/employee/department', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        console.log('Departments fetched:', data);
        setDepartments(data);
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    };

    const fetchDesignations = async () => {
      try {
        const response = await fetch('https://api.myntask.com/employee/designation', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        console.log('Designations fetched:', data);
        setDesignations(data);
      } catch (error) {
        console.error('Error fetching designations:', error);
      }
    };

    fetchDepartments();
    fetchDesignations();
  }, []);

  // Handle form input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setHolidayData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle multiple select for departments and designations
  const handleMultiSelectChange = (e, field) => {
    const { value } = e.target; // e.target.value will be the selected array for multiple selects
    setHolidayData((prev) => ({
      ...prev,
      [field]: value, // Set selected values directly as an array
    }));
  };

  // Handle Save button click with toast notification
  const handleSave = async () => {
    const token = localStorage.getItem('authToken');

    const apiUrl = 'https://api.myntask.com/holiday/add';

    const body = {
      date: holidayData.date,
      occassion: holidayData.occasion,
      department_id: holidayData.department_id,
      designation_id: holidayData.designation_id,
      employment_type: holidayData.employment_type,
    };

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const contentType = response.headers.get('content-type');

      if (contentType && contentType.includes('application/json')) {
        const result = await response.json();
        console.log('Holiday saved successfully:', result);
        toast.success('Holiday saved successfully!'); // Show success toast notification
      } else {
        const result = await response.text(); // Handle non-JSON response
        console.log('Holiday saved successfully:', result);
        toast.success('Holiday saved successfully!'); // Show success toast notification
      }
    } catch (error) {
      console.error('Error saving holiday:', error);
      toast.error('Error saving holiday'); // Show error toast notification
    }
  };

  return (
    <div className="modal-container">
      <ToastContainer /> {/* ToastContainer to show the notifications */}
      <h2>Add Holiday</h2>

      <TextField
        label="Date"
        type="date"
        name="date"
        value={holidayData.date}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />

      <TextField
        label="Occasion"
        name="occasion"
        value={holidayData.occasion}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />

      {/* Departments dropdown */}
      <TextField
        label="Department"
        name="department_id"
        select
        multiple
        value={holidayData.department_id}
        onChange={(e) => handleMultiSelectChange(e, 'department_id')}
        fullWidth
        margin="normal"
        SelectProps={{
          multiple: true,
        }}
      >
        {departments.map((dept) => (
          <MenuItem key={dept.id} value={dept.id}>
            {dept.department}
          </MenuItem>
        ))}
      </TextField>

      {/* Designations dropdown */}
      <TextField
        label="Designation"
        name="designation_id"
        select
        multiple
        value={holidayData.designation_id}
        onChange={(e) => handleMultiSelectChange(e, 'designation_id')}
        fullWidth
        margin="normal"
        SelectProps={{
          multiple: true,
        }}
      >
        {designations.map((designation) => (
          <MenuItem key={designation.id} value={designation.id}>
            {designation.name}
          </MenuItem>
        ))}
      </TextField>

      {/* Employment types dropdown */}
      <TextField
        label="Employment Type"
        name="employment_type"
        select
        multiple
        value={holidayData.employment_type}
        onChange={(e) => handleMultiSelectChange(e, 'employment_type')}
        fullWidth
        margin="normal"
        SelectProps={{
          multiple: true,
        }}
      >
        {employmentTypes.map((type) => (
          <MenuItem key={type.id} value={type.id}>
            {type.name}
          </MenuItem>
        ))}
      </TextField>

      <div className="button-container">
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </div>
    </div>
  );
}

export default AddHoliday;
