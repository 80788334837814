import React, { useEffect, useState } from 'react';
import { Modal, Box, TextField, Select, MenuItem, Button } from '@mui/material';




function EditDepartmentModal({ open, handleClose, departmentId }) {
  const [departmentName, setDepartmentName] = useState('');
  const [parentId, setParentId] = useState('');
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [error, setError] = useState('');




  useEffect(() => {
    const fetchDepartmentDetails = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No authentication token found');
        return;
      }




      try {
        const response = await fetch(`https://api.myntask.com/employee/department_edit/${departmentId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });




        if (!response.ok) {
          throw new Error(`Failed to fetch department details: ${response.statusText}`);
        }




        const [data] = await response.json(); // Assuming the response is an array
   
        setDepartmentName(data.team_name || '');
        setParentId(data.parent_id || '');
      } catch (err) {
        console.error('Error fetching department details:', err);
        setError(`Error fetching department details: ${err.message}`);
      }
    };




    const fetchDepartmentOptions = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No authentication token found');
        return;
      }




      try {
        const response = await fetch('https://api.myntask.com/employee/department', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });




        if (!response.ok) {
          throw new Error(`Failed to fetch department options: ${response.statusText}`);
        }




        const data = await response.json();
        setDepartmentOptions(data);
      } catch (err) {
        console.error('Error fetching department options:', err);
        setError(`Error fetching department options: ${err.message}`);
      }
    };




    if (departmentId) {
      fetchDepartmentDetails();
    }
    fetchDepartmentOptions();
  }, [departmentId]);




  const handleSave = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No authentication token found');
      return;
    }




    try {
      const response = await fetch('https://api.myntask.com/employee/department_update', {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          department_id: departmentId,
          name: departmentName,
          parent_id: parentId || 0, // Default to 0 if parentId is empty
        }),
      });




      if (!response.ok) {
        throw new Error(`Failed to update department: ${response.statusText}`);
      }




      handleClose();
    } catch (err) {
      console.error('Error updating department:', err);
      setError(`Error updating department: ${err.message}`);
    }
  };




  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ p: 4, bgcolor: 'background.paper', margin: 'auto', width: 400, marginTop: 10, position: 'relative' }}>
        <button
          onClick={handleClose}
          style={{
            position: 'absolute',
            top: '8px',
            right: '8px',
            backgroundColor: 'transparent',
            border: 'none',
            fontSize: '18px',
            cursor: 'pointer',
          }}
        >
          X
        </button>
        <h2>Edit Department</h2>
        {error && <p>{error}</p>}
        <label htmlFor="departmentName">Department Name</label>
    <input
      type="text"
      id="departmentName"
      value={departmentName}
      onChange={(e) => setDepartmentName(e.target.value)}
      style={{ width: '100%', margin: '8px 0' }}
    />
   
    <label htmlFor="parentId">Parent</label>
    <select
      id="parentId"
      value={parentId}
      onChange={(e) => setParentId(e.target.value)}
      style={{ width: '100%', margin: '8px 0' }}
    >
      <option value={0}>
        None
      </option>
      {departmentOptions.map((option) => (
        <option key={option.id} value={option.id}>
          {option.department}
        </option>
      ))}
    </select>
    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
        <Button
  onClick={handleClose}
  variant="outlined"
  color="secondary"
  sx={{
    '&:hover': {
      color: 'white', // Change font color to white on hover
    },
  }}
>
  Cancel
</Button>


      </div>
      </Box>
    </Modal>
  );
}




export default EditDepartmentModal;









