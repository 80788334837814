import React, { useState, useEffect } from 'react';
import {
 Box,
 Typography,
 Table,
 TableBody,
 TableCell,
 TableContainer,
 TableHead,
 TableRow,
 Paper,
 Avatar,
 Dialog,
 DialogContent,
 Button,
 TextField,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';




const DailyUpdateList = () => {
 const [dailyReports, setDailyReports] = useState([]);
 const [filteredReports, setFilteredReports] = useState([]);
 const [openModal, setOpenModal] = useState(false);
 const [modalContent, setModalContent] = useState({ title: '', content: '', image: '', userName: '', type: '' });
 const [searchTerm, setSearchTerm] = useState('');
 const [selectedDate, setSelectedDate] = useState(null);




 useEffect(() => {
   const fetchDailyReports = async (date) => {
     const token = localStorage.getItem('authToken');
     if (!token) {
       console.error('No auth token found.');
       return;
     }




     try {
       let apiUrl = 'https://api.myntask.com/home/daily_report_list';
       if (date) {
         apiUrl += `?&date=${date}`;
       }




       const response = await fetch(apiUrl, {
         method: 'GET',
         headers: {
           Authorization: `Bearer ${token}`,
           'Content-Type': 'application/json',
         },
       });




       if (!response.ok) {
         throw new Error('Network response was not ok');
       }




       const data = await response.json();
       setDailyReports(data);
       setFilteredReports(data);
     } catch (error) {
       console.error('Error fetching daily reports:', error);
       toast.error('Failed to fetch daily reports. Please try again.');
     }
   };




   fetchDailyReports(selectedDate);
 }, [selectedDate]);




 const handleOpenModal = (title, content, image, userName, type) => {
   setModalContent({ title, content, image, userName, type });
   setOpenModal(true);
 };




 const handleCloseModal = () => {
   setOpenModal(false);
   setModalContent({ title: '', content: '', image: '', userName: '', type: '' });
 };




 const handleSearchChange = (event) => {
   const value = event.target.value.toLowerCase();
   setSearchTerm(value);




   const filtered = dailyReports.filter(
     (report) =>
       report.user_name.toLowerCase().includes(value) ||
       report.project_name.toLowerCase().includes(value) ||
       report.summary.toLowerCase().includes(value) ||
       report.challenges.toLowerCase().includes(value)
   );




   setFilteredReports(filtered);
 };




 const handleExport = () => {
   // Preparing data for export
   const worksheetData = filteredReports.map((report) => ({
     'Team Member': report.user_name,
     Project: report.project_name,
     Summary: report.summary,
     Challenges: report.challenges,
     Mood: report.mood,
     Date: new Date(report.created_at).toLocaleString(),
   }));




   // Creating a new worksheet and workbook
   const worksheet = XLSX.utils.json_to_sheet(worksheetData);
   const workbook = XLSX.utils.book_new();




   // Appending the worksheet to the workbook
   XLSX.utils.book_append_sheet(workbook, worksheet, 'Daily Reports');




   // Exporting the workbook as an Excel file
   XLSX.writeFile(workbook, 'Daily_Updates_List.xlsx');
 };




 return (
  <div className="main-container">
  <div className="top-controls">
    <h1 className='title'>Daily Update List
      <div className="underline"></div>
    </h1>
    <div className='buttons-row'>
    <button className="color-button" onClick={handleExport}>
          Export to Excel
          </button>
     
    </div>
  </div>


 
      {/* Filters */}
      <div className="top-controls">
        <div className="filters-and-search">
         
          <input
        type="date"
        value={selectedDate}
        onChange={(e) => setSelectedDate(e.target.value)}
        className="border-button"
        placeholder='select date'
      />
        </div>
      </div>


    <table >
      <thead>
        <tr>
          <th>Team Member</th>
          <th>Project</th>
          <th>Summary</th>
          <th>Challenges</th>
          <th>Mood</th>
          <th>Date</th>
          <th>Location</th>
          <th>Report Files</th>
        </tr>
      </thead>
      <tbody>
        {filteredReports.length > 0 ? (
          filteredReports.map((report, index) => (
            <tr key={index}>
              <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={report.image}
                    alt={report.user_name}
                    className='image'
                  />
                  {report.user_name}
                </div>
              </td>
              <td>{report.project_name}</td>
              <td
                style={{ cursor: 'pointer', color: '#1976d2' }}
                onClick={() => handleOpenModal('Summary', report.summary, '', report.user_name, 'text')}
              >
                {report.summary}
              </td>
              <td
                style={{ cursor: 'pointer', color: '#1976d2' }}
                onClick={() => handleOpenModal('Challenges', report.challenges, '', report.user_name, 'text')}
              >
                {report.challenges}
              </td>
              <td
                style={{
                  color:
                    report.mood === 'happy' ? '#4caf50' : report.mood === 'neutral' ? '#ff9800' : '#f44336',
                  fontWeight: 'bold',
                }}
              >
                {report.mood}
              </td>
              <td>{new Date(report.created_at).toLocaleString()}</td>
              <td>
                <a
                  href={report.location}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'underline', color: '#1976d2' }}
                >
                  View Location
                </a>
              </td>
              <td>
                {report.report_files && report.report_files.length > 0 && (
                  <button
                    onClick={() => handleOpenModal('Report Files', '', report.report_files[0], report.user_name, 'image')}
                    style={{ padding: '6px 12px', border: '1px solid #ccc', borderRadius: '4px', background: 'none', cursor: 'pointer' }}
                  >
                    View Files
                  </button>
                )}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={8} style={{ textAlign: 'center' }}>
              No data available.
            </td>
          </tr>
        )}
      </tbody>
    </table>




  {/* Modal to display full content */}
  {openModal && (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ backgroundColor: '#fff', padding: '24px', borderRadius: '8px', width: '100%', maxWidth: '600px', textAlign: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '16px' }}>
          <img
            src={modalContent.image}
            alt={modalContent.userName}
            style={{
              width: '30px',
              height: '30px',
              marginRight: '12px',
              borderRadius: '50%',
              border: '2px solid #3f51b5',
            }}
          />
          <h3 style={{ fontWeight: 'bold' }}>{modalContent.userName}</h3>
        </div>


        <h3 style={{ fontWeight: 'bold', marginBottom: '16px' }}>{modalContent.title}</h3>


        {/* Display the content conditionally based on type */}
        {modalContent.type === 'text' && <p>{modalContent.content}</p>}
        {modalContent.type === 'image' && modalContent.image && (
          <img
            src={modalContent.image}
            alt={modalContent.title}
            style={{
              maxWidth: '100%',
              height: 'auto',
              borderRadius: '8px',
              marginBottom: '20px',
              boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)',
            }}
          />
        )}


        <button
          onClick={handleCloseModal}
          style={{
            padding: '8px 16px',
            backgroundColor: '#3f51b5',
            color: '#fff',
            border: 'none',
            borderRadius: '16px',
            cursor: 'pointer',
            fontWeight: 'bold',
          }}
        >
          Close
        </button>
      </div>
    </div>
  )}
</div>


 );
};




export default DailyUpdateList;












 
