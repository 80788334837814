import React, { useState, useEffect } from 'react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';
import { Button } from '@mui/material';


const ClockIn = () => {
  const [isClockedIn, setIsClockedIn] = useState(false);
  const [loading, setLoading] = useState(false);


  // Fetch attendance status from localStorage when the component mounts
  useEffect(() => {
    const storedAttendance = JSON.parse(localStorage.getItem('attendanceStatus')) || {};
    setIsClockedIn(storedAttendance.clockInStatus === 'Yes');
  }, []);


  const getUserLocationAndSaveAttendance = async () => {
    setLoading(true);


    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;


          const success = await handleSaveAttendance(latitude, longitude);
          if (success) {
            setIsClockedIn(true);
          }
          setLoading(false);
        },
        (error) => {
          console.error('Error getting user location:', error);
          toast.error('Error getting user location');
          setLoading(false);
        }
      );
    } else {
      toast.error('Geolocation is not supported by this browser.');
      setLoading(false);
    }
  };


  const getUserLocationAndClockOut = async () => {
    setLoading(true);


    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          await handleClockOut(latitude, longitude);
          setIsClockedIn(false);
          setLoading(false);
        },
        (error) => {
          console.error('Error getting user location for clock out:', error);
          toast.error('Error getting user location for clock out');
          setLoading(false);
        }
      );
    } else {
      toast.error('Geolocation is not supported by this browser.');
      setLoading(false);
    }
  };


  const handleSaveAttendance = async (latitude, longitude) => {
    const url = 'https://api.myntask.com/attendance/save';
    const data = { latitude, longitude };


    try {
      const token = localStorage.getItem('authToken');
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });


      const responseText = await response.text();
      if (response.ok) {
        toast.success('Attendance marked successfully');
        return true;
      } else {
        toast.error(responseText || 'Failed to save attendance');
        return false;
      }
    } catch (error) {
      console.error('Error saving attendance:', error);
      toast.error('Error saving attendance');
      return false;
    }
  };


  const handleClockOut = async (latitude, longitude) => {
    const clockOutTimestamp = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const url = 'https://api.myntask.com/attendance/save';
    const data = {
      latitude,
      longitude,
      clock_out: clockOutTimestamp,
    };


    try {
      const token = localStorage.getItem('authToken');
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });


      const responseText = await response.text();
      if (response.ok) {
        toast.success('Clocked out successfully');
      } else {
        toast.error(responseText || 'Failed to clock out');
      }
    } catch (error) {
      console.error('Error clocking out:', error);
      toast.error('Error clocking out');
    }
  };


  return (
    <Button
    variant="contained"
    color="primary"
    onClick={() => {
      if (!loading) {
        isClockedIn ? getUserLocationAndClockOut() : getUserLocationAndSaveAttendance();
      }
    }}
    sx={{
      backgroundColor: 'white',
      color: isClockedIn ? 'red' : 'green', // Red for "Clock Out", Green for "Clock In"
      padding: '4px 10px',
      fontSize: '12px',
      border: '1px solid #ccc',
      boxShadow: 'none',
      fontWeight: '600',
      display: 'flex',
      alignItems: 'center',
      marginRight: '10px',
      gap: '5px',
      '&:hover': {
        backgroundColor: '#f0f0f0',
        boxShadow: 'none',
      },
     
    }}
    disabled={loading} // Disable button during loading
    startIcon={<ExitToAppIcon />}
  >
    {isClockedIn ? 'Clock Out' : 'Clock In'}
    {loading && <CircularProgress size={20} sx={{ marginLeft: '10px' }} />}
  </Button>
 
  );
};


export default ClockIn;





