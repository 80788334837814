import React, { useEffect, useState } from 'react';
import { Modal, Box, TextField, Button, MenuItem, Select } from '@mui/material';
import EditDesignationModal from '../Components/EditDesignationModal';
import { GridMoreVertIcon } from '@mui/x-data-grid';




function Designation() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const [newDesignation, setNewDesignation] = useState({ name: '', parent_id: '' });
  const [parentOptions, setParentOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedId, setSelectedId] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [action, setAction] = useState('');
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [actionMenuVisible, setActionMenuVisible] = useState({});
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [viewData, setViewData] = useState(null);
  const [permissions, setPermissions] = useState({});




  useEffect(() => {
    const fetchDesignationData = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No token found');
        return;
      }




      try {
        const response = await fetch('https://api.myntask.com/employee/designation_list', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });




        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }




        const data = await response.json();
        setData(data.designation);
        setFilteredData(data.designation);
        setPermissions(data.permission); // Set the permissions data
      } catch (error) {
        setError(error.message);
      }
    };




    fetchDesignationData();
  }, []);




  useEffect(() => {
    const fetchParentOptions = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No token found');
        return;
      }




      try {
        const response = await fetch('https://api.myntask.com/employee/designation', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });




        if (!response.ok) {
          throw new Error('Failed to fetch parent options');
        }




        const options = await response.json();
        setParentOptions(options);
      } catch (error) {
        setError(error.message);
      }
    };




    fetchParentOptions();
  }, []);




  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);




  const handleAddDesignation = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No token found');
      return;
    }




    try {
      const response = await fetch('https://api.myntask.com/employee/designation_save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          name: newDesignation.name,
          parent_id: newDesignation.parent_id || null,
        }),
      });




      if (!response.ok) {
        throw new Error('Failed to save designation');
      }




      const result = await response.json();




      // Update the list with the new designation
      setData((prevData) => [...prevData, { id: result.insertId, name: newDesignation.name, parent: newDesignation.parent_id || 'None' }]);
      setFilteredData((prevData) => [...prevData, { id: result.insertId, name: newDesignation.name, parent: newDesignation.parent_id || 'None' }]);




      handleClose();
    } catch (err) {
      setError('Error saving designation');
    }
  };




  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewDesignation((prev) => ({ ...prev, [name]: value }));
  };




  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);




    const filtered = data.filter((designation) =>
      designation.name.toLowerCase().includes(query) ||
      (designation.parent && designation.parent.toLowerCase().includes(query))
    );




    setFilteredData(filtered);
  };




  const handleCheckboxChange = (id) => {
    if (selectedId === id) {
      setSelectedId(null);
      setDropdownOpen(false);
    } else {
      setSelectedId(id);
      setDropdownOpen(true);
    }
  };




  const handleAction = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No token found');
      return;
    }




    try {
      if (action === 'delete') {
        const response = await fetch('https://api.myntask.com/employee/designation_delete', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ designation_id: selectedId }),
        });




        if (!response.ok) {
          throw new Error('Failed to delete designation');
        }




        // Remove the deleted designation from the list
        setData((prevData) => prevData.filter((designation) => designation.id !== selectedId));
        setFilteredData((prevData) => prevData.filter((designation) => designation.id !== selectedId));
      } else if (action === 'edit') {
        setEditModalOpen(true); // Open the edit modal
      } else if (action === 'view') {
        const selectedDesignation = data.find((designation) => designation.id === selectedId);
        setViewData(selectedDesignation); // Set the data for viewing
        setViewModalOpen(true); // Open the view modal
      }




      setDropdownOpen(false);
    } catch (err) {
      setError('Error performing action');
    }
  };








  const toggleActionMenu = (id) => {
    setActionMenuVisible((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
    setSelectedId(id);
  };




  return (
    <div className='main-container'>
     <div className="top-controls">
        <h1 className='title'>Designation
          <div className="underline"></div>
        </h1>




        <div className="buttons-row">
          {dropdownOpen && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <select onChange={(e) => setAction(e.target.value)} value={action} className="color-button">
                <option value="">Select Action</option>
                {permissions.edit_designation === 'all' && <option value="edit">Edit</option>}
                {permissions.delete_designation === 'all' && <option value="delete">Delete</option>}
              </select>
              <button onClick={handleAction} className="color-button">
                Apply
              </button>
            </div>
          )}
          {permissions.add_designation === 'all' && (
            <button className="color-button" onClick={() => setOpen(true)}>
              Add Designation
            </button>
          )}
        </div>
      </div>




      <div className="top-controls">
        <div className="filters-and-search">
          <button className="border-button">
            Clear Filters
          </button>
          <button className="border-button">
            Import
          </button>
          <input
            type="text"
            placeholder="Search Designation"
            value={searchQuery}
            onChange={handleSearch}
            className="searchbar"
          />
        </div>
      </div>




      <table>
        <thead>
          <tr>
            <th>Serial No</th>
            <th>Select</th>
            <th>Name</th>
            <th>Parent</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((designation, index) => (
            <tr key={designation.id}>
              <td>{index + 1}</td>
              <td>
                <input
                  type="checkbox"
                  checked={selectedId === designation.id}
                  onChange={() => handleCheckboxChange(designation.id)}
                />
              </td>
              <td>{designation.name}</td>
              <td>{designation.parent || 'None'}</td>
              <td>
                <div className="action-container">
                  <div className="action-dots" onClick={() => toggleActionMenu(designation.id)}>
                    <GridMoreVertIcon/>
                  </div>
                  {actionMenuVisible[designation.id] && (
                    <div className="action-menu">
                      {permissions.edit_designation === 'all' && (
                        <div
                          className="action-item"
                          onClick={() => {
                            setAction('edit');
                            handleAction();
                          }}
                        >
                          Edit
                        </div>
                      )}
                      {permissions.delete_designation === 'all' && (
                        <div
                          className="action-item"
                          onClick={() => {
                            setAction('delete');
                            handleAction();
                          }}
                        >
                          Delete
                        </div>
                      )}
                      <div
                        className="action-item"
                        onClick={() => {
                          setAction('view');
                          handleAction();
                        }}
                      >
                        View
                      </div>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {error && <p style={{ color: 'red' }}>{error}</p>}




      <EditDesignationModal
        open={editModalOpen}
        handleClose={() => setEditModalOpen(false)}
        designationId={selectedId} // Pass the selected ID to the modal
      />




<Modal open={open} onClose={handleClose}>
        <Box sx={{ p: 4, bgcolor: 'background.paper', margin: 'auto', width: '400px', marginTop: 10, position: 'relative' ,borderRadius:'10px'}}>
          {/* Close Button */}
          <div className="top-controls">
        <h1 className='title'>Add Designation
          <div className="underline"></div>
        </h1>




        <div className="buttons-row">
         
            <button  style={{color:"black",padding:'0px',backgroundColor:'transparent',fontSize:'22px'}}  onClick={handleClose}>
              X
            </button>
       
        </div>
      </div>
         
          <div>
  <label htmlFor="designation-name">Designation Name</label>
  <input
    type="text"
    id="designation-name"
    name="name"
    value={newDesignation.name}
    onChange={handleChange}
   className='field'
  />


  <label htmlFor="parent-designation">Parent Designation</label>
  <select
    id="parent-designation"
    name="parent_id"
    value={newDesignation.parent_id}
    onChange={handleChange}
   className='drop'
  >
    <option value="">
      <em>None</em>
    </option>
    {parentOptions.map((option) => (
      <option key={option.id} value={option.id}>
        {option.name}
      </option>
    ))}
  </select>
</div>


          <button className='color-button' style={{marginTop:'20px'}}
            onClick={handleAddDesignation}>
            Save
          </button>
        </Box>
      </Modal>




      {/* View Modal */}
      <Modal open={viewModalOpen} onClose={() => setViewModalOpen(false)}>
  <Box
    sx={{
      backgroundColor: 'white',
      width: '400px',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      outline: 'none',
    }}
  >
    {viewData && (
      <>
        <h2 style={{ marginBottom: '20px' }}>View Designation</h2>
        <p>
          <strong>Name:</strong> {viewData.name}
        </p>
        <p>
          <strong>Parent:</strong> {viewData.parent || 'None'}
        </p>
        {/* Close button */}
        <button
  onClick={() => setViewModalOpen(false)}
  style={{
    backgroundColor: '#f44336',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '4px',
    cursor: 'pointer',
    position: 'absolute',   // Add absolute positioning
    bottom: '20px',         // Position it 20px from the bottom
    right: '20px',          // Position it 20px from the right
  }}
>
  Close
</button>


      </>
    )}
  </Box>
</Modal>










    </div>
  );
}




export default Designation;




































